import React, { useEffect, useState, useMemo } from 'react'
import { Grid, Checkbox, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from "@material-ui/core"
import { refreshCanalOptions } from '../../constants'
// import * as actions from "../../containers/User/Calculator/actions"
// import { toastError } from '../../utils'
// import { update } from 'lodash'
import { usePrevious } from '../../hooks/usePrevious'

const initialCanalPort = {
    eca: false,
    hra: false,
    jwc: false,
    suez: false,
    panama: false,
    singapore: false,
}

function PortDistanceModal(props) {

    const {
        calculatorData = {},
        setCalculatorData = () => { },
        onFetchPortOperationDistance = () => { },
        onChangePortCanalInQueryState = () => { },
    } = props

    const { portOperation = [], clientQuery = {} } = calculatorData || {}

    // const [userCanal, setUserCanal] = useState({})
    const [canalPorts, setCanalPorts] = useState({ ...initialCanalPort })
    const [routeOverride, setRouteOverride] = useState(false)

    useEffect(() => {
        // onGetUserCanalData()
        const { eca = false, hra = false, jwc = false, suez = false, panama = false, singapore = false } = clientQuery
        setCanalPorts({
            eca, hra, jwc, suez, panama, singapore
        })
    }, [])

    const onChangeDistanceNotes = (index, value) => {
        const updatedPortOperations = [...portOperation]
        updatedPortOperations[index].notes = value
        setCalculatorData({
            ...calculatorData,
            portOperation: updatedPortOperations
        })
    }

    const onSetCanalPort = (e) => {
        const { checked, name } = e.target
        const updatedCanalPorts = { ...canalPorts, [name]: checked }
        const updatedClientQuery = { ...clientQuery, [name]: checked }
        setCanalPorts(updatedCanalPorts)
        setRouteOverride(true)
        // console.log(updatedClientQuery, "updatedClientQuery")
        setCalculatorData({
            ...calculatorData,
            clientQuery: updatedClientQuery
        })
        onChangePortCanalInQueryState(updatedCanalPorts)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <button
                    className="refresh-distance-btn"
                    onClick={() => { onFetchPortOperationDistance(true, null, { ...canalPorts, routeOverride }); setRouteOverride(false) }}>
                    Refresh Distance
                </button>
            </Grid>
            <Grid item xs={12}>
                <div style={{ display: "flex", margin: "20px 0px" }}>
                    {
                        refreshCanalOptions.map((item, index) => {
                            // const isChecked = (canal && canal.includes(item.value)) || false
                            return <div key={index}>
                                {/* <Checkbox color="primary" checked={isChecked} onChange={() => onChangeUserPort(item.value)} /> */}
                                <Checkbox color="primary" name={item.value} checked={canalPorts[item.value]} onChange={onSetCanalPort} />
                                <span>{item.label}</span>
                            </div>
                        })
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper} className="bunker-supply-table">

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>Port</TableCell>
                                <TableCell>Distance Normal</TableCell>
                                <TableCell>Distance ECA</TableCell>
                                <TableCell>Lattitude</TableCell>
                                <TableCell>Longitude</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell>Time Zone</TableCell>
                                <TableCell>User Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(portOperation) && portOperation.map((item, index) => {
                                    const { port = "", cargoDetails = {}, lat = "", lng = "", country = "", notes = "" } = item
                                    const { distEca = 0, distNormal = 0 } = cargoDetails || {}
                                    // const event = new Date();
                                    return <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{port}</TableCell>
                                        <TableCell>{distNormal}</TableCell>
                                        <TableCell>{distEca}</TableCell>
                                        <TableCell>{lat}</TableCell>
                                        <TableCell>{lng}</TableCell>
                                        <TableCell>{country}</TableCell>
                                        {/* <TableCell style={{ width: 200 }}>{event.toString()}</TableCell> */}
                                        <TableCell style={{ width: 200 }}>-</TableCell>
                                        <TableCell style={{ width: 200 }}><input type="text" value={notes || ""} onChange={e => onChangeDistanceNotes(index, e.target.value)} /></TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    )
}

export default PortDistanceModal
