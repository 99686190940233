import {
    loginAPI,
    forgetPasswordAPI,
    updatePasswordAPI
} from "./api"

export const login = (data) => {
    return new Promise((resolve, reject) => {
        loginAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const forgetPassword = (data) => {
    return new Promise((resolve, reject) => {
        forgetPasswordAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updatePassword = (data) => {
    return new Promise((resolve, reject) => {
        updatePasswordAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}