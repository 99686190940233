import * as actionTypes from "./actionsTypes"

export const openErrorAlert = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.OPEN_ERROR_ALERT,
            payload: data
        })
    }
}

export const addOnlyErrors = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ADD_ONLY_ERRORS,
            payload: data
        })
    }
}

export const closeErrorAlert = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLOSE_ERROR_ALERT
        })
    }
}