import React, { useEffect, useState } from 'react'
import * as actions from "../../containers/User/Calculator/actions"
import { Grid } from "@material-ui/core"
import { Link, useHistory } from "react-router-dom"
import DeleteIcon from '@material-ui/icons/Delete';
import { toastError, toastSuccess } from '../../utils';

function LoadSavedResults(props) {
    const { setLoader = () => { } } = props
    const [results, setResults] = useState({})


    useEffect(() => {
        onFetchSavedResults()
    }, [])

    const onFetchSavedResults = () => {
        setLoader(true)
        actions.allSavedResults().then(res => {
            setLoader(false)
            setResults(res)
        }).catch(err => {
            setLoader(false)
        })
    }

    const onClickDeleteSavedFleet = (id) => {
        setLoader(true)
        actions.deleteSavedResultById(id).then(res => {
            toastSuccess('Result deleted successfully')
            onFetchSavedResults()
        }).catch(err => {
            toastError()
            setLoader(false)
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    {
                        results.content && results.content.length ? results.content.map((item, index) => {
                            return <CardView
                                key={index}
                                item={item}
                                onClickDeleteSavedFleet={onClickDeleteSavedFleet}
                            />
                        }) :
                            <div style={{ width: "100%", height: "300px", display: "flex" }}>
                                <p style={{ fontSize: 18, margin: "auto" }}>No result found.</p>
                            </div>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoadSavedResults

const CardView = (props) => {
    const {
        item = {},
        onClickDeleteSavedFleet = () => { }
    } = props

    const history = useHistory()

    return <Grid item xs={3} className="result-card-div">
        <div className="inner-div" onClick={() => history.push(`/saved/${item.id}`)}>
            <p>{item.vessel}</p>
            <DeleteIcon onClick={e => { e.stopPropagation(e); onClickDeleteSavedFleet(item.id) }} />
        </div>
    </Grid>
}
