import React from "react"
import { Checkbox, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import { addFleetGearsOptions, addFleetVesselTypeOptions } from '../../../constants';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ManualAddFleet(props) {
    const {
        updatedSinceLastOptions,
        autoFilters,
        onChangeAutoFilters,
        onSubmitAutoSearch,
        emailFleetList,
        selectedAutoFleet,
        onChangeDateOpening,
        onAutoFleetListCheckBox,
        allSelected,
        addFleetRegionOptions,
        onSelectAllAutoFleet
    } = props

    const indeterminateChecked = (selectedAutoFleet.length && selectedAutoFleet.length !== emailFleetList.length)


    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={1} className="m-t-20" >
                    <Grid item xs={2}>
                        <label className="auto-add-fleet-label">Updated since last</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={updatedSinceLastOptions()}
                            value={autoFilters.updateSince || {}}
                            onChange={(e, value) => onChangeAutoFilters("updateSince", value)}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            fullWidth
                            disableClearable
                            renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <label className="auto-add-fleet-label">Region</label>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={addFleetRegionOptions}
                            value={autoFilters.portRegion || {}}
                            fullWidth
                            onChange={(e, value) => onChangeAutoFilters("portRegion", value)}
                            limitTags={2}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        size="small"
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                    />
                                    <span style={{ fontSize: 12 }}>{option.label}</span>
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" size="small" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <label className="auto-add-fleet-label">Vessel Type</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={addFleetVesselTypeOptions}
                            value={autoFilters.vesselType || {}}
                            onChange={(e, value) => onChangeAutoFilters("vesselType", value)}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            fullWidth
                            disableClearable
                            renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <label className="auto-add-fleet-label">Gears</label>
                        <Autocomplete
                            id="combo-box-demo"
                            options={addFleetGearsOptions}
                            value={autoFilters.gear || {}}
                            onChange={(e, value) => onChangeAutoFilters("gear", value)}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            fullWidth
                            disableClearable
                            renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={2} className="d-flex">
                        <span className="search-by-auto m-a" onClick={onSubmitAutoSearch}>Search</span>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <TableContainer component={Paper} style={emailFleetList.length ? { height: 350 } : {}}>
                    <Table className="fleet-list-table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Checkbox color="primary" indeterminate={indeterminateChecked} checked={allSelected} onChange={onSelectAllAutoFleet} /></TableCell>
                                <TableCell>Vessel Name</TableCell>
                                <TableCell>Email Date</TableCell>
                                <TableCell>Port</TableCell>
                                <TableCell>Port Region</TableCell>
                                <TableCell>DWT</TableCell>
                                {/* <TableCell>Email Date Opening</TableCell> */}
                                <TableCell>Date Opening</TableCell>
                                <TableCell>Email Subject</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emailFleetList.length ? emailFleetList.map((item, index) => {
                                const { id, vesselName = '', emailDate = "", portName = "", portRegion = "", dwt = "", dateOpening = "", dateOpen = "", subject = "" } = item
                                const isChecked = selectedAutoFleet.find(item => item.id === id) || false
                                return <TableRow key={index}>
                                    <TableCell><Checkbox style={{ padding: 0 }} color="primary" checked={!!isChecked} onChange={() => onAutoFleetListCheckBox(item)} /></TableCell>
                                    <TableCell>{vesselName}</TableCell>
                                    <TableCell>{emailDate}</TableCell>
                                    <TableCell>{portName}</TableCell>
                                    <TableCell>{portRegion}</TableCell>
                                    <TableCell>{dwt}</TableCell>
                                    {/* <TableCell>{dateOpening}</TableCell> */}
                                    <TableCell>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                inputVariant="outlined"
                                                size="small"
                                                className="date-time-picker"
                                                format="DD/MMM/YYYY HH:mm:A"
                                                style={{ width: 160, height: 2 }}
                                                value={dateOpen || null}
                                                onChange={date => onChangeDateOpening(index, item, date)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell>{subject}</TableCell>
                                </TableRow>
                            }) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}