import { apiDelete, apiGet, apiPost, apiPut, getOrganizationId } from "../../../utils"

export const usersForOrgAPI = (data) => {
    const { organizationId = '' } = data || {}
    return apiGet(`/api/v1/usersForOrg?organizationId=${organizationId}`)
}

export const createUserAPI = (data) => {
    return apiPost('/api/v1/auth/createUser', data)
}

export const deleteUserAPI = (data) => {
    const { id = '' } = data || {}
    return apiDelete(`/api/v1/deleteUser?id=${id}`)
}

export const allRolesAPI = () => {
    return apiGet('/api/v1/allRoles')
}

export const getAllRolesAPI = () => {
    return apiGet('/api/v1/getRoles')
}

export const usersWithIdAPI = (data) => {
    const { id = '' } = data || {}
    return apiGet(`/api/v1/usersWithId?id=${id}`)
}

export const getUserRolesAPI = (data) => {
    const { id = '' } = data || {}
    return apiGet(`/api/v1/getUserRoles?id=${id}`)
}

export const updateUserAPI = (data) => {
    return apiPut(`/api/v1/updateUser`, data)
}

export const allVesselsAPI = (data) => {
    const { offset = 0, limit = 25 } = data || {}
    return apiGet(`/api/v1/allVessels?offset=${offset}&limit=${limit}`)
}

export const getAllOrganizationsAPI = (data) => {
    return apiGet(`/api/v1/getAllOrganizations`)
}

export const getFleetByOrgAPI = (filters) => {
    // const data = { orgId: getOrganizationId() }
    const { offset = 0, limit = 25, orgId = '' } = filters || {}
    return apiGet(`/api/v1/paginatedFleet?offset=${offset}&limit=${limit}&orgId=${orgId}`)
}
// http://fleetsigma.psychoin.icu/api/v1/paginatedFleet?orgId=7ba5fcbf-c0ff-485c-9f6d-7e58cc854c28&offset=0

export const createVesselAPI = (data) => {
    return apiPut('/api/v1/createVessel', data)
}

export const allVesselsByCharAPI = (data) => {
    const { vessel = '', offset = 0, limit = 25 } = data || {}
    return apiGet(`/api/v1/allVesselsByChar?vessel=${vessel}&offset=${offset}&limit=${limit}`)
}

export const updateVesselWithFleetDetailsAPI = (data) => {
    return apiPut('/api/v1/updateVesselWithFleetDetails', data)
}

export const markFleetAPI = (data) => {
    return apiPost('/api/v1/markFleet', data)
}

export const createOrganizationAPI = (data) => {
    return apiPost('/api/v1/organization', data)
}

export const updateOrganizationAPI = (data) => {
    return apiPut('/api/v1/updateOrganization', data)
}

export const deleteOrganizationAPI = (data) => {
    return apiDelete('/api/v1/deleteOrganization', data)
}