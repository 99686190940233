
import {
    getQueryFormatAPI,
    queryByIdAPI,
    cargoByQueryAPI,
    allCargoExtraDetailsByQueryAPI,
    allFleetAPI,
    sendCalculatorDataAPI,
    addCargoExtraDetailsAPI,
    updateQueryFormtAPI,
    deleteCargoExtraDetailAPI,
    updateQueryFormatAPI,
    deleteFleetAPI,
    allVesselsByCharWithoutReducerAPI,
    portsByCharAPI,
    addMultiFleetAPI,
    getEmailsFleetListAPI,
    getVesselsWithIdAPI,
    saveToFleetAPI,
    getPortDistanceByPortListAPI,
    updateDistanceAPI,
    savePortDistanceAPI,
    getCanalNameByUserAPI,
    setUSerPortCanalAPI,
    resetDistanceAPI,
    updateFuelPriceAPI,
    userFleetsAPI,
    userFleetDataCopyAPI,
    allSavedResultsAPI,
    saveResultByIdAPI,
    deleteSavedResultByIdAPI,
    allVoyageResultsByCharAPI,
    allPortRegionsAPI,
    // getNewPortDistanceByPortListAPI,
} from "./api"
import * as actionsTypes from "./actionTypes"

export const getQueryFormat = (data) => {
    return new Promise((resolve, reject) => {
        getQueryFormatAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const queryById = (data) => {
    return new Promise((resolve, reject) => {
        queryByIdAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const cargoByQuery = (data) => {
    return new Promise((resolve, reject) => {
        cargoByQueryAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allCargoExtraDetailsByQuery = (data) => {
    return new Promise((resolve, reject) => {
        allCargoExtraDetailsByQueryAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allFleet = (data) => {
    return new Promise((resolve, reject) => {
        allFleetAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const sendCalculatorData = (data) => {
    return new Promise((resolve, reject) => {
        sendCalculatorDataAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const addCargoExtraDetails = (data) => {
    return new Promise((resolve, reject) => {
        addCargoExtraDetailsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateQueryFormt = (data) => {
    return new Promise((resolve, reject) => {
        updateQueryFormtAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const deleteCargoExtraDetail = (data) => {
    return new Promise((resolve, reject) => {
        deleteCargoExtraDetailAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateQueryFormat = (data) => {
    return new Promise((resolve, reject) => {
        updateQueryFormatAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const deleteFleet = (data) => {
    return new Promise((resolve, reject) => {
        deleteFleetAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allVesselsByCharWithoutReducer = (data) => {
    return new Promise((resolve, reject) => {
        allVesselsByCharWithoutReducerAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const portsByChar = (data) => {
    return new Promise((resolve, reject) => {
        portsByCharAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const addMultiFleet = (data) => {
    return new Promise((resolve, reject) => {
        addMultiFleetAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getEmailsFleetList = (data) => {
    return new Promise((resolve, reject) => {
        getEmailsFleetListAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getVesselsWithId = (data, isExtra) => {
    return new Promise((resolve, reject) => {
        getVesselsWithIdAPI(data, isExtra).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const saveToFleet = (data) => {
    return new Promise((resolve, reject) => {
        saveToFleetAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getPortDistanceByPortList = (data) => {
    return new Promise((resolve, reject) => {
        getPortDistanceByPortListAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateDistance = (data) => {
    return new Promise((resolve, reject) => {
        updateDistanceAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const savePortDistance = (data) => {
    return new Promise((resolve, reject) => {
        savePortDistanceAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const getCanalNameByUser = (data) => {
    return new Promise((resolve, reject) => {
        getCanalNameByUserAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const setUSerPortCanal = (data) => {
    return new Promise((resolve, reject) => {
        setUSerPortCanalAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const resetDistance = (data) => {
    return new Promise((resolve, reject) => {
        resetDistanceAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateFuelPrice = (data, data2) => {
    return new Promise((resolve, reject) => {
        updateFuelPriceAPI(data, data2).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const userFleets = (data) => {
    return new Promise((resolve, reject) => {
        userFleetsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const userFleetDataCopy = (data) => {
    return new Promise((resolve, reject) => {
        userFleetDataCopyAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allSavedResults = (data) => {
    return new Promise((resolve, reject) => {
        allSavedResultsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const saveResultById = (data) => {
    return new Promise((resolve, reject) => {
        saveResultByIdAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const deleteSavedResultById = (data) => {
    return new Promise((resolve, reject) => {
        deleteSavedResultByIdAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allVoyageResultsByChar = (data) => {
    return new Promise((resolve, reject) => {
        allVoyageResultsByCharAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allPortRegions = (data) => {
    return new Promise((resolve, reject) => {
        allPortRegionsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// export const getNewPortDistanceByPortList = (data) => {
//     return new Promise((resolve, reject) => {
//         getNewPortDistanceByPortListAPI(data).then(res => {
//             resolve(res)
//         }).catch(err => {
//             reject(err)
//         })
//     })
// }

export const clearCalculatorReducer = () => {
    return (disptach) => {
        disptach({
            type: actionsTypes.CLEAR_CALCULATOR_REDUCER
        })
    }
}