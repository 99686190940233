import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import CustomButton from '../../components/Common/CustomButton'
import CustomTextField from '../../components/Common/CustomTextField'
import { useHistory } from 'react-router-dom'

const intialRequestObj = {
    name: "",
    email: "",
    number: "",
    description: ""
}

export default function RequestSignup() {
    const history = useHistory()
    const [user, setUser] = useState({ ...intialRequestObj })

    const onChangeHandler = (e) => {
        const { name = "", value = "" } = e.target
        setUser({ ...user, [name]: value })
    }

    const onClickLogin = () => {
        history.push('/login')
    }

    return (
        <Grid container className="login-container">
            <Grid item xs={12} sm={7} md={4} lg={3} className="m-a">
                <Grid container >
                    <Grid item xs={12} className="p-10">
                        <Box className="welcome-login">
                            <h2>Welcome to FleetSigma!</h2>
                        </Box>
                        <Box p={2} className="login-container-card">

                            <Grid item xs={12}>
                                <Box pt={2} pb={6}>
                                    <h2 className="login-heading">Request Registration</h2>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <CustomTextField
                                        name="name"
                                        placeholder="Name"
                                        value={user.name}
                                        onChange={onChangeHandler}
                                    // onKeyPress={keyPressed}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <CustomTextField
                                        name="email"
                                        placeholder="Email"
                                        value={user.email}
                                        onChange={onChangeHandler}
                                    // onKeyPress={keyPressed}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <CustomTextField
                                        type="number"
                                        name="number"
                                        placeholder="Number"
                                        value={user.number}
                                        onChange={onChangeHandler}
                                    // onKeyPress={keyPressed}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={2} pb={3}>
                                    <CustomTextField
                                        name="description"
                                        placeholder="Description (optional)"
                                        value={user.description}
                                        onChange={onChangeHandler}
                                    // onKeyPress={keyPressed}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={5} display="flex" justifyContent="end">
                                    <p className="forgot-password" onClick={onClickLogin}>Login</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <CustomButton
                                    // onClick={onConfirmEmail}
                                    // loader={loginLoader}
                                    // disabled={loginLoader}
                                    >
                                        Request
                                    </CustomButton>
                                </Box>
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}