import React from 'react'
import { TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, } from "@material-ui/core"
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment"
import CustomPagination from '../../../components/Common/CustomPagination';
import CancelIcon from '@material-ui/icons/Cancel';
import { MuiPickersUtilsProvider, KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment"

export default function AllOrdersTable(props) {

    const {
        allOrganizationOrders = [],
        onClickEditQuery = () => { },
        setIsDetailView = () => { },
        searchType = {},
        onChangeSearchType = () => { },
        onClickSubmitSearch = () => { },
        onChangePagination = () => { },
        filters = {},
        onClickClear = () => { }
    } = props

    const loadSliderValue = String(searchType.loadQty).split(',')
    const createdDateValue = String(searchType.createdDate).split(',')

    return (
        <TableContainer component={Paper}>
            <Table className="universal-table">
                <TableHead>
                    <TableRow style={{ background: '#dedede' }}>
                        <TableCell>No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>First Load Port</TableCell>
                        <TableCell>Last Discharge Port</TableCell>
                        <TableCell>Cargo</TableCell>
                        <TableCell>Load Qty</TableCell>
                        <TableCell>Voy Type</TableCell>
                        <TableCell>Account</TableCell>
                        <TableCell>Created on</TableCell>
                        <TableCell>Created by</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className="searchable-tablerow">
                        <TableCell>{searchType.type ? <CancelIcon style={{ color: "#3f51b5" }} onClick={onClickClear} /> : null}</TableCell>
                        <TableCell><input name="name" value={searchType.name} onChange={onChangeSearchType} /></TableCell>
                        <TableCell><input name="firstLoadPort" value={searchType.firstLoadPort} onChange={onChangeSearchType} /></TableCell>
                        <TableCell><input name="lastDischargePort" value={searchType.lastDischargePort} onChange={onChangeSearchType} /></TableCell>
                        <TableCell><input name="cargo" value={searchType.cargo} onChange={onChangeSearchType} /></TableCell>
                        <TableCell>
                            <div className="range-load-quantity">
                                <input type="number" name="loadQty" value={loadSliderValue[0] || ''} onChange={e => onChangeSearchType(e, 'start')} />
                                <input type="number" name="loadQty" value={loadSliderValue[1] || ''} onChange={e => onChangeSearchType(e, 'end')} />
                            </div>
                        </TableCell>
                        <TableCell><input name="voyType" value={searchType.voyType} onChange={onChangeSearchType} /></TableCell>
                        <TableCell><input name="account" value={searchType.account} onChange={onChangeSearchType} /></TableCell>
                        <TableCell>
                            {/* <input name="createdDate" value={searchType.createdDate} onChange={onChangeSearchType} /> */}
                            <MuiPickersUtilsProvider utils={MomentUtils} >
                                <DatePicker
                                    autoOk
                                    fullWidth
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MMM/yyyy"
                                    className="small-date-picker"
                                    placeholder="Start"
                                    size="small"
                                    name="createdDate"
                                    clearable
                                    value={(createdDateValue && createdDateValue[0] && Number(createdDateValue[0])) || null}
                                    onChange={(date) => onChangeSearchType({ target: { name: 'createdDate', value: date } }, 'start')}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils} >
                                <DatePicker
                                    autoOk
                                    fullWidth
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MMM/yyyy"
                                    className="small-date-picker"
                                    style={{ marginLeft: 10 }}
                                    placeholder="End"
                                    size="small"
                                    clearable
                                    name="createdDate"
                                    value={(createdDateValue && createdDateValue[1] && Number(createdDateValue[1])) || null}
                                    onChange={(date) => onChangeSearchType({ target: { name: 'createdDate', value: date } }, 'end')}
                                />
                            </MuiPickersUtilsProvider>
                        </TableCell>
                        <TableCell><input name="createdUserName" value={searchType.createdUserName} onChange={onChangeSearchType} /></TableCell>
                        <TableCell><button onClick={onClickSubmitSearch}>Search</button></TableCell>
                    </TableRow>
                    {
                        Array.isArray(allOrganizationOrders) && allOrganizationOrders.length ? allOrganizationOrders.map((item, index) => {

                            return <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.name || ''}</TableCell>
                                <TableCell>{item.firstLoadPort || ''}</TableCell>
                                <TableCell>{item.lastDischargePort || ''}</TableCell>
                                <TableCell>{item.cargo || ''}</TableCell>
                                <TableCell>{item.loadQty || ''}</TableCell>
                                <TableCell>{item.voyType || ''}</TableCell>
                                <TableCell>{item.account || ''}</TableCell>
                                <TableCell>{item.createdDate ? moment(item.createdDate).format('DD/MMM/yyyy') : ''}</TableCell>
                                <TableCell>{item.createdUserName || ''}</TableCell>
                                <TableCell>
                                    <VisibilityIcon color="primary" onClick={() => { onClickEditQuery(item); setIsDetailView(true) }} />
                                </TableCell>
                            </TableRow>
                        }) : <TableRow>
                            <TableCell colSpan="10" align="center">No Order Found.</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <CustomPagination
                onChangePagination={onChangePagination}
                filters={filters}
            />
        </TableContainer >
    )
}
