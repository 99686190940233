import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import userManagmentLogo from "../../../assets/images/user.png"
import vesselInformation from "../../../assets/images/vesselicon1.png"
import organizationIcon from "../../../assets/images/organization.png"
import { isAdminLogin } from '../../../utils'

let initialOptions = [
    {
        label: "SETUP ORGANIZATION",
        url: "/setup_organization",
        image: organizationIcon,
    },
    {
        label: "USER MANAGEMENT FOR ORGANIZATION",
        url: "/user_organization",
        image: userManagmentLogo,
    },
    {
        label: "VESSEL INFORMATION",
        url: "/vessel_information",
        image: vesselInformation,
    },
]

export default function Dashboard() {
    const history = useHistory();
    const [dashboardOperations, setDashboardOperations] = useState([...initialOptions])

    useEffect(() => {
        if (!isAdminLogin()) {
            let newDashboardOperations = dashboardOperations.filter(item => item.url !== '/setup_organization')
            setDashboardOperations(newDashboardOperations)
        }
    }, [])

    return (
        <Grid container className="dashboard-container">
            <Grid item xs={12} sm={12} md={10} lg={8} className="m-a">
                <Grid container>
                    {
                        dashboardOperations.map((item, index) => {
                            const moreItems = dashboardOperations.length > 2;
                            return <Grid key={index} item xs={12} sm={6} md={moreItems ? 4 : 6}>
                                <Box p={2}>
                                    <Paper onClick={() => history.push(item.url)} className="click-on-paper">
                                        <div className="dashboard-menu-images">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <p className="dashboard-label">{item.label}</p>
                                    </Paper>
                                </Box>
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}