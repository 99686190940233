import React, { memo, useState, useMemo } from 'react'
import {
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "@material-ui/core"
import moment from "moment"
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CustomShowModal from '../Common/CustomShowModal';
import AddFleetModal from './AddCalculatorFleet/AddFleetTabView';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment"
import { getComparator, stableSort } from '../../utils';
import SplitHireModal from './SplitHireModal';
import pinIconBlack from "../../assets/icons/pin-icon-black.png"
import pinIconRed from "../../assets/icons/pin-icon-red.png"
import ConfirmModal from '../Common/CustomConfirmModal';
import { vesselTableHeading } from '../../constants';
import CustomAddModal from '../Common/CustomAddModal';
import SaveVesselLabel from './SaveVesselLabel';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

function VesselSelection(props) {
    const {
        calculatorData = {},
        setCalculatorData = () => { },
        selectedVessels = [],
        onChangeFleetRowSelect = () => { },
        onDeleteFleet = () => { },
        onClickAddFleet = () => { },
        addFleetModal = false,
        addFleetsArray = [],
        setAddFleetsArray = () => { },
        onChangeNewFleetsValue = () => { },
        setLoader = () => { },
        setAddFleetModal = () => { },
        sendCalculationRequest = () => { },
        setSelectedVessels = () => { },
        splitHireModal = false,
        setSplitHireModal = () => { },
        selectedSplitHire = {},
        setSelectedSplitHire = () => { },
        openSplitHireModal = () => { },
        onFetchPortOperationDistance = () => { },
        getInitalQueryAndFleetData = () => { }
    } = props

    const { fleetList = [], portOperation = [], clientQuery = {} } = calculatorData || {}
    const { deliveryTerms = "", tcAdcom = "" } = clientQuery || {}

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('tceq');
    const [showConfrim, setShowConfrim] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [saveModal, setSaveModal] = useState(false)
    const [selectedVessel, setSelectedVessel] = useState(null)
    const [saveLabel, setSaveLabel] = useState("")

    const onChangeHandler = (id, name, value) => {
        const findIndex = fleetList.findIndex(item => item.id === id)
        fleetList[findIndex][name] = value

        setCalculatorData({
            ...calculatorData,
            fleetList
        })
        if (id === selectedVessels.id) {
            setSelectedVessels({ ...selectedVessels, [name]: value })
        }
    }

    const onChangeSplitHire = (index, e, isDelete = false) => {
        let splitHire = (selectedSplitHire && selectedSplitHire.splitHire && JSON.parse(selectedSplitHire.splitHire)) || []
        if (isDelete) splitHire.splice(index, 1)
        else {
            const { name = "", value = "" } = e.target
            splitHire[index][name] = Number(value)
        }
        const udpatedSelectedSplitHire = { ...selectedSplitHire, splitHire: JSON.stringify(splitHire) }
        setSelectedSplitHire(udpatedSelectedSplitHire)

        setCalculatorData({
            ...calculatorData,
            fleetList: calculatorData.fleetList.map(item => {
                if (item.id === udpatedSelectedSplitHire.id) {
                    return udpatedSelectedSplitHire
                }
                return item
            })
        })
        if (udpatedSelectedSplitHire.id === selectedVessels.id) setSelectedVessels(udpatedSelectedSplitHire)
    }

    const addNewSplitHire = () => {
        let splitHire = (selectedSplitHire && selectedSplitHire.splitHire && JSON.parse(selectedSplitHire.splitHire)) || []
        if (!!splitHire.find(item => (item.NoDays === 0))) return
        splitHire.push({ NoDays: 0, applied: 0, disable: false, hireRate: 0 })
        setSelectedSplitHire({ ...selectedSplitHire, splitHire: JSON.stringify(splitHire) })
    }

    const onChangeIsReferenceVessel = (fleet) => {
        setCalculatorData({
            ...calculatorData,
            fleetList: calculatorData.fleetList.map(item => {
                return {
                    ...item,
                    isReferenceVessel: item.id === fleet.id
                }
            })
        })
    }

    const onClickDeleleButton = () => {
        onDeleteFleet(deleteId)
        setShowConfrim(false)
        setDeleteId('')
    }

    // const getArrivalTimeOfFirstLoading = useMemo(() => {
    //     let time = ''
    //     for (let i = 0; i < portOperation.length; i++) {
    //         if (portOperation[i].operation === "Loading") {
    //             time = portOperation[i].arrivalTime
    //             break;
    //         }
    //     }
    //     return time
    // }, [portOperation])

    const onClickRowChange = (vessel) => {
        // onFetchPortOperationDistance()
        onChangeFleetRowSelect(vessel, null, true)
    }

    const onChangeClientQuery = (e) => {
        const { name = "", value = "" } = e.target || {}
        setCalculatorData({
            ...calculatorData,
            clientQuery: {
                ...clientQuery,
                [name]: value
            }
        })
    }

    const onClickSaveFleet = (id, saveLabel = "") => {
        setSelectedVessel(id)
        setSaveLabel(saveLabel)
        setSaveModal(true)
    }

    const onCloseSaveFleet = () => {
        setSaveModal(false)
        setSelectedVessel(null)
        setSaveLabel("")
    }

    const onClickSaveFleetLabel = () => {
        const findIndex = fleetList.findIndex(item => item.id === selectedVessel)
        if (findIndex > -1 && saveLabel) {
            const updatedFleetList = [...fleetList]
            updatedFleetList[findIndex] = { ...updatedFleetList[findIndex], selectedForSave: true, saveLabel }
            // setCalculatorData({
            //     ...calculatorData,
            //     fleetList: updatedFleetList
            // })
            const updatedCalculatorData = {
                ...calculatorData,
                fleetList: updatedFleetList,
                autoSave: true
            }

            if (selectedVessel === selectedVessels.id) {
                setSelectedVessels({
                    ...selectedVessels,
                    saveLabel
                })
            }
            sendCalculationRequest(updatedCalculatorData)
            onCloseSaveFleet()
        }
    }

    const onChangeOrderBy = (order, orderBy) => {
        setOrder(order)
        setOrderBy(orderBy)
    }

    const filteredFleetList = useMemo(() => {
        return stableSort(fleetList, getComparator(order, orderBy));
    }, [fleetList, order, orderBy])

    return (
        <div>
            <div className="port-operation-buttons">
                <button className="add" onClick={onClickAddFleet}>+ Add Vessel</button>
                <div className="vessel-extra-actions">
                    <div className="extra-actions-div">
                        <span>TC Adcom %:</span>
                        <input type="number" name="tcAdcom" value={tcAdcom} onChange={onChangeClientQuery} />
                    </div>
                    <div className="extra-actions-div">
                        <span>Delivery Terms</span>
                        <select
                            name="deliveryTerms"
                            value={deliveryTerms}
                            onChange={onChangeClientQuery}
                        >
                            <option value="DLOSP">DLOSP</option>
                            <option value="AFSPS">AFSPS</option>
                        </select>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper} className="vessel-selection-table">
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                vesselTableHeading.map(item => {
                                    if (item.showOrder) {
                                        return <TableCell key={item.id}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                                <span>{item.title}</span>
                                                {(order === 'desc' && item.id === orderBy) ? <ArrowDownwardIcon className="table-sort-arrow active-arrow" onClick={(() => onChangeOrderBy('asc', item.id))} /> :
                                                    (order === "asc" && item.id === orderBy) ? <ArrowUpwardIcon className='table-sort-arrow active-arrow' onClick={(() => onChangeOrderBy('desc', item.id))} /> :
                                                        (order === "desc" && item.id !== orderBy) ? <ArrowDownwardIcon className='table-sort-arrow' onClick={(() => onChangeOrderBy('asc', item.id))} /> :
                                                            (order === "asc" && item.id !== orderBy) ? <ArrowUpwardIcon className='table-sort-arrow' onClick={(() => onChangeOrderBy('desc', item.id))} /> : null
                                                }
                                            </div>
                                        </TableCell>
                                    }
                                    return <TableCell key={item.id}><span>{item.title}</span></TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Array.isArray(filteredFleetList) && filteredFleetList.length ? filteredFleetList.map((vessel) => {
                                const { id, vesselName = '', dateOpen = '', port = '', gbb = 0, hireGbb = 0, hirePerDay = 0, remark = '', tcEq = 0, portToPortOperationResultMap = {},
                                    frtCost = 0, profitNet = 0, loadability = 0, vesselPremium = 0, positionPremium = 0, vessel: fleetVesselData, splitHire = '', isReferenceVessel = false, saveLabel = "" } = vessel || {}
                                const { vesselDwt = 0 } = JSON.parse(fleetVesselData) || ""
                                const isSelected = selectedVessels.id === id || false
                                const arrivalTime = portToPortOperationResultMap && portToPortOperationResultMap[1] && portToPortOperationResultMap[1].arrivalTime || ""

                                return <TableRow key={id} onClick={() => onClickRowChange(vessel)} className={isSelected ? 'selected-fleet-row' : `non-selected-fleet-row ${isReferenceVessel ? "reference-fleet" : ""}`}>
                                    <TableCell style= {{width:'12%'}}>{vesselName}</TableCell>
                                    <TableCell style= {{width:'4%'}}>{vesselDwt}</TableCell>
                                    <TableCell style= {{width:'10%'}}>{port}</TableCell>
                                    <TableCell onClick={e => e.stopPropagation()}>
                                        <MuiPickersUtilsProvider utils={MomentUtils} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                                            <DateTimePicker
                                                inputVariant="outlined"
                                                size="small"
                                                // style={{ width: 150 }}
                                                value={dateOpen || null}
                                                format="DD/MMM/yyyy HH:mm"
                                                onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
                                                onChange={date => onChangeHandler(vessel.id, "dateOpen", date)}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell style= {{width:'5%', borderLeft:'1px solid grey'}}>{Number(tcEq).toFixed(0)}</TableCell>
                                    <TableCell>
                                        <input
                                            type="number"
                                            className="vessel-list-editable-input"
                                            value={gbb || ''}
                                            placeholder="0"
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={e => onChangeHandler(vessel.id, "gbb", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ minWidth: 95, borderLeft:'1px solid grey' }}>
                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type="number"
                                                className="vessel-list-editable-input"
                                                value={hirePerDay || ''}
                                                // style={{ width: 90 }}
                                                placeholder="0"
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={e => onChangeHandler(vessel.id, "hirePerDay", e.target.value)}
                                            />
                                            <span className={`split-hire-dot ${splitHire ? "red" : "green"}`} onClick={(e) => { e.stopPropagation(); openSplitHireModal(vessel) }}>&bull;</span>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="number"
                                            className="vessel-list-editable-input"
                                            value={hireGbb || ''}
                                            placeholder="0"
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={e => onChangeHandler(vessel.id, "hireGbb", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '1px solid grey'}}>{Number(frtCost).toFixed(3)}</TableCell>
                                    <TableCell>{Number(profitNet).toFixed(0)}</TableCell>
                                    <TableCell>{Number(loadability).toFixed(0)}</TableCell>
                                    {/* <TableCell>{moment(getArrivalTimeOfFirstLoading).format('DD/MMM/yyyy HH:mm')}</TableCell> */}
                                    <TableCell style= {{width:'14%', borderLeft:'1px solid grey'}}>{arrivalTime ? moment(arrivalTime).format('DD/MMM/yyyy HH:mm') : ''}</TableCell>
                                    {/* <TableCell>Rank</TableCell> */}
                                    <TableCell style={{ borderLeft: '1px solid grey'}}>{Number(vesselPremium).toFixed(0)}</TableCell>
                                    <TableCell>{Number(positionPremium).toFixed(0)}</TableCell>
                                    <TableCell>
                                        <textarea
                                            value={remark}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={e => onChangeHandler(vessel.id, "remark", e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell onClick={e => e.stopPropagation()}>
                                        <div className="d-flex">
                                            <DeleteIcon style={{ color: "blue", fontSize: "14px", cursor: "pointer" }} onClick={e => {
                                                setDeleteId(id)
                                                setShowConfrim(true)
                                            }} />
                                            <div>
                                                <img src={isReferenceVessel ? pinIconRed : pinIconBlack} width="13px" onClick={(e) => {
                                                    onChangeIsReferenceVessel(vessel)
                                                }} alt="" />
                                            </div>
                                            <SaveIcon
                                                style={{ color: "green", fontSize: "14px", cursor: "pointer" }}
                                                onClick={() => onClickSaveFleet(id, saveLabel)}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan="18" align="center">
                                    No Vessel Found.
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomShowModal
                open={addFleetModal}
                disableClose
                handleClose={onClickAddFleet}
                maxWidth="lg"
                title="Add Fleet"
            >
                <AddFleetModal
                    calculatorData={calculatorData}
                    setCalculatorData={setCalculatorData}
                    addFleetsArray={addFleetsArray}
                    setAddFleetsArray={setAddFleetsArray}
                    onChangeNewFleetsValue={onChangeNewFleetsValue}
                    setLoader={setLoader}
                    setAddFleetModal={setAddFleetModal}
                    sendCalculationRequest={sendCalculationRequest}
                    getInitalQueryAndFleetData={getInitalQueryAndFleetData}
                    onClickAddFleet={onClickAddFleet}
                />
            </CustomShowModal>

            <CustomShowModal
                open={splitHireModal}
                handleClose={() => setSplitHireModal(false)}
                maxWidth="xs"
                title="Split Hire"
            >
                <SplitHireModal
                    addNewSplitHire={addNewSplitHire}
                    selectedSplitHire={selectedSplitHire}
                    onChangeSplitHire={onChangeSplitHire}
                />
            </CustomShowModal>

            <CustomAddModal
                open={saveModal}
                handleClose={onCloseSaveFleet}
                onSubmitConfirm={onClickSaveFleetLabel}
                maxWidth="xs"
                saveLabel="Save"
                title="Save Voyage"
            >
                <SaveVesselLabel
                    value={saveLabel}
                    onChange={e => setSaveLabel(e.target.value)}
                />
            </CustomAddModal>

            <ConfirmModal
                open={showConfrim}
                message="Are you sure you want to delete this Vessel?"
                handleClose={() => setShowConfrim(false)}
                onSubmitConfirm={onClickDeleleButton}
            />
        </div>
    )
}

export default memo(VesselSelection)
