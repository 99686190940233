import React from 'react'
import { Grid, Paper } from "@material-ui/core"
import { expenseResultField } from '../../constants'

function Expenses(props) {

    const {
        selectedVessels = {},
        onChangeSelectedVessel = () => { },
        openSplitHireModal = () => { },
        calculatorData = {},
        setCalculatorData = () => { }
    } = props || {}

    const { clientQuery = {}, portOperation = [] } = calculatorData || {}

    const onChangeQueryValues = (e) => {
        const { name = '', value = '' } = e.target
        let newPortOperation = [...portOperation]
        if (name === "freightIdea") {
            newPortOperation = newPortOperation.map(item => {
                if (item.operation === "Loading") {
                    return { ...item, freight: Number(value) }
                }
                return item
            })
        }
        setCalculatorData({
            ...calculatorData,
            clientQuery: {
                ...clientQuery,
                [name]: Number(value)
            },
            portOperation: newPortOperation
        })
    }

    const { hireGbb = 0, hirePerDay = 0, profitPerDay = 0, profitPerDayGBB = 0, tcEq = 0, gbb = 0 } = selectedVessels || {}
    const { freightMode = '', freightIdea = 0, miscRevenue = 0 } = clientQuery || {}
    const disablefreightIdea = freightMode === "Multiple Freight"

    return (
        <Grid container>
            <Grid item xs={12} className='calculator-section-header'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h4 className="result-heading">Freight and Result</h4>
                    <span style={{ marginRight: 10 }}>GBB</span>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <table className="fleet-result-table low-th-width">
                        <tr>
                            <th>Freight Rate</th>
                            <td><input type="number" value={freightIdea || 0} name="freightIdea" onChange={onChangeQueryValues} disabled={disablefreightIdea} /></td>
                        </tr>
                        <tr>
                            <th>TC Eq</th>
                            <td><p>{Number(tcEq).toFixed(2)}</p></td>
                            <td><input type="number" placeholder="0" value={gbb} name="gbb" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        </tr>
                        <tr>
                            <th style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => openSplitHireModal(selectedVessels)}>Hire Per Day</th>
                            <td><input type="number" placeholder="0" value={hirePerDay} name="hirePerDay" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                            <td><input type="number" placeholder="0" value={hireGbb} name="hireGbb" onChange={e => onChangeSelectedVessel(e, true)} /></td>
                        </tr>
                        <tr>
                            <th>Profit/day</th>
                            <td><p>{Number(profitPerDay).toFixed(2)}</p></td>
                            <td><p>{Number(profitPerDayGBB)}</p></td>
                        </tr>
                    </table>

                    <h4 className="result-heading m-t-5">Revenue</h4>
                    <table className="fleet-result-table">
                        <tr>
                            <th>Misc Revenue</th>
                            <td>
                                <input type="number" placeholder="0" onChange={onChangeQueryValues} value={miscRevenue || ''} name="miscRevenue" />
                            </td>
                        </tr>
                    </table>
                    <h4 className="result-heading m-t-5">Expenses</h4>
                    <table className="fleet-result-table">
                        {
                            expenseResultField.map((item, key) => {
                                return <tr key={key}>
                                    <th>{item.label}</th>
                                    <td><input type="number" placeholder="0" onChange={onChangeQueryValues} value={clientQuery[item.value] || ''} name={item.value} /></td>
                                </tr>
                            })
                        }
                    </table>
                </div>

            </Grid>
        </Grid>
    )
}

export default Expenses
