import * as actionTypes from "./actionsTypes"

const initialState = {
    userOrganizaton: [],
    allRoles: [],
    allVessels: [],
    allOrganization: [],
    allFleetByOrg: [],
    userOrganizatonLoading: false
}

export default function dashboard(state = { ...initialState }, actions) {
    switch (actions.type) {
        case actionTypes.USERS_ORGANIZATION: return {
            ...state,
            userOrganizatonLoading: true
        }
        case actionTypes.USERS_ORGANIZATION_SUCCESS: return {
            ...state,
            userOrganizaton: actions.payload,
            userOrganizatonLoading: false
        }
        case actionTypes.USERS_ORGANIZATION_FAILED: return {
            ...state,
            userOrganizatonLoading: false
        }
        case actionTypes.All_ROLES_SUCCESS: return {
            ...state,
            allRoles: actions.payload
        }
        case actionTypes.ALL_VESSELS_SUCCESS: return {
            ...state,
            allVessels: actions.payload
        }
        case actionTypes.GET_ALL_ORGANIZATION_SUCCESS: return {
            ...state,
            allOrganization: actions.payload
        }
        case actionTypes.ALL_FLEETS_BY_ORG_SUCCESS: return {
            ...state,
            allFleetByOrg: actions.payload
        }
        case actionTypes.EMPTY_DASHBOARD_STORE: return { ...initialState }
        default: return state
    }
}