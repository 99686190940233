
import {
    clientQueryUserAPI,
    clientQueryOrgAPI,
    getQueryFormatForUserAPI,
    addClientQueryAPI,
    addCargoToQueryListAPI,
    querydeleteAPI,
    updateCargoDetailsAPI,
    updateQueryAPI,
    updateClientQueryAPI,
    allQueryByCharForOrgAPI,
    allQueryByCharForUserAPI
} from "./api"
import * as actionTypes from "./actionTypes"

export const clientQueryUser = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            clientQueryUserAPI(data).then(res => {
                dispatch({
                    type: actionTypes.ALL_CLIENT_ORDERS,
                    payload: res
                })
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export const clientQueryOrg = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            clientQueryOrgAPI(data).then(res => {
                dispatch({
                    type: actionTypes.ALL_ORGANIZATION_ORDERS,
                    payload: res
                })
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export const getQueryFormatForUser = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getQueryFormatForUserAPI(data).then(res => {
                dispatch({
                    type: actionTypes.GET_QUERY_IN_ORDER_FORMAT,
                    payload: res
                })
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export const addClientQuery = (data) => {
    return new Promise((resolve, reject) => {
        addClientQueryAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export const addCargoToQueryList = (data, queryId) => {
    return new Promise((resolve, reject) => {
        addCargoToQueryListAPI(data, queryId).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const querydelete = (data) => {
    return new Promise((resolve, reject) => {
        querydeleteAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateCargoDetails = (data) => {
    return new Promise((resolve, reject) => {
        updateCargoDetailsAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateQuery = (data) => {
    return new Promise((resolve, reject) => {
        updateQueryAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const updateClientQuery = (data) => {
    return new Promise((resolve, reject) => {
        updateClientQueryAPI(data).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const allQueryByCharForUser = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            allQueryByCharForUserAPI(data).then(res => {
                dispatch({
                    type: actionTypes.ALL_CLIENT_ORDERS,
                    payload: res
                })
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export const allQueryByCharForOrg = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            allQueryByCharForOrgAPI(data).then(res => {
                dispatch({
                    type: actionTypes.ALL_ORGANIZATION_ORDERS,
                    payload: res
                })
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}


export const clearOrderReducer = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_ORDER_REDUCER
        })
    }
}