import { apiGet, getCurrentUserId, apiPost, apiPut } from "../../../utils"

export const clientQueryUserAPI = (data = {}) => {
    let inititalData = { limit: 10, offset: 0 }
    return apiGet('/api/v1/paginatedClientQueryUser', { ...inititalData, ...data })
}

export const clientQueryOrgAPI = (data = {}) => {
    let inititalData = { limit: 10, offset: 0 }
    return apiGet('/api/v1/paginatedClientQueryOrg', { ...inititalData, ...data })
}

export const getQueryFormatForUserAPI = () => {
    let data = { userId: getCurrentUserId() }
    return apiGet('/api/v1/getQueryFormatForUser', data)
}

export const addClientQueryAPI = (data) => {
    return apiPost('/api/v1/clientQuery', data)
}

export const addCargoToQueryListAPI = (data, queryId) => {
    return apiPost(`/api/v1/cargoList?queryId=${queryId}`, data)
}

export const querydeleteAPI = (data) => {
    return apiPost(`/api/v1/Querydelete`, data)
}

export const updateCargoDetailsAPI = (data) => {
    return apiPost(`/api/v1/updateCargoDetails`, data)
}

export const updateQueryAPI = (data) => {
    return apiPost(`​/api​/v1​/updateQuery`, data)
}

export const updateClientQueryAPI = (data) => {
    return apiPut(`/api/v1/updateClientQuery`, data)
}

export const allQueryByCharForOrgAPI = (data) => {
    return apiGet(`/api/v1/allQueryByCharForOrg`, data)
}

export const allQueryByCharForUserAPI = (data) => {
    return apiGet(`/api/v1/allQueryByCharForUser`, data)
}

