import React from 'react'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { Grid } from "@material-ui/core"

function ActionButtons(props) {
    const {
        hideOrder = false,
        setHideOrder = () => { },
        onClickCalculate = () => { },
        onClickCalculateAndSave = () => { },
        onSetHideCalculator = () => { },
        onSetOpenMap = () => { },
    } = props

    return (
        <Grid container className="actions-buttons-div">
            <Grid item xs={1}>
                <SwapHorizIcon onClick={() => setHideOrder(prev => !prev)} />
            </Grid>
            <Grid item xs={11} style={{ display: "flex", justifyContent: "space-between" }}>
                <div className='show-hide-calculator'>
                    <SwapHorizIcon onClick={onSetHideCalculator} />
                </div>
                <div className="action-button-container">
                    <button className="calculate" onClick={onSetOpenMap}>Open Map</button>
                    {/* <a href="/voyages" target="_blank"><button className="calculate">Load Saved Results</button></a> */}
                    {/* <button className="calculate" onClick={onSetHideCalculator}>Show/Hide Calculator</button> */}
                    <button className="calculate" onClick={onClickCalculate}>Calc</button>
                    <button className="calculate" onClick={onClickCalculateAndSave}>Calculate & Save</button>
                </div>
            </Grid>
        </Grid>
    )
}

export default ActionButtons
