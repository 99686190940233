import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import * as actions from "../../api"
import { Grid, Paper, Table, TableRow, TableCell, TableBody, TableHead } from "@material-ui/core"
import AccountCargoDetails from '../../../../../components/Calculator/AccountCargoDetails'
import CalculatorVesselDetails from '../../../../../components/Calculator/CalculatorVesselDetails'
import MarginAndAllowances from '../../../../../components/Calculator/MarginAndAllowances'
import Expenses from '../../../../../components/Calculator/Expenses'
import Result from '../../../../../components/Calculator/Result'
import PortOperations from '../../../../../components/Calculator/PortOperations'
import BunkerSupply from '../../../../../components/Calculator/BunkerSupply'
import MarginAllowancesTable from '../../../../../components/Calculator/MarginAllowancesTable'

export default function SingleSavedResult(props) {

    const { setLoader = () => { } } = props
    const { id = "" } = useParams()
    const [calculatorData, setCalculatorData] = useState({
        portOperation: [],
        fleetList: [],
        clientQuery: {},
    })
    const [selectedVessels, setSelectedVessels] = useState({})

    useEffect(() => {
        if (id) {
            setLoader(true, "Fetching data...")
            actions.saveResultByIdAPI(id).then(res => {
                setLoader(false)
                const { saveResultDTO = {} } = res || {}
                let { calculatorResult = {}, cargoOrder = {}, fleet = {}, portOperation = [] } = saveResultDTO
                portOperation = portOperation.map((item, index) => ({ ...item, index }))
                setCalculatorData({
                    portOperation,
                    fleetList: [{ ...fleet, ...calculatorResult }],
                    clientQuery: {},
                })
                setSelectedVessels({ ...fleet, ...calculatorResult })
            }).catch(err => {
                setLoader(false)
            })
        }
    }, [])
    console.log(calculatorData, "calculatorData")

    let splitHireArray = calculatorData.fleetList && calculatorData.fleetList[0] && calculatorData.fleetList[0].splitHire ? JSON.parse(calculatorData.fleetList[0].splitHire) : [] || []

    return (
        <Grid container className="calculator-section">
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={2}>
                        <div className="upper-section-grid">
                            <AccountCargoDetails
                                disableEdit
                                calculatorData={{}}
                                setLoader={setLoader}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <div className="upper-section-grid">
                            <CalculatorVesselDetails
                                disableEdit
                                setLoader={setLoader}
                                // setSelectedVessels={setSelectedVessels}
                                selectedVessels={selectedVessels}
                                calculatorData={calculatorData}
                            // setCalculatorData={setCalculatorData}
                            // onChangeHandler={onChangeSelectedVessel}
                            // fleetCheckbox={fleetCheckbox}
                            // setFleetCheckbox={setFleetCheckbox}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} >
                        <div className="upper-section-grid">
                            <MarginAndAllowances
                                disableEdit
                                // onChangeSelectedVessel={onChangeSelectedVessel}
                                selectedVessels={selectedVessels}
                                // setSelectedVessels={setSelectedVessels}
                                // setMarginModal={setMarginModal}
                                calculatorData={calculatorData}
                            // setCalculatorData={setCalculatorData}
                            // syncBunker={syncBunker}
                            // setSyncBunker={setSyncBunker}
                            // setSupplyModal={setSupplyModal}
                            // onChangeSyncBunker={onChangeSyncBunker}
                            // isOverride={isOverride}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={4} md={2} >
                        <div className="upper-section-grid">
                            <Expenses
                                disableEdit
                                // onChangeSelectedVessel={onChangeSelectedVessel}
                                selectedVessels={selectedVessels}
                                // openSplitHireModal={openSplitHireModal}
                                calculatorData={calculatorData}
                            // setCalculatorData={setCalculatorData}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <div className="upper-section-grid">
                            <Result
                                disableEdit
                                selectedVessels={selectedVessels}
                            // calculationErrors={calculationErrors}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="middle-section-grid">
                            <PortOperations
                                disableEdit
                                // marginModal={marginModal}
                                // setMarginModal={setMarginModal}
                                // sendCalculationRequest={sendCalculationRequest}
                                // onChangePortOperations={onChangePortOperations}
                                calculatorData={calculatorData}
                                // setCalculatorData={setCalculatorData}
                                // syncBunker={syncBunker}
                                // setSyncBunker={setSyncBunker}
                                // onAddOperations={onAddOperations}
                                // onDeleteOperations={onDeleteOperations}
                                selectedVessels={selectedVessels}
                            // supplyModal={supplyModal}
                            // setSupplyModal={setSupplyModal}
                            // onChangeSyncBunker={onChangeSyncBunker}
                            // onFetchPortOperationDistance={onFetchPortOperationDistance}
                            // fetchPortDistance={fetchPortDistance}
                            // openAddOperation={openAddOperation}
                            // setOpenAddOperation={setOpenAddOperation}
                            // onChangeSelectedVessel={onChangeSelectedVessel}
                            // isOverride={isOverride}
                            // setIsOverride={onChangeOverride}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div className="lower-section-grid">
                            <MarginAllowancesTable
                                disableEdit
                                calculatorData={calculatorData}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={4}>
                        <div className="lower-section-grid">
                            <div className="selected-vessel-remark">
                                <p>
                                    Remarks:
                                    <span>
                                        {(selectedVessels && selectedVessels.remark) || ''}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <div className="lower-section-grid">
                            <BunkerSupply
                                disableEdit
                                portOperation={calculatorData.portOperation}
                                // syncBunker={syncBunker}
                                // setSyncBunker={setSyncBunker}
                                // clientQuery={clientQuery}
                                // onChangeBunkerScrubber={onChangeBunkerScrubber}
                                selectedVessels={selectedVessels}
                            // onChangeSyncBunker={onChangeSyncBunker}
                            // onChangeSelectedVessel={onChangeSelectedVessel}
                            // onChangePortOperations={onChangePortOperations}
                            // isOverride={isOverride}
                            // setIsOverride={setIsOverride}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={4}>
                        <div className="lower-section-grid">
                            <Paper>
                                <h2>Split hire</h2>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Hire Rate</TableCell>
                                            <TableCell>No. of Days</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            Array.isArray(splitHireArray) && splitHireArray.map(item => {
                                                return <TableRow>
                                                    <TableCell>{item.hireRate}</TableCell>
                                                    <TableCell>{item.NoDays}</TableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                            <div className="detail-map-view">
                                <p>Space For Map</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}