import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Zoom } from '@material-ui/core';

export default function CustomAddModal(props) {
    const {
        handleClose,
        title,
        onSubmitConfirm,
        open,
        saveLabel = 'Add',
        maxWidth = "sm",
        addThirdBtn = false,
        thirdBtnAction = () => { },
        thirdBtnLabel = '',
        isDetailView = false
    } = props;
    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                maxWidth={maxWidth}
                TransitionComponent={Zoom}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="font-family">{isDetailView ? "Details" : title}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description" className="font-family"> */}
                    {props.children}
                    {/* </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    {addThirdBtn ? <Button onClick={thirdBtnAction} color="primary" variant="outlined" className="font-family">
                        {thirdBtnLabel}
                    </Button> : null}
                    {isDetailView ? null : <Button onClick={onSubmitConfirm} color="primary" variant="outlined" className="font-family">
                        {saveLabel}
                    </Button>}
                    <Button onClick={handleClose} color="secondary" variant="outlined" className="font-family" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}