import React, { useState, useEffect, useRef } from 'react'
import { Grid, Box, Paper, makeStyles, Tabs, Tab, useTheme } from "@material-ui/core"
import CustomButton from '../../../components/Common/CustomButton';
import CustomAddModal from '../../../components/Common/CustomAddModal';
import CustomConfirmModal from '../../../components/Common/CustomConfirmModal';
import AddNewOrder from './AddNewOrder';
import * as actions from "./actions"
import { useDispatch, useSelector } from "react-redux"
import * as calculatorActions from "../Calculator/actions"
import { toastError, toastSuccess } from '../../../utils';
import AllOrdersTable from './AllOrdersTable';
import MyOrdersTable from './MyOrdersTable';
import { sortedPortsByFormat } from './dataMapping';
import moment from "moment"
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles({
    root: {
        width: "fit-content",
    },
});

const initialSearchType = {
    type: '',
    name: '',
    firstLoadPort: '',
    lastDischargePort: '',
    cargo: '',
    loadQty: '',
    voyType: '',
    account: '',
    createdDate: '',
    createdUserName: ''
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function Orders(props) {
    const { setLoader = () => { } } = props
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { allClientOrders = [], allOrganizationOrders = [], queryOrderList = [] } = useSelector(state => state.orders) || {}

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false)

    const [selectedQuery, setSelectedQuery] = useState({})
    const [selectedPorts, setSelectedPorts] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [isDetailView, setIsDetailView] = useState(false)
    const [searchType, setSearchType] = useState({ ...initialSearchType })
    const [myFilters, setMyFilters] = useState({
        offset: 0,
        limit: 10
    })
    const [allFilters, setAllFilters] = useState({
        offset: 0,
        limit: 10
    })
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState({})

    const addQueryModalRef = useRef();

    useEffect(() => {
        setLoader(true)
        Promise.all([
            dispatch(actions.clientQueryOrg()),
            dispatch(actions.clientQueryUser()),
            dispatch(actions.getQueryFormatForUser())
        ]).then(() => {
            setLoader(false)
        }).catch((err) => {
            setLoader(false);
            toastError(err.message || 'Something went wrong.')
        })
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchType({ ...initialSearchType })
    };

    const onCloseModal = () => {
        setOpen(false)
        setIsEdit(false)
        setIsDetailView(false)
        setSelectedQuery({})
        setSelectedPorts([])
    }

    const onClickEditQuery = (query) => {
        setLoader(true)
        setSelectedQuery(query)
        calculatorActions.cargoByQuery(query.id).then(res => {
            setLoader(false)
            let portOperations = sortedPortsByFormat(query, res)
            setSelectedPorts(portOperations.filter(item => item.operation !== 'Commence'))
            setOpen(true)
            setIsEdit(true)
        }).catch((err) => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })

    }

    const onChangePinQuery = (queryId) => {
        const isFindIndex = queryOrderList.findIndex(item => item === queryId)
        let updatedQueryList = [...queryOrderList]
        if (isFindIndex > -1) {
            updatedQueryList.splice(isFindIndex, 1)
        } else {
            updatedQueryList.push(queryId)
        }
        setLoader(true)
        if (updatedQueryList.length === 0) updatedQueryList = [null]
        calculatorActions.updateQueryFormat(updatedQueryList).then(async res => {
            toastSuccess('Query format updated.')
            await dispatch(actions.getQueryFormatForUser())
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            toastError('Error while updating query list')
        })
    }

    const onDeleteQuery = () => {
        const isFindIndex = queryOrderList.findIndex(item => item === deleteId.id)
        let updatedQueryList = [...queryOrderList]
        if (isFindIndex > -1) {
            updatedQueryList.splice(isFindIndex, 1)
        }
        setLoader(true)
        Promise.all([
            calculatorActions.updateQueryFormat(updatedQueryList),
            actions.querydelete(deleteId),
        ]).then(async () => {
            toastSuccess('Query deleted successfully')
            setDeleteModal(false)
            await dispatch(actions.clientQueryUser())
            setLoader(false)
        }).catch((err) => {
            setDeleteModal(false)
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const onChangeSearchType = (e, key = '') => {
        let { name = '', value = '' } = e.target || {}
        if (!value) name = ''
        let updatedSearchType = { ...searchType }
        if (name === 'loadQty') {
            let valueSplit = updatedSearchType[name].split(",")
            let startValue = valueSplit[0] || ''
            let endValue = valueSplit[1] || ''
            if (key === 'start') startValue = value
            else endValue = value
            value = `${startValue},${endValue}`
        } else if (name === 'createdDate') {
            let valueSplit = updatedSearchType[name].split(",")
            let startValue = valueSplit[0] || ''
            let endValue = valueSplit[1] || ''
            if (key === 'start') startValue = moment(value).valueOf()
            else endValue = moment(value).valueOf()
            value = `${startValue},${endValue}`
        }
        setSearchType({ ...initialSearchType, type: name, [name]: value })
    }

    const onClickSubmitSearch = async () => {
        setLoader(true)
        if (searchType.type) {
            let searchRequest = actions.allQueryByCharForUser
            if (value !== 1) searchRequest = actions.allQueryByCharForOrg
            await dispatch(searchRequest({ searchParam: searchType[searchType.type], type: searchType.type, offset: 10 }))
        } else {
            let searchRequest = actions.clientQueryUser
            if (value !== 1) searchRequest = actions.clientQueryOrg
            await dispatch(searchRequest())
        }
        setLoader(false)
    }

    const onSubmitConfirm = () => {
        if (addQueryModalRef.current.onSubmitQuery) {
            addQueryModalRef.current.onSubmitQuery()
        }
    }

    const onClickCreateCopy = () => {
        if (addQueryModalRef.current.onSubmitQuery) {
            addQueryModalRef.current.onSubmitQuery(true)
        }
    }

    const onChangeMyPagination = (type = '') => {
        let newMyFilters = {}
        if (type === 'inc') {
            newMyFilters = { ...myFilters, offset: myFilters.offset + 1 }

        } else if (type === 'first') {
            newMyFilters = { ...myFilters, offset: 0 }
        } else {
            if (myFilters.offset === 0) return
            newMyFilters = { ...myFilters, offset: myFilters.offset - 1 }
        }
        setLoader(true)
        dispatch(actions.clientQueryUser(newMyFilters)).then(() => {
            setLoader(false)
            setMyFilters(newMyFilters)
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const onChangeAllPagination = (type = '') => {
        let newallFilters = {}
        if (type === 'inc') {
            newallFilters = { ...allFilters, offset: allFilters.offset + 1 }

        } else if (type === 'first') {
            newallFilters = { ...allFilters, offset: 0 }
        } else {
            if (allFilters.offset === 0) return
            newallFilters = { ...allFilters, offset: allFilters.offset - 1 }
        }
        setLoader(true)
        dispatch(actions.clientQueryOrg(newallFilters)).then(() => {
            setLoader(false)
            setAllFilters(newallFilters)
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const onClickClearMyOrders = () => {
        setLoader(true)
        dispatch(actions.clientQueryUser()).then(() => {
            setSearchType({ ...initialSearchType })
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const onClickClearAllOrders = () => {
        setLoader(true)
        dispatch(actions.clientQueryOrg()).then(() => {
            setSearchType({ ...initialSearchType })
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const onOpenDeleteModal = (query) => {
        setDeleteId(query)
        setDeleteModal(true)
    }
    const handleChangeIndex = (index) => {
        setValue(index)
    }

    return (
        <Grid container>

            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Paper className={classes.root}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={0} label="My Orders" />
                                <Tab value={1} label="All Orders" />
                            </Tabs>
                        </Paper>
                    </Box>
                    <div style={{ width: "115px" }}><CustomButton onClick={() => setOpen(true)}>New Order</CustomButton></div>
                </Box>
            </Grid>


            <Grid item xs={12}>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <Box pt={2}>
                        <MyOrdersTable
                            allClientOrders={allClientOrders}
                            queryOrderList={queryOrderList}
                            onClickEditQuery={onClickEditQuery}
                            onChangePinQuery={onChangePinQuery}
                            onDeleteQuery={onOpenDeleteModal}
                            searchType={searchType}
                            onChangeSearchType={onChangeSearchType}
                            onClickSubmitSearch={onClickSubmitSearch}
                            filters={myFilters}
                            onChangePagination={onChangeMyPagination}
                            onClickClear={onClickClearMyOrders}
                        />
                    </Box>
                    <Box pt={2}>
                        <AllOrdersTable
                            allOrganizationOrders={allOrganizationOrders}
                            onClickEditQuery={onClickEditQuery}
                            setIsDetailView={setIsDetailView}
                            searchType={searchType}
                            onChangeSearchType={onChangeSearchType}
                            onClickSubmitSearch={onClickSubmitSearch}
                            filters={allFilters}
                            onChangePagination={onChangeAllPagination}
                            onClickClear={onClickClearAllOrders}
                        />
                    </Box>

                </SwipeableViews>
            </Grid>
            <CustomAddModal
                open={open}
                title={isEdit ? "Update Order" : "New Order"}
                maxWidth="xl"
                saveLabel={isEdit ? "Update" : "Add"}
                addThirdBtn={isEdit}
                thirdBtnAction={onClickCreateCopy}
                thirdBtnLabel="Create New Order"
                handleClose={onCloseModal}
                isDetailView={isDetailView}
                onSubmitConfirm={onSubmitConfirm}
            >
                <AddNewOrder
                    ref={addQueryModalRef}
                    setLoader={setLoader}
                    onCloseModal={onCloseModal}
                    selectedQuery={selectedQuery}
                    selectedPorts={selectedPorts}
                    isEdit={isEdit}
                    onChangePinQuery={onChangePinQuery}
                    isDetailView={isDetailView}
                    setIsEdit={setIsEdit}
                />
            </CustomAddModal>

            <CustomConfirmModal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                message="Are you sure to delete this order?"
                onSubmitConfirm={onDeleteQuery}
            />

        </Grid>
    )
}

export default Orders
