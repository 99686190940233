import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom"

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function CustomDrawer(props) {
    const { openDrawer, onClickMenu, validRoutes = [], activeRoute = '' } = props;
    const classes = useStyles();
    const history = useHistory();

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={onClickMenu}
            onKeyDown={onClickMenu}
        >
            <List>
                {validRoutes.map((item, index) => {
                    const selectedRoute = item.activeRoutes.includes(activeRoute)
                    return <ListItem button key={index} className={selectedRoute ? "selected-route" : ""} onClick={() => history.push(item.url)}>
                        {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                        <div className='drawer-icon-div'><img src={selectedRoute ? item.whiteImg : item.blackImg} alt="" /></div>
                        <ListItemText primary={item.label} />
                    </ListItem>
                })}
            </List>
        </div>
    );

    return (
        <div>
            <Drawer anchor="left" open={openDrawer} onClose={onClickMenu}>
                {list()}
            </Drawer>
        </div>
    );
}