import React from 'react'
import { Grid, Table, Paper, TableContainer, TableCell, TableRow, TableBody, TableHead } from "@material-ui/core"

function EmailTableContainer(props) {

    const {
        emailParserData = [],
        onSearchByOrgId = () => { },
        selectedEmail = {},
    } = props

    return (
        <Grid container className="m-t-20">
            <Grid item xs={12} className="mui-selected-grey" style={{ height: '280px', overflow: 'auto' }}>
                <TableContainer component={Paper} className="vessel-selection-table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vessel</TableCell>
                                <TableCell>Dwt</TableCell>
                                <TableCell>Built</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Region</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Gears</TableCell>
                                <TableCell>Sender</TableCell>
                                <TableCell>Updated On</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                emailParserData.length ? emailParserData.map(email => {
                                    const { id = "", vesselName = "", dwt = "", built = "", portName = "", portRegion = "", createdDate = "", gears = "",
                                        sender = "", lastModifiedDate = ""
                                    } = email
                                    return <TableRow key={id} hover style={{ cursor: "pointer" }} selected={selectedEmail.id === id} onClick={() => onSearchByOrgId(email)}>
                                        <TableCell>{vesselName}</TableCell>
                                        <TableCell>{dwt}</TableCell>
                                        <TableCell>{built}</TableCell>
                                        <TableCell>{portName}</TableCell>
                                        <TableCell>{portRegion}</TableCell>
                                        <TableCell>{createdDate}</TableCell>
                                        <TableCell>{gears}</TableCell>
                                        <TableCell>{sender}</TableCell>
                                        <TableCell>{lastModifiedDate}</TableCell>
                                    </TableRow>
                                }) :
                                    <TableRow>
                                        <TableCell colSpan={10} align="center">No data found.</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default EmailTableContainer
