import * as actionTypes from "./actionsTypes"

const initialState = {
    errorList: [],
    errorTitle: 'sadasdasdas',
    errorOpen: false
}

export default function dashboard(state = { ...initialState }, actions) {
    switch (actions.type) {
        case actionTypes.OPEN_ERROR_ALERT: return {
            ...state,
            errorOpen: true,
            errorList: actions.payload.errorList || [],
            errorTitle: actions.payload.errorTitle || 'List Of Errors'
        }
        case actionTypes.ADD_ONLY_ERRORS: {
            const setObj = {
                ...state,
                errorList: actions.payload.errorList || [],
                errorTitle: actions.payload.errorTitle || 'List Of Errors'

            }
            if (actions.payload.errorList.length === 0) setObj.errorOpen = false
            return setObj
        }
        case actionTypes.CLOSE_ERROR_ALERT: return {
            ...state,
            errorOpen: false,
            errorList: [],
            errorTitle: 'List Of Errors'
        }
        default: return state
    }
}