import React from 'react'
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Autocomplete from "@material-ui/lab/Autocomplete"

function AddPortsModal(props) {

    const {
        selectedOperation = {},
        delayPortsSearch = () => { },
        onSelectPortsOfOperation = () => { },
        portOptions = [],
        portLoading = false,
        onClickCalculateDistance = () => { }
    } = props;

    let newPortOptions = [...portOptions]
    let selectedPort = { label: '', value: '' }
    if (selectedOperation.port) {
        // newPortOptions = [...portOptions, { label: selectedOperation.port, value: selectedOperation.port }]
        selectedPort = { label: selectedOperation.port, value: selectedOperation.port }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Autocomplete
                    options={newPortOptions}
                    value={selectedPort}
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => onSelectPortsOfOperation(value)}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search ports"
                            value={selectedPort.label}
                            onChange={e => delayPortsSearch(selectedOperation, e.target.value)}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {portLoading ? <CircularProgress color="inherit" size={14} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <button className="calculate-port-distance-btn" onClick={onClickCalculateDistance}>Calculate Port Distance</button>
            </Grid>
        </Grid>
    )
}

export default AddPortsModal
