import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom"
import * as Components from "./containers"
import { getRolePermisionRoutes, isLoggedIn } from "./utils"
import App from "./app/App";
import OAuth2RedirectHandler from "./user/oauth2/OAuth2RedirectHandler";

const UserRoute = ({ component: Component, ...rest }) => {
  return <Route
    {...rest}
    render={props => {
      let isValidRoute = getRolePermisionRoutes().includes(rest.path)

      if(rest.path === '/welcome') {
        isValidRoute = true
      }
      console.log(isValidRoute,  "isValidRoute")
      const secoundaryRoute = getRolePermisionRoutes()[0]
      return isLoggedIn() ? isValidRoute ? (
        <Components.UserMain {...props} {...rest}>
          <Component {...props} />
        </Components.UserMain>
      ) : <Components.UserMain {...props} {...rest}>
        <Redirect to={secoundaryRoute} />
      </Components.UserMain> :
        (
          <Redirect to='/login' />
        )
    }
    }
  />
}

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route
    {...rest}
    render={props => isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />
    }
  />
}

function Routes() {

  return (
    <Router>
      <Switch>
        <UserRoute exact path="/" component={Components.Dashboard} routeName="Dashboard" />
        <UserRoute exact path="/welcome" component={Components.Welcome} routeName="Welcome" />
        <UserRoute exact path="/user_organization" component={Components.UserMgntOrg} routeName="User Managment Organization" />
        <UserRoute exact path="/vessel_information" component={Components.VesselInformation} routeName="Vessel Information" />
        <UserRoute exact path="/setup_organization" component={Components.SetupOrganization} routeName="Setup Organization" />
        <UserRoute exact path="/orders" component={Components.Orders} routeName="Orders" />
        <UserRoute exact path="/voyages" component={Components.Voyages} routeName="Voyages" />
        <UserRoute exact path="/calculator" component={Components.Calculator} routeName="Matrix" />
        <UserRoute exact path="/email_parser" component={Components.EmailParser} routeName="Email Parser" />
        <UserRoute exact path="/vessel/:id" component={Components.SingleVessel} routeName="Vessel Information" />
        <UserRoute exact path="/saved/:id" component={Components.SingleSavedResult} routeName="Saved Results" />
        <UserRoute exact path="/saved" component={Components.SavedResults} routeName="Saved Results" />
        <UserRoute exact path="/map/:id" component={Components.ShowMap} routeName="Map" />
        <PublicRoute exact path="/login" component={Components.Auth} />
        <PublicRoute exact path="/forgot_password" component={Components.ForgotPassword} />
        <PublicRoute exact path="/request_signup" component={Components.RequestSignup} />
        <PublicRoute exact path="/reset_password" component={Components.ResetPassword} />
        <PublicRoute exact path="/google" component={App} />
        <PublicRoute exact path="/oauth2/redirect" component={OAuth2RedirectHandler} />
        <PublicRoute exact path="/profile" component={App} />
      </Switch>
    </Router>
  )
}

export default Routes;
