import React, { useState, useEffect } from 'react'
import ButtonAppBar from '../../../components/Main/ButtonAppBar'
import CustomDrawer from '../../../components/Main/CustomDrawer'
import { Box, Grid, CircularProgress } from "@material-ui/core"
import * as dashboardActions from "../Dashboard/actions"
import { useDispatch, useSelector } from "react-redux"
import CustomErrorAlert from '../../../components/Common/CustomErrorAlert'
import { getRolePermisionRoutes } from '../../../utils'
import { userDrawerList } from '../../../constants'
import { useRouteMatch } from 'react-router-dom'

function UserMain(props) {
    const { routeName = '' } = props
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false)
    const [loader, setLoader] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [loadingMsg, setlLoadingMsg] = useState(false)
    const { errorList = [], errorTitle = '', errorOpen = false } = useSelector(state => state.common)
    const routeMatch = useRouteMatch();

    useEffect(() => {
        dispatch(dashboardActions.getAllOrganizations())
    }, [])

    const onClickMenu = () => {
        setOpenDrawer(!openDrawer)
    }

    const onSetLoader = (action, loadingMessage = null) => {
        setLoader(action)
        if (loadingMessage) setlLoadingMsg(loadingMessage)
    }

    const validRoutes = userDrawerList.filter(item => {
        return getRolePermisionRoutes().includes(item.url)
    })

    return (
        <div>
            {loader ? <div className="main-advanced-loader" >
                <div className="loader-wrapper">
                    <CircularProgress size={45} />
                    {loadingMsg ? <div><p>{loadingMsg}</p></div> : null}
                </div>
            </div> : null}
            <ButtonAppBar onClickMenu={onClickMenu} title={routeName} validRoutes={validRoutes} activeRoute={routeMatch.path} />
            <CustomDrawer openDrawer={openDrawer} onClickMenu={onClickMenu} validRoutes={validRoutes} activeRoute={routeMatch.path} />
            <Grid container className="main-parent-container">
                <Grid item xs={12} style={{ height: "calc(100vh - 50px)" }}>
                    <Box p={isFullScreen ? 0 : 2}>
                        {React.cloneElement(props.children, { setLoader: onSetLoader, setIsFullScreen, loader })}
                    </Box>
                </Grid>
            </Grid>
            {errorOpen ?
                <CustomErrorAlert
                    errorList={errorList}
                    errorTitle={errorTitle}
                />
                : null}
        </div >
    )
}

export default UserMain
