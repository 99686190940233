import React, { useState } from 'react'
import { Grid, CircularProgress, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { operationsOptions } from '../../constants'

function AddOperationsModal(props) {

    const {
        selectedOperation = {},
        delayPortsSearch = () => { },
        portOptions = [],
        portLoading = false,
        onClickCalculateDistance = () => { },
        onSelectPortsOfOperation = () => { },
        initialAddOperation = {},
        setInitialAddOperation = () => { },
        onAddOperationByModal = () => { }
    } = props

    let newPortOptions = [...portOptions]
    let selectedPort = { label: '', value: '' }
    if (initialAddOperation.port) {
        // newPortOptions = [...portOptions, { label: initialAddOperation.port, value: initialAddOperation.port }]
        selectedPort = { label: initialAddOperation.port, value: initialAddOperation.port }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <p style={{ marginBottom: 5 }}>Select Operation</p>
                <select
                    value={initialAddOperation.operation || ''}
                    name="operation"
                    onChange={e => setInitialAddOperation({ ...initialAddOperation, operation: e.target.value })}
                    className="add-operation-modal-select"
                >
                    {operationsOptions.map((option, key) => <option key={key} value={option.value} disabled={option.isDisabled}>{option.label}</option>)}
                </select>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <p style={{ marginBottom: 5 }}>Select Port</p>
                <Autocomplete
                    options={(portLoading || newPortOptions.length === 0) ? [] : newPortOptions}
                    value={selectedPort}
                    disableClearable
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => setInitialAddOperation({ ...initialAddOperation, port: value.value })}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search port"
                            value={selectedPort.label}
                            onChange={e => delayPortsSearch(initialAddOperation, e.target.value, true)}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {portLoading ? <CircularProgress color="inherit" size={14} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
                <p style={{ marginBottom: 5 }}>Port Exp</p>
                <input type="number" className="add-operation-input" value={initialAddOperation.portExp} onChange={e => setInitialAddOperation({ ...initialAddOperation, portExp: Number(e.target.value) })} />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
                <p style={{ marginBottom: 5 }}>Extra Days</p>
                <input type="number" className="add-operation-input" value={initialAddOperation.extraDays} onChange={e => setInitialAddOperation({ ...initialAddOperation, extraDays: Number(e.target.value) })} />
            </Grid>

            <Grid item xs={12}>
                <button className="calculate-port-distance-btn" onClick={onAddOperationByModal}>Add Operation</button>
            </Grid>
        </Grid>
    )
}

export default AddOperationsModal
