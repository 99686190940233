import { validateEmail } from "../utils"

export default function loginValidation(data) {
    let errors = {}
    if (!data.email) {
        errors.email = 'Email is required'
    }
    if (data.email && !validateEmail(data.email)) {
        errors.email = 'Invalid Email'
    }
    if (!data.password) {
        errors.password = 'Password is required'
    }
    return {
        isValid: Object.keys(errors).length === 0,
        errors
    }
}