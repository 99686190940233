import React from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
    portOperationHeader,
    portOperationDisabledFields,
    operationsOptions,
    loadlineOptions,
    termsCoEfficientTable,
    portEmissionOptions
} from "../../constants";

const DragHandle = SortableHandle(() => <span><MenuIcon /></span>);

const SortableItem = SortableElement((props) => {
    return (
        <div className="sortable-row-tr">
            {props.children}
        </div>
    );
});

const styles = (index) => {
    const width = portOperationHeader[index].width
    return { minWidth: width, width }
}


const DraggableList = SortableContainer(props => {
    let {
        combinedOperation = [],
        onDeleteOperations = () => { },
        onChangePortOperations = () => { },
        onClickSinglePort = () => { },
        showFreightSelection = false,
        cargoTxOverride = false,
        dischargedPortIndexes = []
    } = props;

    return (
        <div className="sortable-div">
            {combinedOperation && combinedOperation.map((operations, index) => {
                const checkDisabled = portOperationDisabledFields[operations.operation] || {}
                const { bunkerSuppliedHSFO = 0, bunkerSuppliedLSFO = 0, bunkerSuppliedMGO = 0, distNormal = 0, distEca = 0 } = operations.cargoDetails || {}
                const totalSupply = Number(bunkerSuppliedHSFO) + Number(bunkerSuppliedLSFO) + Number(bunkerSuppliedMGO)

                let dischargedPort = dischargedPortIndexes.includes(index)
                let operationLbmarker = operations.lbmarker === "ballast" ? "B" : operations.lbmarker === "laden" ? "L" : ""

                return (
                    <SortableItem key={index} index={index}>
                        <div className="operation-input-div" style={styles(0)}>
                            {index === 0 ? <MenuIcon /> : <DragHandle />}
                        </div>
                        <div className="operation-input-div" style={styles(1)}>
                            {operationLbmarker}
                        </div> {/* NO */}
                        <div className="operation-input-div" style={styles(2)}>
                            {/* {operations.operation || 0} */}
                            <select
                                value={operations.operation || ''}
                                name="operation"
                                onChange={e => onChangePortOperations(index, e)}
                                disabled={checkDisabled.operation}
                            >
                                {operationsOptions.map((option, key) => <option key={key} value={option.value} disabled={option.isDisabled}>{option.label}</option>)}
                            </select>
                        </div>
                        <div className="operation-input-div" style={styles(3)}>
                            <p className="add-three-dots" onClick={() => {
                                if (index === 0 || operations.operation === 'Loading' || operations.operation === 'Discharging') return
                                onClickSinglePort(operations)
                            }}>{operations.port || ''}</p>
                        </div>
                        <div className="operation-input-div" style={styles(4)}>
                            {/* dist normal */}
                            <input
                                type="number"
                                value={(distNormal) || ''}
                                placeholder="0"
                                disabled={!!checkDisabled.distNormal}
                                name="distNormal"
                                onChange={e => onChangePortOperations(index, e, true)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(5)}>
                            {/* {(operations.cargoDetails && operations.cargoDetails.distEca) || 0} */}
                            <input
                                type="number"
                                value={(distEca) || ''}
                                placeholder="0"
                                disabled={!!checkDisabled.distEca}
                                name="distEca"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e, true)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(6)}>
                            <input
                                type="number"
                                value={operations.dwf || ''}
                                disabled={!!checkDisabled.dwf}
                                name="dwf"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(7)}>
                            {/*  speed normal */}
                            {/* <input
                                type="number"
                                value={operations.dwf}
                                disabled={!!checkDisabled.dwf}
                                name="dwf"
                                onChange={e => onChangePortOperations(index, e)}
                            /> */}
                            {operations.speedNormal || 0}

                        </div>
                        <div className="operation-input-div" style={styles(8)}>
                            {/*  speed eca */}
                            {/* <input
                                type="number"
                                value={operations.dwf}
                                disabled={!!checkDisabled.dwf}
                                name="dwf"
                                onChange={e => onChangePortOperations(index, e)}
                            /> */}
                            {operations.speedECA || 0}
                        </div>
                        <div className="operation-input-div" style={styles(9)}>
                            {/*  cargo tx */}
                            {(cargoTxOverride && dischargedPort) ? <input
                                type="number"
                                value={operations.cargoTxOverriddenValue || ''}
                                // disabled={!!checkDisabled.dwf}
                                placeholder="0"
                                name="cargoTxOverriddenValue"
                                onChange={e => onChangePortOperations(index, e)}

                            /> : operations.cargoTx || ''}
                        </div>
                        <div className="operation-input-div" style={styles(10)}>
                            {/*  rate MT day */}
                            <input
                                type="number"
                                value={operations.rate || ''}
                                disabled={!!checkDisabled.rate}
                                name="rate"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(11)}>
                            {/* {operations.terms}  */}
                            <select
                                value={operations.terms || ""}
                                name="terms"
                                onChange={e => onChangePortOperations(index, e)}
                                disabled={checkDisabled.terms}
                            >
                                {termsCoEfficientTable.map((option, key) => <option key={key} value={option.label}>{option.label}</option>)}
                            </select>
                        </div>
                        <div className="operation-input-div" style={styles(12)}>
                            <p>{operations.factor}</p>
                        </div>
                        <div className="operation-input-div" style={styles(13)}>
                            {/* {operations.turnTime} */}
                            <input
                                type="number"
                                value={operations.turnTime || ''}
                                disabled={!!checkDisabled.turnTime}
                                name="turnTime"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        {showFreightSelection ? <div className="operation-input-div" style={styles(13)}>
                            {/* {operations.turnTime} */}
                            <input
                                type="number"
                                value={operations.freight || ''}
                                disabled={!!checkDisabled.freight}
                                name="freight"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div> : null}
                        <div className="operation-input-div" style={styles(14)}>
                            {/* {operations.extraDays} */}
                            <input
                                type="number"
                                value={operations.extraDays || ''}
                                disabled={!!checkDisabled.extraDays}
                                name="extraDays"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(15)}>
                            {/* {operations.portExp} */}
                            <input
                                type="number"
                                value={operations.portExp || ''}
                                disabled={!!checkDisabled.portExp}
                                name="portExp"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(16)}>
                            <p> {operations.portStay || 0}</p>
                        </div>
                        <div className="operation-input-div" style={styles(17)}>
                            {/*  Port Emission */}
                            <select
                                value={operations.sulfur || ""}
                                name="sulfur"
                                onChange={e => onChangePortOperations(index, e)}
                                disabled={checkDisabled.sulfur}
                            >
                                {portEmissionOptions.map((option, key) => <option key={key} value={option.value}>{option.label}</option>)}
                            </select>
                        </div>
                        <div className="operation-input-div" style={styles(18)}>
                            {/* {operations.loadline} */}
                            <select
                                value={operations.loadline || ""}
                                name="loadline"
                                onChange={e => onChangePortOperations(index, e)}
                                disabled={checkDisabled.loadline}
                            >
                                {loadlineOptions.map((option, key) => <option key={key} value={option.value}>{option.label}</option>)}
                            </select>
                        </div>
                        <div className="operation-input-div" style={styles(19)}>
                            {/* {operations.draftRes} */}
                            <input
                                type="number"
                                value={operations.draftRes || ''}
                                disabled={!!checkDisabled.draftRes}
                                name="draftRes"
                                placeholder="0"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(20)}>
                            {/* {operations.waterDensity} */}
                            <input
                                type="number"
                                value={operations.waterDensity || ''}
                                placeholder="0"
                                disabled={!!checkDisabled.waterDensity}
                                name="waterDensity"
                                onChange={e => onChangePortOperations(index, e)}
                            />
                        </div>
                        <div className="operation-input-div" style={styles(21)}>
                            {/* {operations.supply} */}
                            <input
                                type="number"
                                value={totalSupply}
                                disabled
                            />
                        </div>
                        <div className="operation-input-div" style={styles(22)}>
                            {!!checkDisabled.canDelete ? <DeleteIcon onClick={() => onDeleteOperations(index, operations.id)} /> : null}
                        </div>
                    </SortableItem>
                );
            })}
        </div>
    );
});

export default DraggableList