import React, { useEffect, useState } from 'react'
import { Grid, Paper, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core"
import EmailParserUI from '../../../components/EmailParser/EmailParserUI'
import EmailTableContainer from '../../../components/EmailParser/EmailTableContainer'
import * as calculatorActions from "../Calculator/actions"
import moment from "moment"
import { getOrganizationId, toastError } from '../../../utils'
import * as actions from "./actions"

const initialAutoFilters = {
    updateSince: { label: "1", value: "1" },
    portRegion: [{ label: "Unknown", value: "Unknown" }],
    vesselType: { label: "All", value: "0_99999999" },
    gear: { label: "All", value: "All" }
}

function EmailParser(props) {

    const { setLoader = () => { } } = props

    const [addFleetRegionOptions, setAddFleetRegionOptions] = useState([])
    const [autoFilters, setAutoFilters] = useState({ ...initialAutoFilters })
    const [emailParserData, setEmailParserData] = useState([])
    const [selectedEmail, setSelectedEmail] = useState({})
    const [selectedEmailData, setSelectedEmailData] = useState([])
    const [selectedSingleEmail, setSelectedSingleEmail] = useState({})

    useEffect(() => {
        calculatorActions.allPortRegions().then(res => {
            const regionOptions = (Array.isArray(res) && res.map(region => ({ label: region, value: region }))) || []
            setAddFleetRegionOptions([{ label: "Unknown", value: "Unknown" }, ...regionOptions])
        })
    }, [])

    const onSubmitAutoSearch = () => {
        const { updateSince, portRegion, vesselType, gear } = autoFilters || {}
        const modifiedFilters = {
            emailDateLower: moment().subtract(Number(updateSince.value), "d").startOf().format("yyyy-MM-DD HH:MM:SS"),
            emailDateUpper: moment().startOf().format("yyyy-MM-DD HH:MM:SS"),
            dwtLower: Number(vesselType.value.split("_")[0]),
            dwtUpper: Number(vesselType.value.split("_")[1]),
            portRegion: portRegion.map(item => item.value).join(","),
            orgId: getOrganizationId(),
            gear: gear.label
        }

        setLoader(true)

        actions.getDataByCumulativeSearch(modifiedFilters).then(res => {
            if (Array.isArray(res)) setEmailParserData(res)
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            toastError()
        })
    }

    const onSearchByOrgId = (email) => {
        const { updateSince } = autoFilters || {}
        const searchFilters = {
            emailDateLower: moment().subtract(Number(updateSince.value), "d").startOf().format("yyyy-MM-DD HH:MM:SS"),
            emailDateUpper: moment().startOf().format("yyyy-MM-DD HH:MM:SS"),
            vesselName: email.vesselName || '',
            orgId: getOrganizationId(),
        }
        setLoader(true)
        setSelectedEmail(email)
        actions.getEmailsByVessel(searchFilters).then(res => {
            setSelectedEmailData(Array.isArray(res) ? res : [])
            setLoader(false)
        }).catch(err => {
            setLoader(false)
            toastError()
        })
    }

    console.log(emailParserData, "emailParserData")

    const { sender = '', subject = '', deliveredTo = '', createdDate = '', emailContent = '' } = selectedSingleEmail

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>Position Reader</h3>
            </Grid>

            <Grid item xs={12}>
                <Paper style={{ marginTop: 10 }}>
                    <EmailParserUI
                        addFleetRegionOptions={addFleetRegionOptions}
                        setAutoFilters={setAutoFilters}
                        autoFilters={autoFilters}
                        onSubmitAutoSearch={onSubmitAutoSearch}
                    />
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <EmailTableContainer
                    emailParserData={emailParserData}
                    onSearchByOrgId={onSearchByOrgId}
                    selectedEmail={selectedEmail}
                />
            </Grid>

            <Grid item xs={12} md={8} style={{ paddingTop: 10 }}>
                <TableContainer component={Paper} className="vessel-selection-table selected-email-table mui-selected-grey">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Sender</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                selectedEmailData.length ?
                                    selectedEmailData.map(singleEmail => {
                                        const { id, sender = '', subject = '', createdDate = '' } = singleEmail
                                        return <TableRow key={id} hover style={{ cursor: "pointer" }} selected={selectedSingleEmail.id === id} onClick={() => setSelectedSingleEmail(singleEmail)}>
                                            <TableCell>{sender}</TableCell>
                                            <TableCell>{subject}</TableCell>
                                            <TableCell>{createdDate}</TableCell>
                                        </TableRow>
                                    })
                                    :
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">No data found.</TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={12} md={4} className="m-t-10">
                <Paper className="email-content-container">
                    <div>
                        <div><p>Subject: <span>{subject}</span></p></div>
                        <div><p>From: <span>{sender}</span></p></div>
                        <div><p>To: <span>{deliveredTo}</span></p></div>
                        <div><p>Time Received: <span>{createdDate ? moment(createdDate).format('hh:mm:A') : ''}</span></p></div>
                    </div>
                    <div className="email-content">
                        <p style={{ whiteSpace: 'pre-line' }}> {emailContent}</p>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default EmailParser
