import React, { useCallback, useState, useEffect } from 'react'
import { CircularProgress, Grid, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from "lodash"
import * as actions from '../../containers/User/Calculator/actions';

function AddPortQueryModal(props) {
    const {
        selectedPort = {},
        setSelectedPort = () => { },
        onSavePortOperationsOfQuery = () => { },
        // onClosePortModal = () => { }
    } = props

    const [portLoading, setPortLoading] = useState(false)
    const [portOptions, setPortOptions] = useState([])

    const delayPortsSearch = useCallback(_.debounce((search) => onSearchPortList(search), 500), []);

    const onSearchPortList = (value) => {
        setSelectedPort({ ...selectedPort, port: value })
        if (value) {
            setPortLoading(true)
            actions.portsByChar(value).then(res => {
                setPortLoading(false)
                setPortOptions((Array.isArray(res) && res.map(item => ({ label: `${item.name} ${item.country ? "(" + item.country + ")" : ""}`, value: item.name }))) || [])
            }).catch(err => {
                setPortLoading(false)
            })
        }
    }

    const onSelectPortsOfOperation = (value) => {
        setSelectedPort({ ...selectedPort, port: value.value })
        onSavePortOperationsOfQuery(value.value)
    }

    let newPortOptions = [...portOptions]
    let selectedPortName = { label: '', value: '' }
    if (selectedPort.port) {
        // newPortOptions = [...portOptions, { label: selectedPort.port, value: selectedPort.port }]
        selectedPortName = { label: selectedPort.port, value: selectedPort.port }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Autocomplete
                    options={(portLoading || portOptions.length === 0) ? [] : newPortOptions}
                    value={selectedPortName}
                    disableClearable

                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => onSelectPortsOfOperation(value)}
                    getOptionSelected={(option, value) => option.value === value.value}
                    // inputValue={selectedPortName.port}
                    // onInputChange={}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoFocus
                            variant="outlined"
                            placeholder="Search Ports"
                            value={selectedPortName.port}
                            onChange={e => delayPortsSearch(e.target.value)}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {portLoading ? <CircularProgress color="inherit" size={14} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>

            {/* <Grid item xs={12}>
                <button className="calculate-port-distance-btn" onClick={() => onSavePortOperationsOfQuery(selectedPort)}>Save</button>
            </Grid> */}
        </Grid>
    )
}

export default AddPortQueryModal
