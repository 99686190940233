import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Grid, Box, Select, MenuItem, TableContainer, Table, Paper, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core"
import CustomTextField from '../../../components/Common/CustomTextField'
import { MuiPickersUtilsProvider, KeyboardDatePicker, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment"
import { addQueryInitialData, queryPortDisabledFields, termsCoEfficientTable } from '../../../constants';
import DeleteIcon from '@material-ui/icons/Delete';
import * as actions from "./actions"
import { toastError, toastSuccess } from '../../../utils';
import moment from "moment"
import { useDispatch } from "react-redux"
import CustomShowModal from '../../../components/Common/CustomShowModal';
import AddPortQueryModal from '../../../components/Orders/AddPortQueryModal';
import * as commonActions from "../../Common/actions"
import { validateNewQuery } from './addQueryValidation';
import { setCopyPortOperation, setCopyQuery } from './dataMapping';

const AddNewOrder = forwardRef((props, ref) => {

    const {
        setLoader = () => { },
        onCloseModal = () => { },
        selectedQuery = {},
        selectedPorts = [],
        isEdit = false,
        // isDetailView = false,
        onChangePinQuery = () => { },
    } = props

    const addPortInitialData = [
        {
            cargoId: "",
            operation: "Loading",
            port: "",
            cargoQty: 0,
            tolerance: 0,
            rate: 0,
            terms: "SHINC",
            turnTime: 0,
            pdaRemarks: "",
            grade: "",
            freight: 0,
            adcom: 0,
            other: 0,
            brokerage: 0,
            ttlComm: 0,
            netFrt: 0,
            min: 0,
            max: 0,
            account: "",
            cargo: "",
            factor: 1,
            stowFactor: 0,
            queryId: "",
            loadline: "S-Summer",
            sulfur: "Normal"
        },
        {
            cargoId: "",
            operation: "Discharging",
            port: "",
            cargoQty: 0,
            tolerance: 0,
            rate: 0,
            terms: "SHINC",
            turnTime: 0,
            pdaRemarks: "",
            grade: "",
            freight: 0,
            adcom: 0,
            other: 0,
            brokerage: 0,
            ttlComm: 0,
            netFrt: 0,
            min: 0,
            max: 0,
            account: "",
            cargo: "",
            factor: 1,
            stowFactor: 0,
            queryId: "",
            loadline: "S-Summer",
            sulfur: "Normal"
        }
    ]


    const dispatch = useDispatch()

    const [portOperations, setPortOperations] = useState([...addPortInitialData])
    const [query, setQuery] = useState({ ...addQueryInitialData })
    const [portModal, setPortModal] = useState(false)
    const [selectedPort, setSelectedPort] = useState({})
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        if (Object.keys(selectedQuery).length) {
            setQuery(selectedQuery)
        }
        if (Array.isArray(selectedPorts) && selectedPorts.length) {
            setPortOperations(selectedPorts)
        }
        return () => {
            resetStateVariables()
        }
    }, [selectedQuery, selectedPorts])

    useEffect(() => {
        const { errors } = validateNewQuery(query, portOperations)
        dispatch(commonActions.addOnlyErrors({ errorList: errors }))
    }, [query, portOperations])


    const resetStateVariables = () => {
        setPortOperations([...addPortInitialData])
        setQuery({ ...addQueryInitialData })
        // setStartDate(null)
        // setEndDate(null)
        setPortModal(false)
        setSelectedPort({})
        setSelectedIndex(0)

    }

    const onOpenErrorAlert = (errors = []) => {
        dispatch(commonActions.openErrorAlert({ errorList: errors }))
    }

    useImperativeHandle(ref, () => ({
        async onSubmitQuery(isCopy = false) {
            const { errors, isValid } = validateNewQuery(query, portOperations)
            if (!isValid) return onOpenErrorAlert(errors)
            setLoader(true)
            if (isEdit && !isCopy) {
                // const oldOperations = portOperations.filter(item => item.queryId)
                // const newlyAddedOperations = portOperations.filter(item => !item.queryId).map(item => ({ ...item, queryId: oldOperations[0].queryId }))
                try {
                    let newQuery = { ...query, laycanStartDate: moment(query.laycanStartDate).toISOString(), laycanEndDate: moment(query.laycanEndDate).toISOString() }
                    await actions.updateClientQuery(newQuery)
                    await actions.updateCargoDetails(portOperations)
                    // if(newlyAddedOperations.length) await actions.addCargoToQueryList(newlyAddedOperations, oldOperations[0].queryId)
                    await dispatch(actions.clientQueryUser())
                    toastSuccess('Query updated successfully')
                    setLoader(false)
                } catch (ex) {
                    setLoader(false)
                }
                onCloseModal()
                return
            }
            let newQuery = { ...query, laycanStartDate: moment(query.laycanStartDate).toISOString(), laycanEndDate: moment(query.laycanEndDate).toISOString() }
            let newPortOperation = []
            if (isCopy) {
                newQuery = setCopyQuery(newQuery)
                newPortOperation = setCopyPortOperation(portOperations)
            } else {
                newPortOperation = portOperations
            }

            actions.addClientQuery(newQuery).then(res => {
                toastSuccess('Query added successfully')
                onCloseModal()
                const updatedPorts = newPortOperation.map(item => {
                    return {
                        ...item,
                        queryId: res.id,
                    }
                })
                actions.addCargoToQueryList(updatedPorts, res.id).then(async () => {
                    await dispatch(actions.clientQueryUser())
                    onChangePinQuery(res.id)
                    setLoader(false)
                }).catch(() => {
                    setLoader(false)
                    toastError('Error while adding operations to query')
                })
            }).catch(() => {
                setLoader(false)
            })
        }
    }))

    const onChangeHandler = (e) => {
        // if (isDetailView) return
        const { value = '', name = '' } = e.target || {}
        if (name === "freightMode") {
            if (value === "Single Freight") {
                let firstLoadPort = {} // reset values to first loading port
                setPortOperations(portOperations.map((item, index) => {
                    if (!Object.keys(firstLoadPort).length && item.operation === "Loading") firstLoadPort = {
                        freight: item.freight,
                        adcom: item.adcom,
                        other: item.other,
                        brokerage: item.brokerage
                    }
                    if (index !== 0 && item.operation === "Loading") {
                        return {
                            ...item,
                            ...firstLoadPort
                        }
                    }
                    return item
                }))
            }
        }
        setQuery({ ...query, [name]: value })
    }

    const onChangePortOperation = (index, e) => {
        // if (isDetailView) return
        const { name = "", value = "", type = '' } = e.target
        let updatedPortOperation = [...portOperations]
        updatedPortOperation[index][name] = type === 'number' ? Number(value) : value
        if (name === 'terms') {
            const findFactor = termsCoEfficientTable.find(item => item.label === value)
            if (findFactor) {
                updatedPortOperation[index]['factor'] = findFactor.value
            }
        }
        setPortOperations(updatedPortOperation)
    }

    const onAddPortOperations = () => {
        // if (isDetailView) return
        setPortOperations([...portOperations, { ...addPortInitialData[1] }])
    }

    const onDeletePortOperations = (index) => {
        // if (isDetailView) return
        let updatedOperation = [...portOperations]
        updatedOperation.splice(index, 1)
        setPortOperations(updatedOperation)
    }

    const onSelectPortsOfOperations = (port, index) => {
        setSelectedPort(port)
        setSelectedIndex(index)
        setPortModal(true)
    }

    const onSavePortOperationsOfQuery = (port) => {
        let updatedPortOperation = [...portOperations]
        updatedPortOperation[selectedIndex]["port"] = port
        setPortOperations(updatedPortOperation)
        onClosePortModal()
    }

    const onClosePortModal = () => {
        setPortModal(false)
        setSelectedPort({})
        setSelectedIndex(0)
    }

    const onChangeDate = (type, date) => {
        // if (isDetailView) return
        setQuery({ ...query, [type]: date })
    }

    const { access = "", freightMode = "", message = "", name = "", qtyMode = "", laycanStartDate = null, laycanEndDate = null } = query || {}

    let showMinMax = false
    if (qtyMode === 'MINMAX') showMinMax = true

    return (
        <Grid container className="add-order-container">
            <Grid item xs={12}>
                <Box className="item-box-contianer">
                    <TableContainer component={Paper} className="add-query-operations-table">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Operation</TableCell>
                                    <TableCell>Port</TableCell>
                                    {
                                        showMinMax ? <>
                                            <TableCell>Min</TableCell>
                                            <TableCell>Max</TableCell>
                                        </> :
                                            <>
                                                <TableCell>Cargo Qty</TableCell>
                                                <TableCell>Tolerance</TableCell>
                                            </>
                                    }
                                    <TableCell>Rate MT/Day</TableCell>
                                    <TableCell>Terms</TableCell>
                                    <TableCell>Turn Time(d)</TableCell>
                                    <TableCell>Freight</TableCell>
                                    <TableCell>Adcom(%)</TableCell>
                                    <TableCell>Other(%)</TableCell>
                                    <TableCell>Brokerage(%)</TableCell>
                                    <TableCell>A/C</TableCell>
                                    <TableCell>Cargo</TableCell>
                                    <TableCell>Stow Factor</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    portOperations.map((item, index) => {
                                        const { operation = '', port = '', cargoQty = '', tolerance = '', rate = '', terms = '', turnTime = '', freight = '', min = 0, max = 0,
                                            adcom = '', other = '', brokerage = '', account = '', cargo = '', stowFactor = '', factor = 0,
                                        } = item || {}

                                        const disableForSingleFreight = (freightMode === "Single Freight" && index !== 0) || false

                                        return <TableRow key={index}>
                                            <TableCell>
                                                <select
                                                    disabled={index === 0}
                                                    value={operation}
                                                    name="operation"
                                                    style={{ marginLeft: 5 }}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                >
                                                    <option value="Loading" >Loading</option>
                                                    <option value="Discharging" >Discharging</option>
                                                </select>
                                            </TableCell>
                                            <TableCell style={{ width: 100 }}>
                                                <div className="port-change-div">
                                                    <p onClick={() => onSelectPortsOfOperations(item, index)}>{port}</p>
                                                </div>
                                            </TableCell>
                                            {showMinMax ? <> <TableCell>
                                                <input
                                                    type="number"
                                                    value={min}
                                                    name="min"
                                                    disabled={queryPortDisabledFields[operation]["min"]}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="number"
                                                        value={max}
                                                        name="max"
                                                        disabled={queryPortDisabledFields[operation]["max"]}
                                                        onChange={e => onChangePortOperation(index, e)}
                                                    />
                                                </TableCell>
                                            </>
                                                :
                                                <> <TableCell >
                                                    <input
                                                        type="number"
                                                        value={cargoQty}
                                                        name="cargoQty"
                                                        disabled={queryPortDisabledFields[operation]["cargoQty"]}
                                                        onChange={e => onChangePortOperation(index, e)}
                                                    />
                                                </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="number"
                                                            value={tolerance}
                                                            name="tolerance"
                                                            disabled={queryPortDisabledFields[operation]["tolerance"]}
                                                            onChange={e => onChangePortOperation(index, e)}
                                                        />
                                                    </TableCell>
                                                </>

                                            }
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={rate}
                                                    name="rate"
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell style={{ width: 60 }}>
                                                <select
                                                    name="terms"
                                                    value={terms}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                >
                                                    {termsCoEfficientTable.map((item, index) => {
                                                        return <option key={index} value={item.label}>{item.label}</option>
                                                    })}
                                                </select>
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={turnTime}
                                                    name="turnTime"
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={freight}
                                                    name="freight"
                                                    disabled={queryPortDisabledFields[operation]["freight"] || disableForSingleFreight}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={adcom}
                                                    name="adcom"
                                                    disabled={queryPortDisabledFields[operation]["adcom"] || disableForSingleFreight}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={other}
                                                    name="other"
                                                    disabled={queryPortDisabledFields[operation]["other"] || disableForSingleFreight}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />

                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={brokerage}
                                                    name="brokerage"
                                                    disabled={queryPortDisabledFields[operation]["brokerage"] || disableForSingleFreight}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="text"
                                                    value={account}
                                                    name="account"
                                                    disabled={queryPortDisabledFields[operation]["account"]}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="text"
                                                    value={cargo}
                                                    name="cargo"
                                                    disabled={queryPortDisabledFields[operation]["cargo"]}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="number"
                                                    value={stowFactor}
                                                    name="stowFactor"
                                                    disabled={queryPortDisabledFields[operation]["stowFactor"]}
                                                    onChange={e => onChangePortOperation(index, e)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {index > 1 ? <DeleteIcon onClick={() => onDeletePortOperations(index)} /> : null}
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <button className="add-order-port-operation" onClick={onAddPortOperations}>+ Add Operation</button>
            </Grid>
            <Grid item xs={12} className="lower-input-grid">
                <div className="input-div">
                    <div className="upper-item-div">
                        <p>Name</p>
                        <div className="inputs" style={{ width: "100%" }}>
                            <CustomTextField
                                size="small"
                                placeholder="Enter Name"
                                value={name}
                                name="name"
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                    <div className="upper-item-div">
                        <p>Freight Mode</p>
                        <div className="inputs">
                            <Select
                                value={freightMode || ''}
                                onChange={onChangeHandler}
                                name="freightMode"
                                size="small"
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem dense value="Single Freight">Single Freight</MenuItem>
                                <MenuItem dense value="Multiple Freight">Multiple Freight</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="upper-item-div">
                        <p>Qty option</p>
                        <div className="inputs">
                            <Select
                                value={qtyMode || ''}
                                onChange={onChangeHandler}
                                name="qtyMode"
                                size="small"
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem dense value="MOLOO">MOLOO</MenuItem>
                                <MenuItem dense value="MINMAX">MINMAX</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="upper-item-div">
                        <p>Access Mode</p>
                        <div className="inputs">
                            <Select
                                value={access || ''}
                                onChange={onChangeHandler}
                                name="access"
                                size="small"
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem dense value="private">Private</MenuItem>
                                <MenuItem dense value="public">Public</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="upper-item-div">
                        <p>laycan start date</p>
                        <div className="inputs">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    autoOk
                                    fullWidth
                                    // variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MMM/yyyy HH:mm"
                                    placeholder="laycan start date"
                                    value={laycanStartDate || null}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={(date) => onChangeDate('laycanStartDate', date)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="upper-item-div">
                        <p>laycan end date</p>
                        <div className="inputs">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    autoOk
                                    fullWidth
                                    // variant="inline"
                                    inputVariant="outlined"
                                    format="DD/MMM/yyyy HH:mm"
                                    placeholder="laycan end date"
                                    value={laycanEndDate || null}
                                    InputAdornmentProps={{ position: "end" }}
                                    onChange={(date) => onChangeDate('laycanEndDate', date)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>
                <div className="textarea-div">
                    <textarea
                        className="add-order-textarea"
                        placeholder="Please enter text"
                        value={message}
                        name="message"
                        onChange={onChangeHandler}
                    />
                </div>
            </Grid>

            <CustomShowModal
                open={portModal}
                handleClose={onClosePortModal}
                maxWidth="xs"
                disableClose
                title={`Change ${selectedPort.operation} Port`}
            >
                <AddPortQueryModal
                    // onClosePortModal={onClosePortModal}
                    selectedPort={selectedPort}
                    setSelectedPort={setSelectedPort}
                    onSavePortOperationsOfQuery={onSavePortOperationsOfQuery}
                />
            </CustomShowModal>

        </Grid >
    )
})

export default AddNewOrder
