import axios from "axios"
import {ACCESS_TOKEN, API_URL} from "./constants"
import { toast, Slide } from 'react-toastify';
import store from "./store"
import { clearDashbaordStore } from "./containers/User/Dashboard/actions";
import { clearCalculatorReducer } from "./containers/User/Calculator/actions";
import { clearOrderReducer } from "./containers/User/Orders/actions";

export function apiGet(endpoint, data) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "get", {});
}

export function apiPost(endpoint, data, headers) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "post", headers);
}

export function apiPut(endpoint, data) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "put", {});
}

export function apiDelete(endpoint, data = {}) {
    /*   const user = getObject("user");
      const headers = { ...header, Authorization: user.token }; */
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "delete", {});
}

export function apiPatch(endpoint, data) {
    const finalEndpoint = finalUrl(endpoint);
    return apiRequest(finalEndpoint, data, "patch", {});
}

export function getHeaders() {
    const auth = getObject("auth") || {};
    if (auth.token) {
        return {
            Authorization: `Bearer ${auth.token}`
        };
    }
    return {};
}

export function apiRequest(endPoint, data, method, headers) {
    return new Promise((resolve, reject) => {
        headers = {
            ...getHeaders(),
            ...headers
        };

        if (method === "get" || method === "delete") {
            data = {
                params: data,
                headers
            };
        }
        axios[method](endPoint, data, { headers })
            .then(response => {
                const { data } = response;
                if (!data.hasOwnProperty('error')) {
                    return resolve(data);
                }
                return reject(data);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    removeAuthObjects()
                    toastError('Authorization expired, please login again')
                    window.open("/login", "_self")
                    return
                }
                if (error.response && error.response, "error.response") {
                    return reject(error.response ? error.response : error);
                }
            });
    });
}

const finalUrl = slug => {
    return API_URL + slug;
};

export const saveObject = (key, data) => {
    let obj = JSON.stringify(data);
    localStorage.setItem(key, obj);
};

export const removeObject = key => {
    localStorage.removeItem(key);
};

export const getObject = key => {
    return JSON.parse(localStorage.getItem(key));
};

export const isLoggedIn = () => {
    let auth = getObject("auth");
    if (auth && Object.keys(auth).length) {
        return true;
    }
    return false;
};

export const isAdminLogin = () => {
    let auth = getObject("auth")
    if (auth && !auth.organizationId) {
        return true
    }
    return false
}

export const getOrganizationId = () => {
    let auth = getObject("auth")
    if (auth && auth.organizationId) {
        return auth.organizationId
    }
    return ''
}

export const getCurrentUserEmail = () => {
    let auth = getObject("auth")
    if (auth && auth.username) {
        return auth.username
    }
    return ''
}

export const getCurrentUserId = () => {
    let auth = getObject("auth")
    if (auth && auth.realMe) {
        return auth.realMe
    }
    return ''
}

export const getCurrentUserFirstName = () => {
    let auth = getObject("auth")
    if (auth && auth.FirstName) {
        return auth.FirstName
    }
    return ''
}

export const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function toastSuccess(message, time = 3000) {
    toast.success(message, {
        autoClose: time,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_RIGHT,
        transition: Slide
    })
    clearWaitingQueue()
}

export function toastError(message, time = 3000) {
    toast.error(message || 'Something went wrong.', {
        autoClose: time,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_RIGHT,
        transition: Slide
    })
    clearWaitingQueue()
}

export function clearWaitingQueue() {
    toast.clearWaitingQueue();
}

export function removeAuthObjects() {
    removeObject('auth')
    // store.dispatch({
    //     type: 'CLEAR_ALL_STORE'
    // })
    store.dispatch(clearDashbaordStore())
    store.dispatch(clearCalculatorReducer())
    store.dispatch(clearOrderReducer())
    removeObject('auth');
    removeObject(ACCESS_TOKEN);
}

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function capitalizeFirstLetter(s) {
    return s[0].toUpperCase() + s.slice(1);
}

export function getRolePermisionRoutes() {
    let auth = getObject("auth")
    const roles = (auth && auth.roles) || []
    let isAdmin = false
    let isPricing = false
    let isSales = false
    let isSuperAdmin = false
    for (let i = 0; i < roles.length; i++) {
        const role = roles[i]
        if (role.name === 'ADMIN') isAdmin = true
        if (role.name === 'Pricing') isPricing = true
        if (role.name === 'Sales') isSales = true
        if (role.name === 'Super Admin') isSuperAdmin = true
    }

    let routeList = [
        "/", "/orders", "/user_organization", "/vessel_information", "/vessel/:id",
        "/voyages", "/calculator", "/email_parser", "/map/:id", "/saved/:id", "/saved"
    ]

    if (isAdmin) {
    } else if (isPricing) {
        routeList = routeList.filter(item => item !== '/')
    } else if (isSuperAdmin) {
        routeList = ['/', "/user_organization", "/vessel_information", "/setup_organization", "/map/:id", "/saved/:id", "/saved"]
    } else {
        routeList = ['/orders', "/map/:id", "/saved/:id", "/saved"]
    }

    return routeList
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}