import * as actionTypes from "./actionTypes"

const initialState = {
    allQueryFormat: [],
    queryDetailsById: [],
    cargoByQueryId: [],
    extraCargoDetailsById: []
}

export default function calculator(state = { ...initialState }, actions) {
    switch (actions.type) {
        case actionTypes.GET_ALL_QUERY_FORMAT_SUCCESS: return {
            ...state,
            allQueryFormat: actions.payload,
        }
        case actionTypes.GET_QUERY_DETAILS_BY_ID: return {
            ...state,
            queryDetailsById: actions.payload,
        }
        case actionTypes.GET_QUERY_DETAILS_BY_ID_FAILED: return {
            ...state,
            queryDetailsById: [],
        }
        case actionTypes.GET_CARGO_BY_QUERY_ID: return {
            ...state,
            cargoByQueryId: actions.payload,
        }
        case actionTypes.GET_CARGO_BY_QUERY_ID_FAILED: return {
            ...state,
            cargoByQueryId: [],
        }
        case actionTypes.GET_EXTRA_QUERY_BY_ID: return {
            ...state,
            extraCargoDetailsById: actions.payload,
        }
        case actionTypes.GET_EXTRA_QUERY_BY_ID_FAILED: return {
            ...state,
            extraCargoDetailsById: [],
        }
        case actionTypes.CLEAR_CALCULATOR_REDUCER: return { ...initialState }
        default: return state
    }
}