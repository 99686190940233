import React from 'react'
import { Grid, Paper } from "@material-ui/core"
import { resultsFields } from "../../constants"

function Result(props) {
    const { selectedVessels = {}, calculationErrors = [] } = props;

    return (
        <Grid container>
            <Grid item xs={12} className='calculator-section-header' style={{ paddingRight: 10 }}>
                <h4 className="result-heading">Result Details</h4>
            </Grid>
            <Grid item xs={12}>

                <div>
                    <table className="fleet-result-table">
                        {resultsFields.map((item, key) => {
                            return <tr key={key}>
                                <th>{item.label}</th>
                                <td>{selectedVessels[item.value] && Number(selectedVessels[item.value]).toFixed(2)}</td>
                            </tr>
                        })}
                    </table>
                </div>
            </Grid>
            <Grid item xs={12} style={{ paddingRight: 10 }}>
                <Paper className="result-details-errors">
                    {
                        calculationErrors.length ?
                            calculationErrors.map((item, index) => {
                                // if return 
                                return <React.Fragment key={index}>{(index === 0) ? <p className="errors-found-head">List of Errors</p> : null} <p className="errors-found">{index + 1}. {item.message}</p></React.Fragment>
                            })
                            : <p className="no-errors-found">No Error Found.</p>
                    }

                </Paper>
            </Grid>
        </Grid>
    )
}

export default Result
