import React, { useState, useRef, useMemo, useEffect } from 'react'
import { Grid, Paper } from "@material-ui/core"
import { accountCargoFields } from '../../constants';
import moment from "moment"
import CustomAddModal from '../Common/CustomAddModal';
import AddNewOrder from '../../containers/User/Orders/AddNewOrder';
import * as calculatorActions from "../../containers/User/Calculator/actions"
import { capitalizeFirstLetter } from '../../utils';

function AccountCargoDetails(props) {

    const {
        calculatorData = {},
        setLoader = () => { },
        getInitalQueryAndFleetData = () => { }
    } = props;

    const { portOperation = [], clientQuery = {} } = calculatorData || {}
    const { freightMode } = clientQuery

    const [open, setOpen] = useState(false)
    const [selectedQuery, setSelectedQuery] = useState({})
    const [selectedPorts, setSelectedPorts] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const addQueryModalRef = useRef();

    useEffect(() => {
        setSelectedQuery(clientQuery)
        setSelectedPorts(portOperation.filter(item => (item.operation === "Loading" || item.operation === "Discharging")))
    }, [calculatorData])

    const onCloseModal = () => {
        setOpen(false)
        setIsEdit(false)
        setSelectedQuery({})
        setSelectedPorts([])
    }

    const singleLoadingCargo = useMemo(() => {
        let singleLoadingCargo = {}
        let mixAdcom = ''
        let mixBrokerage = ''
        let mixOther = ''
        let mixTtlComm = ''
        let mixNetFrt = ''
        let mixStowFactor = ''
        if (Array.isArray(portOperation) && portOperation.length) {
            for (let i = 0; i < portOperation.length; i++) {
                if (portOperation[i].operation === 'Loading') {
                    // console.log(portOperation[i].others, "portOperation[i].other")
                    if (!Object.keys(singleLoadingCargo).length) singleLoadingCargo = portOperation[i]
                    mixAdcom = mixAdcom + (mixAdcom ? '/' : '') + portOperation[i].adcom || '0'
                    mixBrokerage = mixBrokerage + (mixBrokerage ? '/' : '') + portOperation[i].brokerage || '0'
                    mixOther = mixOther + (mixOther ? '/' : '') + portOperation[i].other || '0'
                    mixTtlComm = mixTtlComm + (mixTtlComm ? '/' : '') + portOperation[i].ttlComm || '0'
                    mixNetFrt = mixNetFrt + (mixNetFrt ? '/' : '') + portOperation[i].netFrt || '0'
                    mixStowFactor = mixStowFactor + (mixStowFactor ? '/' : '') + portOperation[i].stowFactor || '0'
                }
            }
        }
        return {
            ...singleLoadingCargo,
            mixAdcom,
            mixBrokerage,
            mixOther,
            mixTtlComm,
            mixNetFrt,
            mixStowFactor,
        }
    }, [portOperation])

    const onClickEditQuery = (query) => {
        setLoader(true)
        setSelectedQuery(query)
        calculatorActions.cargoByQuery(query.id).then(res => {
            setLoader(false)
            setSelectedPorts(res.filter(item => item.operation !== 'Commence'))
            setOpen(true)
            setIsEdit(true)
        }).catch(() => {
            setLoader(false)
        })

    }

    const onSubmitConfirm = () => {
        if (addQueryModalRef.current.onSubmitQuery) {
            addQueryModalRef.current.onSubmitQuery()
            getInitalQueryAndFleetData()
        }
    }

    const onClickCreateCopy = () => {
        if (addQueryModalRef.current.onSubmitQuery) {
            addQueryModalRef.current.onSubmitQuery(true)
            getInitalQueryAndFleetData()
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} className='calculator-section-header'>
                <h4 className="result-heading" style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => onClickEditQuery(clientQuery)}>Cargo Details</h4>
            </Grid>
            <Grid item xs={12}>
                <div>
                    <table className="fleet-result-table">
                        {accountCargoFields.map((item, key) => {
                            return <tr key={key}>
                                <th>{item.label}</th>
                                {
                                    (item.value === 'adcom' || item.value === 'brokerage' || item.value === 'other' || item.value === 'ttlComm' || item.value === 'netFrt' || item.value === 'stowFactor') ?
                                        <td>{singleLoadingCargo[`mix${capitalizeFirstLetter(item.value)}`]}</td> :
                                        (item.value === 'laycanStartDate' || item.value === 'laycanEndDate') ?
                                            // <td>{singleLoadingCargo[item.value] ? moment(singleLoadingCargo[item.value]).format('DD-MM-yyyy HH:MM') : ''}</td>
                                            <td>{clientQuery[item.value] ? moment(clientQuery[item.value]).format('DD/MMM/yyyy HH:MM') : ''}</td>
                                            : (item.value === "freightMode") ?
                                                <td>{freightMode}</td>
                                                : <td>{singleLoadingCargo[item.value] || 0}</td>
                                }
                            </tr>
                        })}
                    </table>
                </div>
            </Grid>
            <CustomAddModal
                open={open}
                title={isEdit ? "Update Order" : "New Order"}
                maxWidth="xl"
                saveLabel={isEdit ? "Update" : "Add"}
                addThirdBtn={isEdit}
                thirdBtnAction={onClickCreateCopy}
                thirdBtnLabel="Create New Order"
                handleClose={onCloseModal}
                onSubmitConfirm={onSubmitConfirm}
            >
                <AddNewOrder
                    ref={addQueryModalRef}
                    setLoader={setLoader}
                    onCloseModal={onCloseModal}
                    selectedQuery={selectedQuery}
                    selectedPorts={selectedPorts}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                />
            </CustomAddModal>
        </Grid>
    )
}

export default AccountCargoDetails
