import React from 'react'
import { Grid, Box, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core"
import CustomTextField from "../Common/CustomTextField"

const organizationTypes = [
    {
        label: 'FSC'
    },
    {
        label: 'FOW'
    },
    {
        label: 'FMM'
    }
]

function AddNewOrg(props) {

    const { organization = [], onChange = () => { } } = props

    const {
        contactEmail = "",
        contactNumber = "",
        contactPerson = "",
        domain = "",
        emailReceiver = "",
        orgType = "",
        organizationName = "",
    } = organization

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mb={2}>
                    <CustomTextField
                        placeholder="Organization Name"
                        size="small"
                        name="organizationName"
                        value={organizationName}
                        onChange={onChange}
                    />
                </Box>
                <Box mb={2}>
                    <CustomTextField
                        placeholder="Domain Name"
                        size="small"
                        name="domain"
                        value={domain}
                        onChange={onChange}
                    />
                </Box>
                <Box mb={2}>
                    <CustomTextField
                        placeholder="Contact Person"
                        size="small"
                        name="contactPerson"
                        value={contactPerson}
                        onChange={onChange}
                    />
                </Box>
                <Box mb={2}>
                    <CustomTextField
                        type="number"
                        placeholder="Contact Number "
                        size="small"
                        name="contactNumber"
                        value={contactNumber}
                        onChange={onChange}
                    />
                </Box>
                <Box mb={2}>
                    <CustomTextField
                        placeholder="Contact Email"
                        size="small"
                        name="contactEmail"
                        value={contactEmail}
                        onChange={onChange}
                    />
                </Box>
                <Box mb={2}>
                    <CustomTextField
                        placeholder="Email Receiver"
                        size="small"
                        name="emailReceiver"
                        value={emailReceiver}
                        onChange={onChange}
                    />
                </Box>
                <Box mb={2}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-label">Select Organization Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orgType}
                            label="Select Organization Type"
                            name="orgType"
                            onChange={onChange}
                        >
                            {
                                organizationTypes.map((item) => {
                                    return <MenuItem key={item.label} value={item.label}>{item.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AddNewOrg
