import { Button } from '@material-ui/core'
import React from 'react'

function SaveVesselLabel(props) {
    const {
        value = "",
        onChange = () => { },
        onClickSaveLabel = () => { },
    } = props
    return (
        <div className="saved-fleet-wrapper">
            <input type="text" placeholder="Enter Voyage label" value={value} onChange={onChange} />
            {/* <p>Note: Click on "Calculate and Save" to save this result</p> */}
        </div>
    )
}

export default SaveVesselLabel
