export const validateNewQuery = (query = {}, portOperations = []) => {
    let errors = []
    const { name = '', access = '', laycanStartDate = null, laycanEndDate = null, freightMode = '', qtyMode = '', message = '' } = query || {}
    if (!name) errors.push('Query Name is Required')
    if (!access) errors.push('Access Mode is Required')
    if (!freightMode) errors.push('Freight mode is Required')
    if (!qtyMode) errors.push('Qty option is Required')
    if (!laycanStartDate) errors.push('Laycan start date is Required')
    if (!laycanEndDate) errors.push('Laycan end date is Required')
    // if (message && String(message).length) errors.push('Message max character length of 4000 exceded')

    if (Array.isArray(portOperations)) {
        for (let i = 0; i < portOperations.length; i++) {
            const item = portOperations[i]
            if (!item.port) errors.push(`${item.operation} PORT is required`)
            if (item.rate <= 0) errors.push(`${item.operation} RATE must be greater than 0`)
        }
    }

    return {
        isValid: errors.length === 0,
        errors
    }
}