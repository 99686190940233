import React from 'react';
import ReactDOM from 'react-dom';
import "./styles/index.scss";
import Routes from './routes';
import registerServiceWorker  from "./registerServiceWorker";
import { Provider } from "react-redux"
import store from "./store"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
    limit: 1,
    newestOnTop: true
});

ReactDOM.render(<Provider store={store}><Routes /></Provider>, document.getElementById('root'));
//
// ReactDOM.render(
//     <Router>
//         <App />
//     </Router>,
//     document.getElementById("root")
// );
registerServiceWorker();