export const setCopyQuery = (query) => {
    const { name, cve, insurance, hireSurvey, canalExpenses, additionalPremium, ilohc, manoevringExp, miscExp, miscRevenue, message, qtyMode, freightMode,
        access, scrubberType, laycanStartDate, laycanEndDate, bnkDelhs = 0, bnkDells = 0, bnkDelmgo = 0,
    } = query
    return {
        name,
        cve,
        insurance,
        hireSurvey,
        canalExpenses,
        additionalPremium,
        ilohc,
        manoevringExp,
        miscExp,
        miscRevenue,
        message,
        qtyMode,
        freightMode,
        access,
        scrubberType,
        laycanStartDate,
        laycanEndDate,
        bnkDelhs,
        bnkDells,
        bnkDelmgo,
    }
}

export const setCopyPortOperation = (portOperations) => {
    return (Array.isArray(portOperations) && portOperations.map(item => {
        const { cargoId, operation, port, cargoQty, tolerance, rate, terms, turnTime, pdaRemarks, grade, freight, adcom, other,
            brokerage, ttlComm, netFrt, min, max, account, cargo, factor, stowFactor, queryId, loadline, sulfur,
        } = item
        return {
            cargoId: "",
            operation,
            port,
            cargoQty,
            tolerance,
            rate,
            terms,
            turnTime,
            pdaRemarks,
            grade,
            freight,
            adcom,
            other,
            brokerage,
            ttlComm,
            netFrt,
            min,
            max,
            account,
            cargo,
            factor,
            stowFactor,
            queryId: "",
            loadline,
            sulfur,
        }
    })) || []
}

export const sortedPortsByFormat = (query, res) => {
    let portOperations = []
    let calculationFormat = query.calculationFormat && String(query.calculationFormat).split(',') || []
    let ports = (Array.isArray(res) && res) || []
    // console.log(ports, "ports")
    for (let i = 0; i < calculationFormat.length; i++) {
        const selectedPort = ports.find(item => item.id === calculationFormat[i])
        if (selectedPort) portOperations.push(selectedPort)
    }
    return portOperations
}