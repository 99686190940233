import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { Button, Grid, Box, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core"
import * as dashboardActions from "../../../containers/User/Dashboard/actions"
import * as calculatorActions from "../../../containers/User/Calculator/actions"
import { getOrganizationId, toastSuccess, getCurrentUserId } from '../../../utils'
import { vesselTableHeadingViewOnly } from '../../../constants'
import moment from "moment"

function UserCopyFleet(props) {
    const {
        setLoader = () => { },
        getInitalQueryAndFleetData = () => { },
        onClickAddFleet = () => { }
    } = props
    const dispatch = useDispatch()
    const { userOrganizaton = [] } = useSelector(state => state.dashboard)
    const [selectedUser, setSelectedUser] = useState("")
    const [userFleets, setUserFleets] = useState([])
    const [selectedFleets, setSelectedFleets] = useState([])

    useEffect(() => {
        if (!userOrganizaton.length) {
            dispatch(dashboardActions.usersForOrg({ organizationId: getOrganizationId() }))
        }
    }, [])

    const onChangeUser = async (e) => {
        const { name = '', value = '' } = e.target
        setSelectedFleets([])
        setSelectedUser(value)
        setLoader(true)
        await calculatorActions.userFleets(value).then(res => {
            setUserFleets(Array.isArray(res) ? res : [])
        })
        setLoader(false)
    }

    const onChangeInputCheck = (fleetId) => {
        let updatedSelectedFleets = [...selectedFleets]
        const isFindIndex = updatedSelectedFleets.findIndex(item => item === fleetId)
        if (isFindIndex > -1) {
            updatedSelectedFleets.splice(isFindIndex, 1)
        } else {
            updatedSelectedFleets.push(fleetId)
        }
        updatedSelectedFleets = [...new Set(updatedSelectedFleets)]
        setSelectedFleets(updatedSelectedFleets)
    }

    const onSelectAllFleet = (isAllSelected) => {
        let ids = userFleets.map(item => item.id)
        if (isAllSelected) ids = []
        setSelectedFleets(ids)
    }

    const onUserFleetDataCopy = () => {
        if (selectedFleets.length === 0 || !selectedFleets) return
        const sendObj = {
            id: selectedUser,
            selectedFleet: selectedFleets.map(item => ({ id: item }))
        }
        setLoader(true)
        calculatorActions.userFleetDataCopy(sendObj).then(res => {
            setLoader(false)
            toastSuccess('Fleets copied successfully')
            onClickAddFleet()
            getInitalQueryAndFleetData()
        }).catch(err => {
            setLoader(false)
        })
    }

    let newUserOrganizaton = useMemo(() => {
        return Array.isArray(userOrganizaton) ? userOrganizaton.filter(item => item.id !== getCurrentUserId()) : []
    }, [userOrganizaton])

    const isAllSelected = selectedFleets.length === userFleets.length

    return (
        <Grid container className="user-copy-container">
            <Grid item xs={12}>
                <Box mt={2} width="100%" textAlign="center">
                    <select value={selectedUser} onChange={onChangeUser}>
                        <option value="" disabled>Select User</option>
                        {newUserOrganizaton.map((item, index) => {
                            return <option key={index} value={item.id}>{item.fullname}</option>
                        })}
                    </select>
                    <Button variant="contained" color="primary" onClick={onUserFleetDataCopy}>Copy</Button>
                </Box>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 30 }}>
                <TableContainer component={Paper}>
                    <Table className="user-fleet-table">
                        <TableHead>
                            <TableRow>
                                {
                                    vesselTableHeadingViewOnly.map(item => {
                                        if (item.id === "checkbox") {
                                            return <TableCell key={item.id}><input type="checkbox" checked={isAllSelected} onChange={() => onSelectAllFleet(isAllSelected)} /></TableCell>
                                        }
                                        return <TableCell key={item.id}>{item.title}</TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(userFleets) && userFleets.length ? userFleets.map((vessel) => {
                                    const { id, vesselName = '', dateOpen = '', port = '', gbb = 0, hireGbb = 0, hirePerDay = 0, remark = '', tcEq = 0,
                                        frtCost = 0, profitNet = 0, loadability = 0, vesselPremium = 0, positionPremium = 0, vessel: fleetVesselData, splitHire = '', isReferenceVessel = false } = vessel || {}
                                    const { vesselDwt = 0 } = JSON.parse(fleetVesselData) || ""
                                    const isChecked = selectedFleets.includes(id)
                                    // const isSelected = selectedVessels.id === id || false
                                    // const showSplitHire = (splitHire && JSON.parse(selectedSplitHire.splitHire) !== []) || false
                                    return <TableRow key={id}>
                                        <TableCell><input type="checkbox" checked={isChecked} onChange={() => onChangeInputCheck(id)} /></TableCell>
                                        <TableCell>{vesselName}</TableCell>
                                        <TableCell>{vesselDwt}</TableCell>
                                        <TableCell>{port}</TableCell>
                                        <TableCell onClick={e => e.stopPropagation()}>
                                            {/* <MuiPickersUtilsProvider utils={MomentUtils} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                                            <DateTimePicker
                                                inputVariant="outlined"
                                                size="small"
                                                // style={{ width: 150 }}
                                                value={dateOpen || null}
                                                format="DD/MMM/yyyy HH:MM"
                                                onClick={(e) => { e.stopPropagation(); e.preventDefault() }}
                                                onChange={date => onChangeHandler(vessel.id, "dateOpen", date)}
                                            />
                                        </MuiPickersUtilsProvider> */}
                                            {moment(dateOpen).format("DD/MMM/yyyy HH:MM")}
                                        </TableCell>
                                        <TableCell>
                                            {/* <input
                                            type="number"
                                            className="vessel-list-editable-input"
                                            value={gbb}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={e => onChangeHandler(vessel.id, "gbb", e.target.value)}
                                        /> */}
                                            {gbb}
                                        </TableCell>
                                        <TableCell style={{ minWidth: 90 }}>
                                            {/* <div style={{ position: 'relative' }}>
                                            <input
                                                type="number"
                                                className="vessel-list-editable-input"
                                                value={hirePerDay}
                                                // style={{ width: 90 }}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={e => onChangeHandler(vessel.id, "hirePerDay", e.target.value)}
                                            />
                                            <span className={`split-hire-dot ${splitHire ? "red" : "green"}`} onClick={(e) => { e.stopPropagation(); openSplitHireModal(vessel) }}>&bull;</span>
                                        </div> */}
                                            {hirePerDay}
                                        </TableCell>
                                        <TableCell>
                                            {/* <input
                                            type="number"
                                            className="vessel-list-editable-input"
                                            value={hireGbb}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={e => onChangeHandler(vessel.id, "hireGbb", e.target.value)}
                                        /> */}
                                            {hireGbb}
                                        </TableCell>
                                        {/* <TableCell>{moment(getArrivalTimeOfFirstLoading).format('DD/MMM/yyyy HH:MM')}</TableCell> */}
                                        {/* <TableCell>Rank</TableCell> */}
                                        <TableCell>
                                            {/* <textarea
                                            value={remark}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={e => onChangeHandler(vessel.id, "remark", e.target.value)}
                                        /> */}
                                            {remark}
                                        </TableCell>
                                        {/* <TableCell>
                                        <div className="d-flex">
                                            <DeleteIcon style={{ color: "blue", fontSize: "14px", cursor: "pointer" }} onClick={e => {
                                                e.stopPropagation()
                                                setDeleteId(id)
                                                setShowConfrim(true)
                                            }} />
                                            <div>
                                                <img src={isReferenceVessel ? pinIconRed : pinIconBlack} width="13px" onClick={(e) => {
                                                    e.stopPropagation()
                                                    onChangeIsReferenceVessel(vessel)
                                                }} alt="" />
                                            </div>
                                        </div>
                                    </TableCell> */}
                                    </TableRow>
                                }) : <TableRow>
                                    <TableCell colSpan="18" align="center">
                                        No Fleet Found.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default UserCopyFleet
