import React, { useEffect, useState } from 'react'
import * as actions from "../actions"
import { useParams } from "react-router-dom"
import L from "leaflet"
// import axios from "axios"
import { addPortDistanceToOperations, convertPortOperationToDistanceString, getGeoJsonLineAndCoordinates } from '../dataMapping'
import { IsJsonString, toastError } from '../../../../utils'
import fleetIcon from "../../../../assets/flaticons/cargo-ship.png"

// https://sigmafinder.fleetsigma.com/v3/route/72.866667,18.966667/35.533333,27.566667?none=false
// windy.com

// const getLatLongAPIresponse = (lat1, long1, lat2, long2) => {
//     return new Promise((resolve, reject) => {
//         axios.get(`https://sigmafinder.fleetsigma.com/v3/route/${lat1},${long1}/${lat2},${long2}?none=false`).then(res => {
//             console.log(res``)
//             resolve(res)
//         }).catch(err => {
//             reject({})
//         })
//     })
// }

export default function ShowMap(props) {

    const { setLoader = () => { } } = props
    const { id: selectedQueryId = "" } = useParams()
    const [portOperation, setPortOperation] = useState([])
    const [fleetList, setFleetList] = useState([])

    useEffect(() => {
        if (selectedQueryId) {
            getInitialData(selectedQueryId)
        }
    }, [])

    // useEffect(() => {
    //     if()
    // }, [])

    const getInitialData = async (selectedQueryId) => {
        try {
            setLoader(true)
            const [response0, response1, response2, fleets] = await Promise.all([
                actions.queryById(selectedQueryId),
                actions.cargoByQuery(selectedQueryId),
                actions.allCargoExtraDetailsByQuery(selectedQueryId),
                actions.allFleet()
            ])
            const portOperation = [...response1, ...response2]
            const { routeList } = convertPortOperationToDistanceString({ portOperation })
            const { eca = false, hra = false, jwc = false, suez = false, panama = false, singapore = false } = response0
            const portDataObj = {
                eca, hra, jwc, suez, panama, singapore,
                routeOverride: false,
                routeList: routeList.split(','),
                portList: routeList.split(','),
                clientQueryId: selectedQueryId,
                queryId: selectedQueryId,
                getGeometry: true,
            }
            const portDistanceRes = await actions.getPortDistanceByPortList(portDataObj)
            const newPortOperations = addPortDistanceToOperations(portDistanceRes, [...portOperation], true)
            // setPortOperation(newPortOperations)
            setFleetList(fleets)
            setMapMarkers(newPortOperations, fleets)
            setLoader(false)
        } catch (ex) {
            setLoader(false)
            toastError(ex.message)
        }
    }

    const setMapMarkers = async (newPortOperations = [], fleets = []) => {
        try {
            const centerIndex = Math.floor(newPortOperations.length / 2)
            const centerLat = (newPortOperations[centerIndex] && newPortOperations[centerIndex].lat) || 51.505
            const centerLong = (newPortOperations[centerIndex] && newPortOperations[centerIndex].lng) || -0.09
            const center = [centerLat, centerLong]
            var defaultZoom = 3;
            var defaultMapCenter = new L.LatLng(20, 0);
            var editableLayers = new L.FeatureGroup();

            // const map = L.map('map').setView(center, 2);

            // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // }).addTo(map);

            var seaDefault = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}');
            var seaMark = new L.TileLayer('http://t1.openseamap.org/seamark/{z}/{x}/{y}.png');
            var seaProfil = new L.TileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}');
            var osm = new L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png');
            var Esri_WorldImagery = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}');
            var Esri_WorldGrayCanvas = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}');
            var OpenStreetMap_BlackAndWhite = L.tileLayer('http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png');

            const map = new L.Map('map', {
                layers: [seaDefault, seaMark],
                center: defaultMapCenter,
                zoom: defaultZoom,
                editable: true,
                minZoom: 2,
                cursor: true
            });

            map.on("baselayerchange", function (event) {
                seaMark.bringToFront();
            });
            var baseMaps = {
                "Default": seaDefault,
                "Sea profile": seaProfil,
                "Imagery": Esri_WorldImagery,
                "Open Street Map": osm,
                "Gray": Esri_WorldGrayCanvas,
                "Black & white": OpenStreetMap_BlackAndWhite,
            };
            var overlayMaps = {
                // "Show ports": getPortsLayer(),
                // "Show ECA zones": displayZones(eca_areas, 'ECA', "green"),
                // "Show HRA zonse": displayZones(hra_areas, 'HRA', "red"),
                // "Show JWC zones": displayZones(jwc_areas, 'JWC', "blue"),
                // "Show Grid": displayGrid()
            };
            L.control.layers(baseMaps, overlayMaps).addTo(map);
            map.zoomControl.setPosition('topright');
            // map.addLayer(editableLayers);


            // L.control.mousePosition({ position: 'bottomright', numDigits: 6 }).addTo(map);

            let combinedGeoJson = []

            newPortOperations = newPortOperations.filter(operation => operation !== "Commence")

            for (let i = 0; i < newPortOperations.length; i++) {
                let lat1 = newPortOperations[i].lat || 0
                let long1 = newPortOperations[i].lng || 0
                L.marker([lat1, long1]).addTo(map)
                    .bindPopup(newPortOperations[i].port)
                // .openPopup();
                if (newPortOperations[i].geoJSON) {

                    const geoJSON = getGeoJsonLineAndCoordinates(newPortOperations[i].geoJSON)
                    console.log(geoJSON, "geoJSON")
                    combinedGeoJson.push(geoJSON)
                }

            }
            L.geoJSON(combinedGeoJson).addTo(map)
            var greenIcon = L.icon({
                iconUrl: fleetIcon,
                // shadowUrl: 'leaf-shadow.png',

                // iconSize: [38, 95], // size of the icon
                // shadowSize:   [50, 64], // size of the shadow
                // iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                // shadowAnchor: [4, 62],  // the same for the shadow
                // popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
            });

            for (let i = 0; i < fleets.length; i++) {
                const { lat = 0, lng = 0, vesselName = '' } = fleets[i]
                L.marker([lat, lng], { icon: greenIcon }).addTo(map).bindPopup(vesselName)
            }

        } catch (ex) {
            console.log(ex.message || 'Something went wrong')
        }

    }

    function displayGrid() {
        return L.latlngGraticule({
            showLabel: true,
            color: "black",
            weight: 0.3,
            zoomInterval: [
                { start: 2, end: 2, interval: 40 },
                { start: 3, end: 3, interval: 20 },
                { start: 4, end: 4, interval: 10 },
                { start: 5, end: 7, interval: 5 },
                { start: 8, end: 20, interval: 1 }
            ]
        })
    }

    function displayZones(list, name, color) {
        var newArea = [];
        var polygons = [];
        list.forEach(function (area) {
            var inversedPoly = [];
            area.polygon.forEach(function (p) {
                inversedPoly.push([p[1], p[0]]);
            });
            newArea.push({ name: area.name, polygon: inversedPoly });
            var poly = L.polygon(inversedPoly, {
                color: color,
                opacity: 0.1,
                fillColor: color,
                fillOpacity: 0.3
            }).bindPopup(name + " : " + area.name);
            polygons.push(poly);
        });
        return L.layerGroup(polygons);
    }

    // function getPortsLayer() {
    //     var ports = [];
    //     portsList.forEach(function (p) {
    //         var cityMarker = L.circleMarker(p.latlng, {
    //             radius: 2,
    //             color: '#666',
    //             fillOpacity: 1
    //         }).on('click', function () {
    //             cityMarker.bindPopup(makePopupText(null, p.id, p.title_short, p.country_name, p.country_code, false)).openPopup();
    //         });
    //         ports.push(cityMarker)
    //     });
    //     return L.layerGroup(ports);
    // }

    return (
        <div className="showmap-container" id="map" />
    )
}
