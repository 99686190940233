export const GET_ALL_QUERY_FORMAT = 'GET_ALL_QUERY_FORMAT'
export const GET_ALL_QUERY_FORMAT_SUCCESS = 'GET_ALL_QUERY_FORMAT_SUCCESS'
export const GET_ALL_QUERY_FORMAT_FAILED = 'GET_ALL_QUERY_FORMAT_FAILED'

export const GET_QUERY_DETAILS_BY_ID = 'GET_QUERY_DETAILS_BY_ID'
export const GET_QUERY_DETAILS_BY_ID_FAILED = 'GET_QUERY_DETAILS_BY_ID_FAILED'

export const GET_CARGO_BY_QUERY_ID = 'GET_CARGO_BY_QUERY_ID'
export const GET_CARGO_BY_QUERY_ID_FAILED = 'GET_CARGO_BY_QUERY_ID_FAILED'

export const GET_EXTRA_QUERY_BY_ID = 'GET_EXTRA_QUERY_BY_ID'
export const GET_EXTRA_QUERY_BY_ID_FAILED = 'GET_EXTRA_QUERY_BY_ID_FAILED'

export const CLEAR_CALCULATOR_REDUCER = 'CLEAR_CALCULATOR_REDUCER'