import React from 'react'
// import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';

function OrdersList(props) {
    const {
        allQueryFormat = [],
        selectedQuery = {},
        setSelectedQuery = () => { },
        onRemoveOrderQuery = () => { },
        onChangeSelectedQuery = () => { }
    } = props

    return (
        <ul className="orders-list-items">
            {
                Array.isArray(allQueryFormat) && allQueryFormat.length ? allQueryFormat.map((query, index) => {
                    const selected = selectedQuery.id === query.id
                    return <li key={index} className={selected ? `selected` : ''}>
                        <p>
                            <span onClick={() => onChangeSelectedQuery(query)}>{query.name}</span>
                            <CancelIcon onClick={() => onRemoveOrderQuery(query.id)} />
                        </p>
                    </li>
                }) : <li>
                    No Query Found.
                </li>
            }
        </ul>
    )
}

export default OrdersList
