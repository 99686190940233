import React, { useEffect, useState, memo, useCallback, useRef, useMemo } from 'react'
import { Box, Grid } from "@material-ui/core"
import * as actions from "./actions"
import * as dashboardActions from "../Dashboard/actions"
import OrdersList from '../../../components/Calculator/OrdersList';
import PortOperations from '../../../components/Calculator/PortOperations';
import VesselSelection from '../../../components/Calculator/VesselSelection';
import { initialAddFleet, initialCreatePortOperation, termsCoEfficientTable } from '../../../constants';
import { addPortDistanceToOperations, convertPortOperationToDistanceString, fleetWithCalculatorDataMapping, sendConsumptionsDataByCheckBox, calculationValidation, addPortDistanceToSelectedFleet } from './dataMapping';
import Result from '../../../components/Calculator/Result';
import Expenses from '../../../components/Calculator/Expenses';
import AccountCargoDetails from '../../../components/Calculator/AccountCargoDetails';
import CalculatorVesselDetails from '../../../components/Calculator/CalculatorVesselDetails';
import MarginAndAllowances from '../../../components/Calculator/MarginAndAllowances';
import ActionButtons from '../../../components/Calculator/ActionButtons';
import { getOrganizationId, toastError, toastSuccess } from '../../../utils';
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"

function Calculator(props) {
    const {
        setIsFullScreen = () => { },
        setLoader = () => { },
        loader = false
    } = props
    const dispatch = useDispatch()
    const [hideOrder, setHideOrder] = useState(false)
    const [selectedQuery, setSelectedQuery] = useState({})

    const [calculatorData, setCalculatorData] = useState({
        portOperation: [],
        clientQuery: {},
        fleetList: [],
        cargoList: [],
        cargoExtraDetails: [],
        autoSave: false,
    })

    // reducer data in state
    const [allQueryFormat, setAllQueryFormat] = useState([])

    // vessel
    const [selectedVessels, setSelectedVessels] = useState({})
    const [syncBunker, setSyncBunker] = useState(false)
    const [isOverride, setIsOverride] = useState(false)
    const [hideCalculator, setHideCalculator] = useState(false)
    const [fleetCheckbox, setFleetCheckbox] = useState({
        ballest: {
            1: true,
            2: true,
            3: true,
            4: true
        },
        laden: {
            1: true,
            2: true,
            3: true,
            4: true
        }
    })
    // const [startBunkerSync, setStartBunkerSync] = useState(false)
    const [addFleetsArray, setAddFleetsArray] = useState([{ ...initialAddFleet }])
    const [portDistanceResponse, setPortDistanceResponse] = useState({})
    const [selectedSplitHire, setSelectedSplitHire] = useState({})

    //modals
    const [marginModal, setMarginModal] = useState(false)
    const [supplyModal, setSupplyModal] = useState(false)
    const [addFleetModal, setAddFleetModal] = useState(false)
    const [splitHireModal, setSplitHireModal] = useState(false)
    const delayUpdateAndSaveOperation = useCallback(_.debounce((data) => onUpdateAndSavePortDistance(data), 500), []);
    const [openAddOperation, setOpenAddOperation] = useState(false)
    // const [openMap, setOpenMap] = useState(false)

    //refs
    const fetchPortDistance = useRef(true)

    useEffect(() => {
        setIsFullScreen(true)
        getInitalQueryAndFleetData()
        return () => {
            setIsFullScreen(false)
        }
    }, [])

    const getInitalQueryAndFleetData = () => {
        setLoader(true)
        Promise.all([
            actions.getQueryFormat(),
            actions.allFleet(),
            dispatch(dashboardActions.usersForOrg({ organizationId: getOrganizationId() }))
        ]).then((res) => {
            const response1 = res[0]
            const response2 = res[1]
            const newResponse1 = (Array.isArray(response1) && response1.filter(item => !!item)) || []
            setInitialFetchedData(newResponse1, response2, true)
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const calculationErrors = useMemo(() => {
        let errors = []
        if (selectedVessels && calculatorData.portOperation.length && calculatorData.fleetList.length) {
            errors = calculationValidation(calculatorData, selectedVessels)
        }
        return errors
    }, [calculatorData, selectedVessels])

    const setInitialFetchedData = (mainRes1, mainRes2, initial = false) => {
        fetchPortDistance.current = true
        let selectedQuery = {}
        if (initial && Array.isArray(mainRes1) && mainRes1.length) {
            setAllQueryFormat(mainRes1)
            selectedQuery = mainRes1[0]
        }
        if (!initial) selectedQuery = mainRes1
        setSelectedQuery(selectedQuery)
        if (selectedQuery.id) {
            if (!loader) setLoader(true)
            Promise.all([
                // actions.queryById(selectedQuery.id),
                actions.cargoByQuery(selectedQuery.id),
                actions.allCargoExtraDetailsByQuery(selectedQuery.id)
            ]).then(res => {
                const response2 = res[0]
                const response3 = res[1]

                let combinedCargo = []
                let formattedPortOperations = []
                const extraCargoDetailsWithExtraField = response3.map(item => ({ ...item, isExtra: true }))
                combinedCargo = [...response2, ...extraCargoDetailsWithExtraField]

                // set Order according to selectedQuery calculationFormat
                const portOperationOrder = (selectedQuery.calculationFormat && selectedQuery.calculationFormat.split(',')) || []
                // setPortOperationOrder(order)
                for (let i = 0; i < portOperationOrder.length; i++) {
                    const portElement = combinedCargo.find(item => item.id === portOperationOrder[i])
                    if (!!portElement) {
                        formattedPortOperations.push({ ...portElement, index: i })
                    }
                }
                if (!selectedQuery.fuelCalculationMethod) selectedQuery.fuelCalculationMethod = "DEFAULT"
                if (!selectedQuery.fuelType) selectedQuery.fuelType = "TYPE0"

                if (selectedQuery.fuelCalculationMethod === "LIFO" || selectedQuery.fuelCalculationMethod === "FIFO" || selectedQuery.fuelCalculationMethod === "AVERAGE") {
                    setSyncBunker(true)
                }

                if (selectedQuery.fuelCalculationMethod === "DEFAULT") {
                    const currentType = selectedQuery.fuelType.replace("TYPE", "")
                    formattedPortOperations = formattedPortOperations.map(item => {

                        return {
                            ...item,
                            cargoDetails: {
                                ...item.cargoDetails,
                                fuelPriceType0Hsfo: 0,
                                fuelPriceType0Lsfo: 0,
                                fuelPriceType0Mgo: 0,
                                fuelPriceType1Hsfo: 0,
                                fuelPriceType1Lsfo: 0,
                                fuelPriceType1Mgo: 0,
                                fuelPriceType2Hsfo: 0,
                                fuelPriceType2Lsfo: 0,
                                fuelPriceType2Mgo: 0,
                                fuelPriceType3Hsfo: 0,
                                fuelPriceType3Lsfo: 0,
                                fuelPriceType3Mgo: 0,
                                fuelPriceType4Hsfo: 0,
                                fuelPriceType4Lsfo: 0,
                                fuelPriceType4Mgo: 0,
                            }
                        }
                    })
                }

                // make calculator data add port operations
                const updatedData = {
                    ...calculatorData,
                    clientQuery: selectedQuery,
                    portOperation: formattedPortOperations,
                }
                if (initial) updatedData.fleetList = mainRes2
                // setCalculatorData(updatedData)
                sendCalculationRequest(updatedData)
            }).catch(err => {
                toastError(err.message || 'Something went wrong')
                setLoader(false)
            })
        } else {
            setLoader(false)
        }
    }

    const onChangePortOperations = (index, e, cargoUpdate = false) => {
        let { value = '', name = '' } = e.target
        let portOperation = [...calculatorData.portOperation]

        if (name === 'terms') {
            const termFactor = termsCoEfficientTable.find(item => item.label === value)
            portOperation[index]['terms'] = value
            portOperation[index]['factor'] = (termFactor && termFactor.value) || 0
        }
        else if (cargoUpdate) {
            portOperation[index].cargoDetails[name] = value
        } else {
            portOperation[index][name] = value
        }

        let updatedCalculatorData = { ...calculatorData, portOperation }
        if (name === "distanceNormal" || name === "distanceECA") {
            const updateData = {
                distEca: portOperation[index].distanceECA,
                distNormal: portOperation[index].distanceNormal,
                id: portOperation[index].id,
            }
            const saveData = {
                distEca: portOperation[index].distanceECA,
                distNormal: portOperation[index].distanceNormal,
                openingPort: (portOperation[index - 1] && portOperation[index - 1]['port']) || "",
                endingPort: portOperation[index]['port'] || "",
            }
            delayUpdateAndSaveOperation({ updateData, saveData, updatedCalculatorData })
        }
        setCalculatorData(updatedCalculatorData)
    }

    const onUpdateAndSavePortDistance = ({ updateData = {}, saveData = {}, updatedCalculatorData = {} }) => {
        setLoader(true)
        Promise.all([
            // actions.updateDistance(updateData),
            actions.savePortDistance(saveData)
        ]).then(() => {
            setLoader(false)
            sendCalculationRequest(updatedCalculatorData)
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong.")
        })
    }

    const sendCalculationRequest = async (sendData) => {

        let portDistanceRes = portDistanceResponse
        if (fetchPortDistance.current) {
            portDistanceRes = await onFetchAndSetIntitalPortDistance(sendData)
        }
        sendData = {
            ...sendData,
            portOperation: addPortDistanceToOperations(portDistanceRes, [...sendData.portOperation])
        }
        let modifiedData = {
            cargoList: sendData.portOperation.filter(item => !item.isExtra),
            cargoExtraDetails: sendData.portOperation.filter(item => item.isExtra),
            fleetList: sendData.fleetList,
            clientQuery: {
                ...sendData.clientQuery,
                calculationFormat: sendData.portOperation.map(item => item.id).join(',')
            },
            autoSave: sendData.autoSave
        }

        if (!loader) setLoader(true)
        const res = await sendCalculatorResponse(modifiedData)
        setLoader(false)
        const fleetData = fleetWithCalculatorDataMapping(sendData.fleetList, res)
        let fleetSelected = {}
        if (selectedVessels && selectedVessels.id) fleetSelected = fleetData.find(item => item.id === selectedVessels.id) || {}
        else fleetSelected = fleetData[0]
        onChangeFleetRowSelect(fleetSelected, { ...sendData, fleetList: fleetData }, true, true)
    }

    const sendCalculatorResponse = (modifiedData = {}) => {
        return new Promise((resolve, reject) => {
            if (modifiedData.fleetList.length === 0) return resolve({})
            actions.sendCalculatorData(modifiedData).then(res => {
                return resolve(res)
            }).catch(err => {
                resolve({})
                toastError(err.message || "Something went wrong")
            })
        })
    }

    const onChangeFleetRowSelect = async (fleet = {}, resultCalculatorData = null, changeVessel = false, isPortDistanceChanged = false) => {
        let updatedCalculatorData = {}
        if (resultCalculatorData) updatedCalculatorData = resultCalculatorData
        else updatedCalculatorData = { ...calculatorData }
        let commencePort = (fleet && fleet.port) || ''
        let fleetFuelType = (fleet && fleet.vessel && JSON.parse(fleet.vessel)) || {}
        fleetFuelType = fleetFuelType.fuelType || 'TYPE0'
        let portOperation = [...updatedCalculatorData.portOperation]

        const portToPortOperationResultMap = (fleet && fleet.portToPortOperationResultMap) || []
        portOperation = portOperation.map((operation, index) => {
            const selectedResultMap = portToPortOperationResultMap[index] || {}
            const removeDistanceFromResultMap = ({ distanceECA = '', distanceNormal = '', ...rest }) => {
                return { ...rest }
            }
            if (index !== 0) commencePort = (operation && operation.port) || ''
            let isTermExists = termsCoEfficientTable[0]
            if (operation.terms) {
                isTermExists = termsCoEfficientTable.find(item => item.label === operation.terms)
            }
            let cargoDetails = { ...operation.cargoDetails }

            if (index === 0) {
                const currentType = fleetFuelType.replace("TYPE", "")
                const { bnkDelhs = 0, bnkDells = 0, bnkDelmgo = 0, bnkPriceDeliveryhs = 0, bnkPriceDeliveryls = 0, bnkPriceDeliverymgo = 0 } = fleet
                cargoDetails = {
                    ...cargoDetails,
                    bunkerSuppliedHSFO: bnkDelhs,
                    bunkerSuppliedLSFO: bnkDells,
                    bunkerSuppliedMGO: bnkDelmgo,
                    [`fuelPriceType${currentType}Hsfo`]: bnkPriceDeliveryhs,
                    [`fuelPriceType${currentType}Lsfo`]: bnkPriceDeliveryls,
                    [`fuelPriceType${currentType}Mgo`]: bnkPriceDeliverymgo,
                }
            }
            return {
                ...operation,
                ...removeDistanceFromResultMap(selectedResultMap),
                cargoDetails,
                port: commencePort,
                terms: isTermExists.label,
                factor: isTermExists.value
            }
        })

        if (!isPortDistanceChanged) {
            let portDistanceRes = portDistanceResponse
            if (fetchPortDistance.current) {
                portDistanceRes = await onFetchAndSetIntitalPortDistance(updatedCalculatorData)
            }
            portOperation = addPortDistanceToOperations(portDistanceRes, [...portOperation])
        }
        // add post distance to selected fleet as well
        fleet = addPortDistanceToSelectedFleet(portOperation, fleet)

        if (changeVessel || !selectedVessels.id) {
            setSelectedVessels(fleet)
        }

        setCalculatorData({
            ...updatedCalculatorData,
            clientQuery: {
                ...updatedCalculatorData.clientQuery,
                fuelType: fleetFuelType
            },
            fleetList: updatedCalculatorData.fleetList.map(singleFleet => {
                if (singleFleet.id === fleet.id) return fleet
                else return singleFleet
            }),
            portOperation
        })

    }

    const onChangePortCanalInQueryState = (portCanalChecks) => {
        const findIndexQuery = allQueryFormat.findIndex(query => query.id === selectedQuery.id)

        if (findIndexQuery > -1) {
            const updatedAllQueryFormat = [...allQueryFormat]
            updatedAllQueryFormat[findIndexQuery] = {
                ...updatedAllQueryFormat[findIndexQuery],
                ...portCanalChecks
            }
            setAllQueryFormat(updatedAllQueryFormat)
            setSelectedQuery({ ...updatedAllQueryFormat[findIndexQuery], ...portCanalChecks })
        }
    }

    const onFetchPortOperationDistance = (reset = false, propCalculator = null, portCanalChecks = {}) => {
        let updatedCalculatorData = { ...calculatorData }
        if (propCalculator) {
            updatedCalculatorData = { ...propCalculator }
        }
        const { getPortDistanceString, routeList } = convertPortOperationToDistanceString(updatedCalculatorData)

        const portDataObj = {
            ...portCanalChecks,
            routeList: routeList.split(','),
            portList: getPortDistanceString.split(','),
            clientQueryId: updatedCalculatorData.clientQuery.id,
            queryId: updatedCalculatorData.clientQuery.id,
            getGeometry: false,
        }
        let request = ""

        // change port selection the query as well
        onChangePortCanalInQueryState(portCanalChecks)

        if (reset) request = actions.resetDistance(portDataObj)
        else request = actions.getPortDistanceByPortList(portDataObj)

        if (!loader) setLoader(true)
        request.then(res => {
            setLoader(false)
            setPortDistanceResponse(res)
            const newPortOperations = addPortDistanceToOperations(res, [...updatedCalculatorData.portOperation])
            sendCalculationRequest({
                ...updatedCalculatorData,
                portOperation: newPortOperations
            })
        }).catch(err => setLoader(false))
    }

    const onFetchAndSetIntitalPortDistance = (propCalculator) => {
        if (!fetchPortDistance.current) return
        let updatedCalculatorData = { ...propCalculator }
        const { getPortDistanceString, routeList } = convertPortOperationToDistanceString(updatedCalculatorData)
        setLoader(true)
        return new Promise((resolve, reject) => {
            const { clientQuery = {} } = updatedCalculatorData
            const { eca = false, hra = false, jwc = false, suez = false, panama = false, singapore = false } = clientQuery
            const portDataObj = {
                eca, hra, jwc, suez, panama, singapore,
                routeOverride: false,
                routeList: routeList.split(','),
                portList: getPortDistanceString.split(','),
                clientQueryId: updatedCalculatorData.clientQuery.id,
                queryId: updatedCalculatorData.clientQuery.id,
                getGeometry: false,
            }
            actions.getPortDistanceByPortList(portDataObj).then(res => {
                fetchPortDistance.current = false
                setPortDistanceResponse(res)
                resolve(res)
            }).catch(err => {
                setLoader(false)
                resolve({})
                toastError(err.message || "Something went wrong")

            })
        })
    }

    const onAddOperations = (requiredData = {}) => {
        setLoader(true)
        fetchPortDistance.current = true
        actions.addCargoExtraDetails({ ...initialCreatePortOperation, ...requiredData, queryId: selectedQuery.id }).then((response) => {
            const updatedSelectedQuery = { ...selectedQuery, calculationFormat: selectedQuery.calculationFormat + ',' + response.id }
            actions.updateQueryFormt(updatedSelectedQuery).then((res) => {
                setLoader(false)

                const updatedCalculatorData = {
                    ...calculatorData,
                    clientQuery: updatedSelectedQuery,
                    portOperation: [
                        ...calculatorData.portOperation,
                        { ...response, isExtra: true }
                    ]
                }

                sendCalculationRequest(updatedCalculatorData)
            }).catch(() => setLoader(false))
        }).catch(err => {
            setLoader(false)
        })
    }

    const onDeleteOperations = (index, id) => {
        setLoader(true)
        actions.deleteCargoExtraDetail(id).then(res => {
            setLoader(false)
            let portOperation = [...calculatorData.portOperation]
            portOperation.splice(index, 1)
            const updatedSelectedQuery = { ...selectedQuery, calculationFormat: portOperation.map(item => item.id).join(',') }

            setCalculatorData({
                ...calculatorData,
                portOperation,
                clientQuery: updatedSelectedQuery
            })
        }).catch(err => setLoader(false))
    }

    const onClickCalculate = () => {
        sendCalculationRequest({ ...calculatorData, autoSave: false })
    }
    const onClickCalculateAndSave = async () => {
        await sendCalculationRequest({ ...calculatorData, autoSave: true })
    }

    const onChangeSelectedVessel = (e, isFleetEdit = false) => {
        const { name = '', value = '', type = 'number', checked = false } = e.target || {}
        let newUpdatedFleet = { ...selectedVessels }
        const { fuelType = "" } = calculatorData.clientQuery || {}
        let updatedvessel = { ...JSON.parse(newUpdatedFleet.vessel) }
        if (isFleetEdit) {
            newUpdatedFleet[name] = value
        } else {
            if (type === 'checkbox') {
                updatedvessel[name] = checked
            } else {
                updatedvessel[name] = value
            }
        }
        const updatedFleet = { ...newUpdatedFleet, vessel: JSON.stringify(updatedvessel) }
        setSelectedVessels(updatedFleet)
        const updatedFleetList = calculatorData.fleetList.map(item => {
            if (item.id === updatedFleet.id) {
                return updatedFleet
            } return item
        })
        let updatedPortOperation = [...calculatorData.portOperation]
        if (name === "bnkPriceDeliveryhs" || name === "bnkPriceDeliveryls" || name === "bnkPriceDeliverymgo") {
            const currentType = fuelType.replace("TYPE", "")
            if (name === "bnkPriceDeliveryhs") updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Hsfo`] = value
            if (name === "bnkPriceDeliveryls") updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Lsfo`] = value
            if (name === "bnkPriceDeliverymgo") updatedPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] = value
        }
        if (name === 'bnkDelhs' || name === 'bnkDells' || name === 'bnkDelmgo') {
            if (updatedPortOperation.length) {
                if (name === "bnkDelhs") updatedPortOperation[0].cargoDetails.bunkerSuppliedHSFO = value
                if (name === "bnkDells") updatedPortOperation[0].cargoDetails.bunkerSuppliedLSFO = value
                if (name === "bnkDelmgo") updatedPortOperation[0].cargoDetails.bunkerSuppliedMGO = value
            }
        }
        setCalculatorData({ ...calculatorData, portOperation: updatedPortOperation, fleetList: updatedFleetList })
    }

    // const onChangeSelectedFleet = (e) => {
    //     const { name = '', value = '' } = e.target || {}
    //     let updatedFleet = { ...selectedVessels }
    //     updatedFleet[name] = value
    //     setSelectedVessels(updatedFleet)
    // }

    const onRemoveOrderQuery = (id) => {
        const queryIdMap = allQueryFormat.map(item => item.id).filter(item => item.id !== id)
        setLoader(true)
        actions.updateQueryFormat(queryIdMap).then(res => {
            setLoader(false)
            if (id === selectedQuery.id) {
                setSelectedQuery({})
            }
            setAllQueryFormat(allQueryFormat.filter(item => item.id !== id))
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })

    }

    const onDeleteFleet = (fleetId) => {
        setLoader(true)
        actions.deleteFleet(fleetId).then(res => {
            toastSuccess("Vessel deleted successfully")
            actions.allFleet().then(res => {
                setLoader(false)
                // if (Array.isArray(res)) setAllFleets(res)
                if (Array.isArray(res)) {
                    sendCalculationRequest({ ...calculatorData, fleetList: res })
                }
            }).catch(err => {
                setLoader(false)
                toastError(err.message || "Something went wrong")
            })
        }).catch(err => {
            setLoader(false)
            toastError(err.message || "Something went wrong")
        })
    }

    const onChangeSyncBunker = (checked) => {
        let updatedClientQuery = { ...calculatorData.clientQuery }
        if (!checked && updatedClientQuery.fuelCalculationMethod !== "DEFAULT") return toastError("Please change fuel calculation method")
        // setSyncBunker(checked)

        let updatedCalculatorData = { ...calculatorData }

        let clientQuery = {
            ...updatedCalculatorData.clientQuery,
            fuelPriceOverridden: checked
        }
        if (!checked) {
            clientQuery = {
                ...updatedCalculatorData.clientQuery,
                fuelPriceOverridden: checked,
                fuelCalculationMethod: "DEFAULT",
            }
        }

        updatedCalculatorData = {
            ...updatedCalculatorData,
            clientQuery
        }
        setCalculatorData(updatedCalculatorData)
    }

    const onClickAddFleet = () => {
        setAddFleetModal(prev => !prev)
    }

    const openSplitHireModal = (fleet) => {
        setSelectedSplitHire(fleet)
        setSplitHireModal(true)
    }

    const onSetHideCalculator = () => {
        setHideCalculator(!hideCalculator)
    }

    const onChangeOverride = (value) => {
        if (!value) {
            const { clientQuery = {}, portOperation, fleetList } = { ...calculatorData } || {}
            let { fuelCalculationMethod = '', fuelType = "TYPE0", lsFuelPriceType0 = 0, lsFuelPriceType1 = 0, lsFuelPriceType2 = 0, lsFuelPriceType3 = 0, lsFuelPriceType4 = 0,
                hsFuelPriceType0 = 0, hsFuelPriceType1 = 0, hsFuelPriceType2 = 0, hsFuelPriceType3 = 0, hsFuelPriceType4 = 0,
                mgoFuelPriceType0 = 0, mgoFuelPriceType1 = 0, mgoFuelPriceType2 = 0, mgoFuelPriceType3 = 0, mgoFuelPriceType4 = 0 } = clientQuery || {}

            let fuelTypePrices = {
                "TYPE0": { normal: hsFuelPriceType0, eca: lsFuelPriceType0, mgo: mgoFuelPriceType0 },
                "TYPE1": { normal: hsFuelPriceType1, eca: lsFuelPriceType1, mgo: mgoFuelPriceType1 },
                "TYPE2": { normal: hsFuelPriceType2, eca: lsFuelPriceType2, mgo: mgoFuelPriceType2 },
                "TYPE3": { normal: hsFuelPriceType3, eca: lsFuelPriceType3, mgo: mgoFuelPriceType3 },
                "TYPE4": { normal: hsFuelPriceType4, eca: lsFuelPriceType4, mgo: mgoFuelPriceType4 },
            }

            const selectedFuelPrice = fuelTypePrices[fuelType]
            const newPortOperation = [...portOperation]
            const currentType = fuelType.replace("TYPE", "")
            newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Hsfo`] = selectedFuelPrice.normal
            newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Lsfo`] = selectedFuelPrice.eca
            newPortOperation[0].cargoDetails[`fuelPriceType${currentType}Mgo`] = selectedFuelPrice.mgo

            let newSelectedVessel = {
                ...selectedVessels,
                bunkerPriceOverridden: false,
                bnkPriceDeliveryhs: selectedFuelPrice.normal,
                bnkPriceDeliveryls: selectedFuelPrice.eca,
                bnkPriceDeliverymgo: selectedFuelPrice.mgo,
                bnkPriceRedeliveryhs: selectedFuelPrice.normal,
                bnkPriceRedeliveryls: selectedFuelPrice.eca,
                bnkPriceRedeliverymgo: selectedFuelPrice.mgo,
                bnkPriceSettlemenths: selectedFuelPrice.normal,
                bnkPriceSettlementls: selectedFuelPrice.eca,
                bnkPriceSettlementmgo: selectedFuelPrice.mgo,
            }

            let updatedFleets = fleetList.map(item => {
                if (item.id === newSelectedVessel.id) return newSelectedVessel
                return item
            })
            setSelectedVessels(newSelectedVessel)
            setCalculatorData({ ...calculatorData, portOperation: newPortOperation, fleetList: updatedFleets })
        } else {
            const { fleetList } = { ...calculatorData } || {}
            let newSelectedVessel = {
                ...selectedVessels,
                bunkerPriceOverridden: true,
            }

            let updatedFleets = fleetList.map(item => {
                if (item.id === newSelectedVessel.id) return newSelectedVessel
                return item
            })
            setSelectedVessels(newSelectedVessel)
            setCalculatorData({ ...calculatorData, fleetList: updatedFleets })
        }
        setIsOverride(value)
    }

    const onSetOpenMap = () => {
        if (selectedQuery.id) {
            window.open(`/map/${selectedQuery.id}`);
        }
    }

    console.log(calculatorData, "calculatorData")
    // console.log(allQueryFormat, "allQueryFormat")
    // console.log(selectedQuery, "selectedQUERY")
    return (
        <Grid container className="calculator-section">

            {/* header */}
            <Grid item xs={12} className="quick-menu-bar">
                <ActionButtons
                    hideOrder={hideOrder}
                    setHideOrder={setHideOrder}
                    onSetOpenMap={onSetOpenMap}
                    onClickCalculate={onClickCalculate}
                    onSetHideCalculator={onSetHideCalculator}
                    onClickCalculateAndSave={onClickCalculateAndSave}
                />
            </Grid>

            {/* first UI part orders selector */}
            {hideOrder ? null :
                // <Grid item xs={1}>
                <div className="calc-order-section">
                    <OrdersList
                        onRemoveOrderQuery={onRemoveOrderQuery}
                        allQueryFormat={allQueryFormat}
                        selectedQuery={selectedQuery}
                        setSelectedQuery={setSelectedQuery}
                        onChangeSelectedQuery={setInitialFetchedData}
                    />
                </div>
                // </Grid>
            }

            {/* secound UI part */}
            <Grid item xs={12} style={{ paddingLeft: hideOrder ? '0px' : '140px' }}>
                {!selectedQuery.id ? <Grid container className="h-100">
                    <Grid item xs={12} style={{ display: 'flex' }}>
                        <p className="m-a" style={{ fontSize: 20 }}>Please select a query.</p>
                    </Grid>
                </Grid> :
                    <Grid container className="calc-secound-section">
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {hideCalculator ? null : <>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <div className="upper-section-grid">
                                            <AccountCargoDetails
                                                calculatorData={calculatorData}
                                                setLoader={setLoader}
                                                getInitalQueryAndFleetData={getInitalQueryAndFleetData}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={4}>
                                        <div className="upper-section-grid">
                                            <CalculatorVesselDetails
                                                setLoader={setLoader}
                                                setSelectedVessels={setSelectedVessels}
                                                selectedVessels={selectedVessels}
                                                calculatorData={calculatorData}
                                                setCalculatorData={setCalculatorData}
                                                onChangeHandler={onChangeSelectedVessel}
                                                fleetCheckbox={fleetCheckbox}
                                                setFleetCheckbox={setFleetCheckbox}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2} >
                                        <div className="upper-section-grid">
                                            <MarginAndAllowances
                                                onChangeSelectedVessel={onChangeSelectedVessel}
                                                selectedVessels={selectedVessels}
                                                setSelectedVessels={setSelectedVessels}
                                                setMarginModal={setMarginModal}
                                                calculatorData={calculatorData}
                                                setCalculatorData={setCalculatorData}
                                                syncBunker={syncBunker}
                                                setSyncBunker={setSyncBunker}
                                                setSupplyModal={setSupplyModal}
                                                onChangeSyncBunker={onChangeSyncBunker}
                                                isOverride={isOverride}
                                            />
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2} >
                                        <div className="upper-section-grid">
                                            <Expenses
                                                onChangeSelectedVessel={onChangeSelectedVessel}
                                                selectedVessels={selectedVessels}
                                                openSplitHireModal={openSplitHireModal}
                                                calculatorData={calculatorData}
                                                setCalculatorData={setCalculatorData}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <div className="upper-section-grid">
                                            <Result
                                                selectedVessels={selectedVessels}
                                                calculationErrors={calculationErrors}
                                            />
                                        </div>
                                    </Grid>

                                </>}
                                {/* port operations section drag and drop items */}
                                <Grid item xs={12}>
                                    <div className="middle-section-grid">
                                        <PortOperations
                                            marginModal={marginModal}
                                            setMarginModal={setMarginModal}
                                            sendCalculationRequest={sendCalculationRequest}
                                            onChangePortOperations={onChangePortOperations}
                                            calculatorData={calculatorData}
                                            setCalculatorData={setCalculatorData}
                                            syncBunker={syncBunker}
                                            setSyncBunker={setSyncBunker}
                                            onAddOperations={onAddOperations}
                                            onDeleteOperations={onDeleteOperations}
                                            selectedVessels={selectedVessels}
                                            supplyModal={supplyModal}
                                            setSupplyModal={setSupplyModal}
                                            onChangeSyncBunker={onChangeSyncBunker}
                                            onFetchPortOperationDistance={onFetchPortOperationDistance}
                                            fetchPortDistance={fetchPortDistance}
                                            openAddOperation={openAddOperation}
                                            setOpenAddOperation={setOpenAddOperation}
                                            onChangeSelectedVessel={onChangeSelectedVessel}
                                            isOverride={isOverride}
                                            onChangePortCanalInQueryState={onChangePortCanalInQueryState}
                                            setIsOverride={onChangeOverride}
                                        />
                                    </div>
                                </Grid>

                                {/* vessels selection section */}
                                <Grid item xs={12}>
                                    <div className="bottom-section-grid">
                                        <VesselSelection
                                            selectedVessels={selectedVessels}
                                            onFetchPortOperationDistance={onFetchPortOperationDistance}
                                            onChangeFleetRowSelect={onChangeFleetRowSelect}
                                            onDeleteFleet={onDeleteFleet}
                                            onClickAddFleet={onClickAddFleet}
                                            addFleetModal={addFleetModal}
                                            addFleetsArray={addFleetsArray}
                                            setAddFleetsArray={setAddFleetsArray}
                                            calculatorData={calculatorData}
                                            setCalculatorData={setCalculatorData}
                                            setLoader={setLoader}
                                            setAddFleetModal={setAddFleetModal}
                                            sendCalculationRequest={sendCalculationRequest}
                                            setSelectedVessels={setSelectedVessels}
                                            splitHireModal={splitHireModal}
                                            setSplitHireModal={setSplitHireModal}
                                            selectedSplitHire={selectedSplitHire}
                                            setSelectedSplitHire={setSelectedSplitHire}
                                            openSplitHireModal={openSplitHireModal}
                                            getInitalQueryAndFleetData={getInitalQueryAndFleetData}
                                        />
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                }
            </Grid>
            {/* <CustomShowModal
                open={openMap}
                handleClose={onSetOpenMap}
                maxWidth="lg"
                title="Map View"
            >
                <CalculatorPortMapView
                    calculatorData={calculatorData}
                />
            </CustomShowModal> */}

            {/* <CustomShowModal
                open={openResult}
                handleClose={onSetOpenResult}
                maxWidth="lg"
                title="Load Saved Result"
            >
                <LoadSavedResults setLoader={setLoader} />
            </CustomShowModal> */}

        </Grid >
    )
}

export default memo(Calculator)
