import React, { useState, useEffect } from 'react'
import { Grid, Box, Paper, makeStyles, Tabs, Tab, TableContainer, Table, TableCell, TableRow, TableHead, TableBody, Switch } from "@material-ui/core"
import CustomButton from '../../../components/Common/CustomButton';
import * as actions from "../Calculator/actions"
import moment from "moment"
import DeleteIcon from '@material-ui/icons/Delete';
import { toastError, toastSuccess } from '../../../utils';
import CustomPagination from '../../../components/Common/CustomPagination';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment"
import CancelIcon from '@material-ui/icons/Cancel';
import CustomConfirmModal from '../../../components/Common/CustomConfirmModal';

const useStyles = makeStyles({
    root: {
        width: "fit-content",
    },
});

const initialSearchType = {
    type: '',
    saveLabel: '',
    name: '',
    firstLoadPort: '',
    lastDischargePort: '',
    loadQuantity: '',
    createdDate: ''
}

function Voyages(props) {
    const { setLoader = () => { } } = props
    const classes = useStyles();

    const [value, setValue] = useState(1);
    const [open, setOpen] = useState(false)

    const [savedResults, setSavedResults] = useState([])
    const [allSavedResults, setAllSavedResults] = useState([])
    const [searchType, setSearchType] = useState({ ...initialSearchType })

    const [myFilters, setMyFilters] = useState({
        offset: 0,
        limit: 10
    })
    const [allFilters, setAllFilters] = useState({
        offset: 0,
        limit: 10
    })

    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState({})

    useEffect(() => {
        getAllSavedResults()
    }, [])

    const getAllSavedResults = async () => {
        setLoader(true)
        await actions.allSavedResults().then(res => {
            setLoader(false)
            setSavedResults((res.content && Array.isArray(res.content) && res.content) || [])
        }).catch(() => {
            setLoader(false)
            toastError()
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchType({ ...initialSearchType })
    };

    const onClickDeleteSavedFleet = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }

    const onChangeSearchType = (e, key = '') => {
        let { name = '', value = '' } = e.target || {}
        if (!value) name = ''
        let updatedSearchType = { ...searchType }
        if (name === 'loadQuantity') {
            let valueSplit = updatedSearchType[name].split(",")
            let startValue = valueSplit[0] || ''
            let endValue = valueSplit[1] || ''
            if (key === 'start') startValue = value
            else endValue = value
            value = `${startValue},${endValue}`
        } else if (name === 'createdDate') {
            let valueSplit = updatedSearchType[name].split(",")
            let startValue = valueSplit[0] || ''
            let endValue = valueSplit[1] || ''
            if (key === 'start') startValue = moment(value).valueOf()
            else endValue = moment(value).valueOf()
            value = `${startValue},${endValue}`
        }
        setSearchType({ ...initialSearchType, type: name, [name]: value })
    }

    const onClickSubmitSearch = async () => {
        setLoader(true)
        if (searchType.type) {
            let searchRequest = actions.allVoyageResultsByChar
            if (value !== 1) searchRequest = () => { }
            await searchRequest({ searchParam: searchType[searchType.type], type: searchType.type, offset: 10 }).then(res => {
                setSavedResults((Array.isArray(res) && res) || [])
            })
        } else {
            let searchRequest = actions.allSavedResults
            if (value !== 1) searchRequest = actions.allSavedResults
            await searchRequest().then(res => {
                setSavedResults((res.content && Array.isArray(res.content) && res.content) || [])
            })
        }
        setLoader(false)
    }

    const onChangeMyPagination = (type = '') => {
        let newMyFilters = {}
        if (type === 'inc') {
            newMyFilters = { ...myFilters, offset: myFilters.offset + 1 }

        } else if (type === 'first') {
            newMyFilters = { ...myFilters, offset: 0 }
        } else {
            if (myFilters.offset === 0) return
            newMyFilters = { ...myFilters, offset: myFilters.offset - 1 }
        }
        setLoader(true)
        actions.allSavedResults(newMyFilters).then((res) => {
            setLoader(false)
            setMyFilters(newMyFilters)
            setSavedResults((res.content && Array.isArray(res.content) && res.content) || [])
        }).catch(err => {
            setLoader(false)
        })
    }

    const onChangeAllPagination = (type = '') => {
        // let newallFilters = {}
        // if (type === 'inc') {
        //     newallFilters = { ...allFilters, offset: allFilters.offset + 1 }

        // } else if (type === 'first') {
        //     newallFilters = { ...allFilters, offset: 0 }
        // } else {
        //     if (allFilters.offset === 0) return
        //     newallFilters = { ...allFilters, offset: allFilters.offset - 1 }
        // }
        // setLoader(true)
        // actions.clientQueryOrg(newallFilters).then((res) => {
        //     setLoader(false)
        //     setAllFilters(newallFilters)
        //     setSavedResults((res.content && Array.isArray(res.content) && res.content) || [])
        // }).catch(err => {
        //     setLoader(false)
        // })
    }

    const onClickClearMyVoyages = async () => {
        await getAllSavedResults()
        setSearchType({ ...initialSearchType })
    }

    const onDeleteVoyages = () => {
        setLoader(true)
        actions.deleteSavedResultById(deleteId).then(res => {
            toastSuccess('Result deleted successfully')
            setDeleteModal(false)
            setDeleteId('')
            getAllSavedResults()
        }).catch(err => {
            toastError()
            setLoader(false)
        })
    }

    const loadSliderValue = String(searchType.loadQuantity).split(',')
    const createdDateValue = String(searchType.createdDate).split(',')

    return (
        <Grid container>

            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Paper className={classes.root}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                <Tab value={1} label="My Voyages" />
                                <Tab value={2} label="All Voyages" />
                            </Tabs>
                        </Paper>
                    </Box>
                    <div style={{ width: "115px" }}><CustomButton onClick={() => setOpen(true)}>New Order</CustomButton></div>
                </Box>

            </Grid>

            <Grid item xs={12}>

            </Grid>

            <Grid item xs={12}>
                {value === 1 ?
                    <Box pt={2}>
                        <TableContainer component={Paper}>
                            <Table className="universal-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No.</TableCell>
                                        <TableCell>Voyage Label</TableCell>
                                        <TableCell>Vessel Name</TableCell>
                                        <TableCell>Opening Port</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>First Load Port</TableCell>
                                        <TableCell>Last Discharge Port</TableCell>
                                        <TableCell>Load Qty</TableCell>
                                        <TableCell>Created on</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className="searchable-tablerow">
                                        <TableCell>{searchType.type ? <CancelIcon style={{ color: "#3f51b5" }} onClick={onClickClearMyVoyages} /> : null}</TableCell>
                                        <TableCell><input name="saveLabel" value={searchType.saveLabel} onChange={onChangeSearchType} /></TableCell>
                                        <TableCell><input name="name" value={searchType.name} onChange={onChangeSearchType} /></TableCell>
                                        <TableCell><input name="name" value={searchType.name} onChange={onChangeSearchType} /></TableCell>
                                        <TableCell><input name="name" value={searchType.name} onChange={onChangeSearchType} /></TableCell>
                                        <TableCell><input name="firstLoadPort" value={searchType.firstLoadPort} onChange={onChangeSearchType} /></TableCell>
                                        <TableCell><input name="lastDischargePort" value={searchType.lastDischargePort} onChange={onChangeSearchType} /></TableCell>
                                        <TableCell>
                                            <div className="range-load-quantity">
                                                <input type="number" name="loadQuantity" value={loadSliderValue[0] || ''} onChange={e => onChangeSearchType(e, 'start')} />
                                                <input type="number" name="loadQuantity" value={loadSliderValue[1] || ''} onChange={e => onChangeSearchType(e, 'end')} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {/* <input name="createdDate" value={searchType.createdDate} onChange={onChangeSearchType} /> */}
                                            <MuiPickersUtilsProvider utils={MomentUtils} >
                                                <DatePicker
                                                    autoOk
                                                    fullWidth
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="DD/MMM/yyyy"
                                                    className="small-date-picker"
                                                    placeholder="Start"
                                                    size="small"
                                                    name="createdDate"
                                                    clearable
                                                    value={(createdDateValue && createdDateValue[0] && Number(createdDateValue[0])) || null}
                                                    onChange={(date) => onChangeSearchType({ target: { name: 'createdDate', value: date } }, 'start')}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <MuiPickersUtilsProvider utils={MomentUtils} >
                                                <DatePicker
                                                    autoOk
                                                    fullWidth
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="DD/MMM/yyyy"
                                                    className="small-date-picker"
                                                    style={{ marginLeft: 10 }}
                                                    placeholder="End"
                                                    size="small"
                                                    clearable
                                                    name="createdDate"
                                                    value={(createdDateValue && createdDateValue[1] && Number(createdDateValue[1])) || null}
                                                    onChange={(date) => onChangeSearchType({ target: { name: 'createdDate', value: date } }, 'end')}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell><button onClick={onClickSubmitSearch}>Search</button></TableCell>
                                    </TableRow>
                                    {
                                        Array.isArray(savedResults) && savedResults.length ? savedResults.map((item, index) => {
                                            return <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell><a style={{ color: 'blue' }} href={`/saved/${item.id}`} target="_blank" rel="noreferrer">{item.saveLabel || ''}</a></TableCell>
                                                <TableCell>{item.vessel || ''}</TableCell>
                                                <TableCell>{'-'}</TableCell>
                                                <TableCell>{item.name || ''}</TableCell>
                                                <TableCell>{item.firstLoadPort || ''}</TableCell>
                                                <TableCell>{item.lastDischargePort || ''}</TableCell>
                                                <TableCell>{item.loadQuantity || 0}</TableCell>
                                                <TableCell>{item.createdDate ? moment(item.createdDate).format('DD/MMM/yyyy') : ''}</TableCell>
                                                <TableCell>
                                                    <div className="query-actions">
                                                        <DeleteIcon color="secondary" onClick={() => onClickDeleteSavedFleet(item.id)} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        }) : <TableRow>
                                            <TableCell colSpan="8" align="center">No Voyages Found.</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                            <CustomPagination
                                onChangePagination={onChangeMyPagination}
                                filters={myFilters}
                            />
                        </TableContainer>
                    </Box>
                    :
                    <Box pt={2}>
                        <TableContainer component={Paper}>
                            <Table className="universal-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No.</TableCell>
                                        <TableCell>Saved Name</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>First Load Port</TableCell>
                                        <TableCell>Last Discharge Port</TableCell>
                                        <TableCell>Load Qty</TableCell>
                                        <TableCell>Created on</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Array.isArray(allSavedResults) && allSavedResults.length ? allSavedResults.map((item, index) => {

                                            return <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item.name || ''}</TableCell>
                                                <TableCell>{item.firstLoadPort || ''}</TableCell>
                                                <TableCell>{item.lastDischargePort || ''}</TableCell>
                                                <TableCell>{item.cargo || ''}</TableCell>
                                                <TableCell>{item.loadQty || ''}</TableCell>
                                                <TableCell>{item.createdDate ? moment(item.createdDate).format('DD/MMM/yyyy') : ''}</TableCell>
                                                <TableCell>
                                                    {/* <VisibilityIcon color="primary" onClick={() => { onClickEditQuery(item); setIsDetailView(true) }} /> */}
                                                </TableCell>
                                            </TableRow>
                                        }) : <TableRow>
                                            <TableCell colSpan="8" align="center">No Voyages Found.</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                            <CustomPagination
                                onChangePagination={onChangeAllPagination}
                                filters={allFilters}
                            />
                        </TableContainer>
                    </Box>
                }
            </Grid>

            <CustomConfirmModal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                message="Are you sure to delete this voyage?"
                onSubmitConfirm={onDeleteVoyages}
            />

        </Grid>
    )
}

export default Voyages
