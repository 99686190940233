import { apiDelete, apiGet, apiPost, apiPut, getCurrentUserId } from "../../../utils"

export function getQueryFormatAPI() {
    const data = { userId: getCurrentUserId() }
    return apiGet(`/api/v1/getQueryFormat`, data)
}

export function queryByIdAPI(id) {
    const data = { id: id }
    return apiGet(`/api/v1/queryById`, data)
}

export function cargoByQueryAPI(id) {
    const data = { id: id }
    return apiGet(`/api/v1/CargoByQuery`, data)
}

export function allCargoExtraDetailsByQueryAPI(id) {
    const data = { id: id }
    return apiGet(`/api/v1/allCargoExtraDetailsByQuery`, data)
}

export function allFleetAPI() {
    return apiGet(`/api/v1/allFleet`)
}

export function sendCalculatorDataAPI(data) {
    return apiPost(`/api/v1/calculate/multiple/old`, data)
}

export function addCargoExtraDetailsAPI(data) {
    return apiPost(`/api/v1/cargoExtraDetails`, data)
}

export function updateQueryFormtAPI(data) {
    return apiPut(`/api/v1/updateQueryFormt`, data)
}

export function deleteCargoExtraDetailAPI(id) {
    return apiDelete(`/api/v1/deleteCargoExtraDetail?id=${id}`)
}

export function updateQueryFormatAPI(data) {
    return apiPut(`/api/v1/updateQueryFormat`, data)
}

export function deleteFleetAPI(id) {
    return apiDelete(`/api/v1/deleteFleet?id=${id}`)
}

export const allVesselsByCharWithoutReducerAPI = (search) => {
    // const { vessel = '', offset = 0 } = data || {}
    return apiGet(`/api/v1/allVesselsByChar?vessel=${search}&offset=${0}&limit=100`)
}

export function portsByCharAPI(search) {
    return apiGet(`/api/v1/portsByChar?port=${search}&offset=0&limit=100`)
}

export function addMultiFleetAPI(data) {
    return apiPost(`/api/v1/fleet`, data)
}

export function getEmailsFleetListAPI(data) {
    return apiGet(`/api/v1/getEmailsFleetList`, data)
}

export function getVesselsWithIdAPI(vesselId, isExtra = false) {
    return apiGet(`/api/v1/vesselsWithId?vesselId=${vesselId}&&extraDetails=${isExtra}`)
}

export function saveToFleetAPI(data) {
    return apiPost(`/api/v1/saveToFleet`, data)
}

export function getPortDistanceByPortListAPI(portData) {
    // const data = {
    //     portList,
    //     userId: getCurrentUserId()
    // }
    // return apiGet(`/api/v1/getPortDistance`, data)
    const data = {
        ...portData,
        userId: getCurrentUserId()
    }
    return apiPost(`/api/v1/getPortDistance`, data)
}

// export function getNewPortDistanceByPortListAPI(portData) {
//     const data = {
//         ...portData,
//         userId: getCurrentUserId()
//     }
//     return apiPost(`/api/v1/getPortDistance`, data)
// }

export function updateDistanceAPI(data) {
    return apiPut(`/api/v1/updateDistance`, data)
}

export function savePortDistanceAPI(data) {
    return apiPost(`/api/v1/savePortDistance`, { ...data, userId: getCurrentUserId() })
}

export function getCanalNameByUserAPI(data) {
    return apiGet(`/api/v1/getCanalNameByUser?id=${getCurrentUserId()}`)
}

export function userPortNotesAPI(data) {
    return apiGet(`/api/v1/userPortNotes`, data)
}

export function setUSerPortCanalAPI(data) {
    return apiPost(`/api/v1/canal?canalName=${data}`)
}

export function resetDistanceAPI(portData) {
    const data = {
        ...portData,
        userId: getCurrentUserId()
    }
    return apiPost(`/api/v1/resetDistance`, data)
}

export function updateFuelPriceAPI(fuelType, data) {
    return apiPut(`/api/v1/updateFuelPrice?fuelType=${fuelType}`, data)
}

export function userFleetsAPI(id) {
    return apiGet(`/api/v1/userFleets?id=${id}`)
}

export function userFleetDataCopyAPI(data) {
    return apiPost(`/api/v1/userFleetDataCopy`, data)
}

export function allSavedResultsAPI(data = {}) {
    let inititalData = { limit: 10, offset: 0 }
    return apiGet(`/api/v1/result/allSavedResults`, { ...inititalData, ...data })
}

export function saveResultByIdAPI(id) {
    return apiGet(`/api/v1/result/resultById?id=${id}`)
}

export function allResultsByVesselCharAPI(vessel) {
    return apiGet(`/api/v1/result/allResultsByVesselChar?offset=1&vessel=${vessel}`)
}

export function deleteSavedResultByIdAPI(id) {
    return apiDelete(`/api/v1/result/deleteById?id=${id}`)
}

export function allVoyageResultsByCharAPI(data) {
    return apiGet(`/api/v1/result/allVoyageResultsByChar`, data)
}

export function allPortRegionsAPI() {
    return apiGet(`/api/v1/allPortRegions`)
}