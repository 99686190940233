import React, { useState } from 'react'
import { Grid, Box } from "@material-ui/core"
import CustomTextField from '../../components/Common/CustomTextField'
import CustomButton from '../../components/Common/CustomButton'
import loginValidation from '../../validations/loginValidation'
import * as actions from "./actions"
import { saveObject, toastError, toastSuccess } from '../../utils'
import { useHistory } from "react-router-dom"

const initialUser = {
    email: ""
}

function ForgotPassword() {
    const history = useHistory();

    const [user, setUser] = useState({ ...initialUser })
    const [loginLoader, setLoginLoader] = useState(false)
    // const [errors, setErrors] = useState({})
    const [showConfirm, setShowConfirm] = useState(false)

    const onChangeHandler = (e) => {
        const { name = "", value = "" } = e.target
        setUser({ ...user, [name]: value })
        // setErrors({ ...errors, [name]: "" })
    }

    const keyPressed = e => {
        if (e.key === "Enter") {
            if (!loginLoader) {
                onConfirmEmail();
            }
        }
    }

    const onConfirmEmail = () => {
        if (!user.email) return
        setLoginLoader(true)
        actions.forgetPassword(user).then(res => {
            setLoginLoader(false)
            setShowConfirm(true)
        }).catch(err => {
            setLoginLoader(false)
            setShowConfirm(true)
        })
    }

    const goToLoginPage = () => {
        history.push('')
    }

    return (
        <Grid container className="login-container">
            <Grid item xs={12} sm={7} md={4} lg={3} className="m-a">
                <Grid container >
                    <Grid item xs={12} className="p-10">
                        <Box className="welcome-login">
                            <h2>Welcome to Dry Bulk Matrix!</h2>
                        </Box>
                        {showConfirm ? <Box p={2} className="login-container-card">
                            <Grid item xs={12}>
                                <Box pt={2} pb={6}>
                                    <h2 className="login-heading" style={{ fontSize: '18px' }} >
                                        Will send you reset password link if your email address is registered with us
                                    </h2>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pb={5}>
                                    <p className="forgot-password" style={{ fontSize: '16px' }} onClick={goToLoginPage}>Login</p>
                                </Box>
                            </Grid>
                        </Box> : <Box p={2} className="login-container-card">

                            <Grid item xs={12}>
                                <Box pt={2} pb={6}>
                                    <h2 className="login-heading">Forgot Password</h2>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pt={2} pb={3}>
                                    <CustomTextField
                                        name="email"
                                        placeholder="Email"
                                        value={user.email}
                                        onChange={onChangeHandler}
                                        onKeyPress={keyPressed}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <CustomButton
                                        onClick={onConfirmEmail}
                                        loader={loginLoader}
                                        disabled={loginLoader}
                                    >
                                        Confirm Email
                                    </CustomButton>
                                </Box>
                            </Grid>

                        </Box>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default ForgotPassword
