import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {getCurrentUserFirstName, removeAuthObjects} from '../../utils';
import {useHistory} from "react-router-dom"
import {Tooltip, withStyles, Zoom} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#41465f'
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

// const listOfRoutes = [
//     {
//         label: 'D',
//     },
//     {
//         label: 'C',
//     },
//     {
//         label: 'MV',
//     },
//     {
//         label: 'FR',
//     },
//     {
//         label: 'NewO',
//     },
//     {
//         label: 'VM',
//     },
//     {
//         label: 'EP',
//     },
// ]

export default function ButtonAppBar(props) {
    const {
        title = '',
        onClickMenu = () => { },
        validRoutes = [],
        activeRoute = ''
    } = props;
    const classes = useStyles();
    const history = useHistory();

    const onClickLogout = () => {
        removeAuthObjects()
        history.push('/')
    }

    const goToPage = (page) => {
        history.push(page)
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{ position: "fixed" }}>
                <Toolbar>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton onClick={onClickMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                            <Hidden only={['xs', 'sm']}>
                                <div className="list-routes">
                                    {
                                        validRoutes.map((route, index) => {
                                            const selectedRoute = route.activeRoutes.includes(activeRoute)
                                            return <LightTooltip key={index} title={route.label} arrow TransitionComponent={Zoom}><div className={`list-routes-item ${selectedRoute ? 'active-route' : ''}`} onClick={() => goToPage(route.url)}>
                                                {/* <span>{route.shortLabel}</span> */}
                                                <div className="route-item-div"><img src={route.blackImg} alt="" /></div>
                                            </div>
                                            </LightTooltip>
                                        })
                                    }
                                </div>
                            </Hidden>
                            <Typography variant="h6" className={classes.title} style={{ marginLeft: 10 }}>
                                {title}
                            </Typography>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: 20 }}>Welcome, {getCurrentUserFirstName()}</div>
                            <Button color="inherit" onClick={onClickLogout}>Logout</Button>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}