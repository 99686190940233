import * as actionTypes from "./actionTypes"

const initialState = {
    allClientOrders: [],
    queryOrderList: [],
    allOrganizationOrders: []
}

export default function dashboard(state = { ...initialState }, actions) {
    switch (actions.type) {
        case actionTypes.ALL_CLIENT_ORDERS: return {
            ...state,
            allClientOrders: actions.payload,
        }
        case actionTypes.ALL_ORGANIZATION_ORDERS: return {
            ...state,
            allOrganizationOrders: actions.payload,
        }
        case actionTypes.GET_QUERY_IN_ORDER_FORMAT: return {
            ...state,
            queryOrderList: actions.payload,
        }
        case actionTypes.CLEAR_ORDER_REDUCER: return { ...initialState }
        default: return state
    }
}