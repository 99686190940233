import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import * as actions from "./actions"
import { Grid, Box, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomButton from '../../../components/Common/CustomButton';
import CustomAddModal from '../../../components/Common/CustomAddModal';
import CustomConfirm from '../../../components/Common/CustomConfirmModal';
import AddNewOrg from '../../../components/Dashboard/AddNewOrg';
import { toastError, toastSuccess } from '../../../utils';

const inititalOrganization = {
    contactEmail: "",
    contactNumber: "",
    contactPerson: "",
    domain: "",
    emailReceiver: "",
    orgType: "",
    organizationName: "",
}

function SetupOrganization(props) {
    const { setLoader = () => { } } = props

    const dispatch = useDispatch()
    const { allOrganization = [] } = useSelector(state => state.dashboard)

    const [organization, setOrganization] = useState({ ...inititalOrganization })
    const [open, setOpen] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [saveLabel, setSaveLabel] = useState('Add')
    const [selectedOrg, setSelectedOrg] = useState('')

    useEffect(() => {
        if (allOrganization.length) {
            dispatch(actions.getAllOrganizations())
        }
    }, [])

    const onCloseModal = () => {
        setOpen(false)
        setOrganization({ ...inititalOrganization })
        setSaveLabel('Add')
    }

    const onChangeHandler = (e) => {
        const { name = '', value = '' } = e.target
        setOrganization({ ...organization, [name]: value })

    }

    const onOpenConfirm = (id) => {
        setSelectedOrg(id)
        setOpenConfirm(true)
    }

    const onSubmitConfirm = async () => {
        try {
            setLoader(true)
            if (saveLabel === 'Add') {
                await actions.createOrganization(organization)
                toastSuccess('Created successfully')
            } else {
                await actions.updateOrganization(organization)
                toastSuccess('Updated successfully')
            }
            onCloseModal()
            await dispatch(actions.getAllOrganizations())
            setLoader(false)
        } catch (ex) {
            toastError(ex.message)
            setLoader(false)
        }
    }

    const onClickEdit = (org = {}) => {
        const { id = "", contactEmail = "", contactNumber = "", contactPerson = "", domain = "", emailReceiver = "", orgType = "", organizationName = "" } = org
        setOrganization({
            id,
            contactEmail,
            contactNumber,
            contactPerson,
            domain,
            emailReceiver,
            orgType,
            organizationName,
        })
        setSaveLabel('Update')
        setOpen(true)
    }

    const onSubmitDelete = async () => {
        try {
            setLoader(true)
            await actions.deleteOrganization({ id: selectedOrg })
            setSelectedOrg('')
            setOpenConfirm(false)
            await dispatch(actions.getAllOrganizations())
            setLoader(false)
        } catch (ex) {
            toastError(ex.message)
            setLoader(false)
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                    <div style={{ width: "180px" }}><CustomButton onClick={() => setOpen(true)}>Create Organization</CustomButton></div>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8} className="m-a">
                <Box pt={2}>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.no</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Organization Code</TableCell>
                                    <TableCell>Domain</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Array.isArray(allOrganization) && allOrganization.length ? allOrganization.map((item, index) => {
                                        return <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.organizationName}</TableCell>
                                            <TableCell>{item.organizationCode}</TableCell>
                                            <TableCell>{item.domain}</TableCell>
                                            <TableCell className="user-organization-actions">
                                                <EditIcon onClick={() => onClickEdit(item)} />
                                                <DeleteIcon onClick={() => onOpenConfirm(item.id)} />
                                            </TableCell>
                                        </TableRow>
                                    }) : <TableRow>
                                        <TableCell align="center" colSpan="5">No User organizaton found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <CustomAddModal
                open={open}
                saveLabel={saveLabel}
                maxWidth="xs"
                title={`${saveLabel === 'Add' ? 'Add' : 'Edit'} Organization`}
                handleClose={onCloseModal}
                onSubmitConfirm={onSubmitConfirm}
            >
                <AddNewOrg
                    organization={organization}
                    onChange={onChangeHandler}
                />
            </CustomAddModal>
            <CustomConfirm
                open={openConfirm}
                message='Please confirm if you want to delete organization'
                onSubmitConfirm={onSubmitDelete}
                handleClose={() => setOpenConfirm(false)}
            >
                <div>hello</div>
            </CustomConfirm>
        </Grid>
    )
}

export default SetupOrganization