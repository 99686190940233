import React from 'react'
import { Grid, Checkbox, FormControl, MenuItem, Select } from "@material-ui/core"
import CustomTextField from "../Common/CustomTextField"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment"

function AddNewUser(props) {

    const {
        user = {},
        setUser = () => { },
        isEdit = false,
        selectedOrganization = {},
        onChangeOrganization = () => { },
        isAdminLogin = () => { },
        allOrganization = []
    } = props
    let { allRoles = [] } = props
    const {
        firstName = '',
        lastName = '',
        email = '',
        dob = '',
        password = '',
        organization = "",
        userRole = [],
        userAccessLevel = 0,
    } = user || {}

    allRoles = allRoles.map(item => item.name)

    const onChange = (key, value) => {
        if (key === 'userRole') {
            let newUserRole = [...userRole]
            let isExistsIndex = Array.isArray(newUserRole) && newUserRole.length ? newUserRole.findIndex(role => role.role === value) : -1
            if (isExistsIndex !== -1) {
                newUserRole = newUserRole.filter(item => item.role !== value)
                setUser({ ...user, userRole: newUserRole })
            } else {
                setUser({ ...user, userRole: [...userRole, { role: value }] })
            }
        } else {
            setUser({ ...user, [key]: value })
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <CustomTextField
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => onChange('firstName', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CustomTextField
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => onChange('lastName', e.target.value)}
                />
            </Grid>

            {/* add to disable chrome auto fill */}
            <Grid item xs={12} sm={6}>
                <CustomTextField
                    placeholder="Email"
                    value={email}
                    onChange={(e) => onChange('email', e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* <div className="react-date-picker"> */}
                {/* <DatePicker
                    onChange={onChange}
                    value={new Date()}
                /> */}
                {/* </div> */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        format="DD/MMM/yyyy"
                        placeholder="DOB: DD/MMM/YYYY"
                        value={dob || null}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={date => onChange('dob', date)}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {/* add to disable chrome auto fill */}
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
                <CustomTextField
                />
            </Grid>
            {isEdit ? null : <Grid item xs={12}>
                <CustomTextField
                    inputType="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => onChange('password', e.target.value)}
                />
            </Grid>}
            <Grid item xs={5}>
                <h4>Select Roles</h4>
                <ul className="select-roles-list">
                    {
                        Array.isArray(allRoles) && allRoles.map((item, index) => {
                            const isSelected = Array.isArray(userRole) && userRole.length && userRole.find(role => role.role === item)
                            if (item === 'Super Admin') return null
                            return <li style={{ cursor: "pointer" }} key={index} onClick={() => onChange('userRole', item)} ><Checkbox checked={!!isSelected} />{item}</li>
                        })
                    }
                </ul>
            </Grid>
            <Grid item xs={7}>
                <Grid container>
                    <Grid item xs={12}>

                        <h4>Select Organization</h4>
                        <ul className="select-roles-list">
                            <FormControl variant="filled" size="small" fullWidth>
                                <Select
                                    value={(selectedOrganization && selectedOrganization.id) || ''}
                                    onChange={e => onChangeOrganization(e.target.value)}
                                    // label="Age"
                                    disabled={!isAdminLogin()}
                                    defaultValue="Single Freight"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    {
                                        Array.isArray(allOrganization) && allOrganization.map((item, index) => {
                                            return <MenuItem key={index} dense value={item.id}>{item.organizationName}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>User Level</h4>
                        <ul className="select-roles-list">
                            <CustomTextField
                                inputType="text"
                                placeholder="User Access Level"
                                value={userAccessLevel}
                                onChange={(e) => {
                                    const value = Number(e.target.value)
                                    if (value >= 0 && value <= 9) {
                                        onChange('userAccessLevel', Number(e.target.value))
                                    }
                                }}
                            />
                        </ul>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default AddNewUser
