import React from 'react'
import LoadSavedResults from '../../../../components/Calculator/LoadSavedResults'

export default function SavedResults(props) {
    const { setLoader = () => { } } = props
    return (
        <div>
            <LoadSavedResults setLoader={setLoader} />
        </div>
    )
}
