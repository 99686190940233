import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, Tooltip, CircularProgress, withStyles } from "@material-ui/core"
import { Autocomplete } from '@material-ui/lab'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from "@date-io/moment"
import DeleteIcon from '@material-ui/icons/Delete';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);


function AutoAddFleet(props) {
    const {
        onAddFleetLine,
        addFleetsArray,
        saveVesselListByIndex,
        onChangeNewFleetsValue,
        delayVesselSearch,
        vesselLoading,
        savePortListByIndex,
        delayPortSearch,
        portLoading,
        onDeleteVesselLine
    } = props
    return (
        <Grid container>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <span onClick={onAddFleetLine} className="add-new-fleet-line">+ Add Vessel</span>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
                <TableContainer component={Paper}>
                    <Table className="fleet-list-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Vessel Name <LightTooltip title="Required"><span className="required-span">*</span></LightTooltip></TableCell>
                                <TableCell>DWT</TableCell>
                                <TableCell>Port <LightTooltip title="Required"><span className="required-span">*</span></LightTooltip></TableCell>
                                <TableCell>Date Opening <LightTooltip title="Required"><span className="required-span">*</span></LightTooltip></TableCell>
                                <TableCell>GBB</TableCell>
                                <TableCell>Hire Per Day</TableCell>
                                <TableCell>Hire GBB</TableCell>
                                <TableCell>Remarks/Broker/etc</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(addFleetsArray) && addFleetsArray.length ? addFleetsArray.map((item, index) => {
                                    const { dwt = 0, dateOpen = Date.now(), gbb = 0, hirePerDay = 0, hireGbb = 0, remark = "", selectedVessel = {}, selectedPort = {} } = item || {}
                                    return <TableRow key={index}>
                                        <TableCell>
                                            <Autocomplete
                                                options={(saveVesselListByIndex[index] && saveVesselListByIndex[index]) || []}
                                                value={selectedVessel}
                                                style={{ width: 160 }}
                                                disableClearable
                                                getOptionLabel={(option) => option.vesselLabel}
                                                onChange={(e, value) => onChangeNewFleetsValue(index, "vesselName", value)}
                                                getOptionSelected={(option, value) => option.vesselName === value.vesselName}
                                                className="autocomplete-mui"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Search Vessel"
                                                        value={selectedVessel && selectedVessel.vesselName}
                                                        onChange={e => delayVesselSearch(index, e.target.value)}
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {(vesselLoading[index] && vesselLoading[index]) || false ? <CircularProgress color="inherit" size={14} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <input
                                                type="number"
                                                className="custom-input-field"
                                                value={(selectedVessel && selectedVessel.vesselDwt) || 0}
                                                disabled
                                                onChange={e => onChangeNewFleetsValue(index, "dwt", e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={(savePortListByIndex[index] && savePortListByIndex[index]) || []}
                                                value={selectedPort}
                                                getOptionLabel={(option) => option.portLabel}
                                                style={{ width: 160 }}
                                                disableClearable
                                                onChange={(e, value) => onChangeNewFleetsValue(index, "port", value)}
                                                className="autocomplete-mui"
                                                getOptionSelected={(option, value) => option.portLabel === value.portLabel}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Search Port"
                                                        value={selectedPort && selectedPort.portLabel}
                                                        onChange={e => delayPortSearch(index, e.target.value)}
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {(portLoading[index] && portLoading[index]) || false ? <CircularProgress color="inherit" size={15} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    inputVariant="outlined"
                                                    size="small"
                                                    className="date-time-picker"
                                                    style={{ width: 150 }}
                                                    value={dateOpen}
                                                    onChange={date => onChangeNewFleetsValue(index, "dateOpen", date)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell>
                                            <input
                                                type="number"
                                                value={gbb}
                                                className="custom-input-field"
                                                onChange={e => onChangeNewFleetsValue(index, "gbb", e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <input
                                                type="number"
                                                value={hirePerDay}
                                                className="custom-input-field"
                                                onChange={e => onChangeNewFleetsValue(index, "hirePerDay", e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <input
                                                type="number"
                                                value={hireGbb}
                                                className="custom-input-field"
                                                onChange={e => onChangeNewFleetsValue(index, "hireGbb", e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <input
                                                type="text"
                                                value={remark}
                                                className="custom-input-field large-field"
                                                onChange={e => onChangeNewFleetsValue(index, "remark", e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {index !== 0 ? <DeleteIcon className="action-buttons" color="primary" onClick={() => onDeleteVesselLine(index)} /> : null}
                                        </TableCell>
                                    </TableRow>

                                }) : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default AutoAddFleet
