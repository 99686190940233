import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EmailIcon from '@material-ui/icons/Email';
import dashboardBlack from "./assets/flaticons/dashboard-black.png"
import dashboardWhite from "./assets/flaticons/dashboard-white.png"
import orderBlack from "./assets/flaticons/order-black.png"
import orderWhite from "./assets/flaticons/order-white.png"
import calculatorBlack from "./assets/flaticons/calculator-black.png"
import calculatorWhite from "./assets/flaticons/calculator-white.png"
import emailBlack from "./assets/flaticons/email-parser-black.png"
import emailWhite from "./assets/flaticons/email-parser-white.png"
import voyagesBlack from "./assets/flaticons/voyages-black.png"
import voyagesWhite from "./assets/flaticons/voyages-white.png"

const keys = require("./config/keys");

// export const API_URL = "http://54.221.198.168:8083"
export const API_URL = "https://fleetsigma.com"
//export const API_URL = "http://fs-staging.fleetsigma.com"
 //export const API_URL = "http://localhost:8083"

export const API_BASE_URL = keys.apiBaseUrl;

export const ACCESS_TOKEN = "token";

export const OAUTH2_REDIRECT_URI = keys.redirectUri;

export const GOOGLE_AUTH_URL = API_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;

//leaflet js
export const userDrawerList = [
    {
        label: "Dashboard",
        url: "/",
        shortLabel: 'D',
        icon: <DashboardIcon />,
        blackImg: dashboardBlack,
        whiteImg: dashboardWhite,
        activeRoutes: ["/", "/setup_organization", "/user_organization", "/vessel_information", "/vessel/:id"]
    },
    {
        label: "Freight Order",
        url: "/orders",
        shortLabel: 'FO',
        blackImg: orderBlack,
        whiteImg: orderWhite,
        icon: <ShoppingCartIcon />,
        activeRoutes: ["/orders"]
    },
    {
        label: "My Matrix",
        url: "/calculator",
        shortLabel: 'C',
        blackImg: calculatorBlack,
        whiteImg: calculatorWhite,
        icon: <ListAltIcon />,
        activeRoutes: ["/calculator"]
    },
    {
        label: "E-mail Reader",
        url: "/email_parser",
        shortLabel: 'EP',
        blackImg: emailBlack,
        whiteImg: emailWhite,
        icon: <EmailIcon />,
        activeRoutes: ["/email_parser"]
    },
    {
            label: "My Voyages",
            url: "/voyages",
            shortLabel: 'MV',
            blackImg: voyagesBlack,
            whiteImg: voyagesWhite,
            icon: <ShoppingCartIcon />,
            activeRoutes: ["/voyages"]
    },
    {
        label: "Welcome",
        url: "/welcome",
        shortLabel: 'HOME',
        blackImg: emailBlack,
        whiteImg: emailWhite,
        icon: <EmailIcon />,
        activeRoutes: ["/welcome"]
    },
]

export const adminDrawerList = [
    {
        label: "Dashboard",
        url: "/",
        icon: <DashboardIcon />,
        activeRoutes: ["/", "/user_organization", "/setup_organization", "/vessel_information"]
    }
]

export const createVesselOptions = [
    {
        label: "Built",
        name: "vesselBuilt",
        type: "number",
    },
    {
        label: "Flag",
        name: "vesselFlag",
        type: "type",
    },
    {
        label: "LOA",
        name: "vesselLOA",
        type: "number",
    },
    {
        label: "GRT",
        name: "vesselGRT",
        type: "number",
    },
    {
        label: "NGT",
        name: "vesselNRT",
        type: "number",
    },
    {
        label: "IMO",
        name: "vesselIMO",
        type: "number",
    },
    {
        label: "Class",
        name: "vesselClass",
        type: "text",
    },
    {
        label: "Bale Capacity",
        name: "vesselBaleCap",
        type: "number",
    },
    {
        label: "Gears",
        name: "vesselGears",
        type: "text",
    },
    {
        label: "Grabs",
        name: "vesselGrabs",
        type: "text",
    },
    {
        label: "Holds",
        name: "vesselHolds",
        type: "number",
    },
    {
        label: "CO2",
        name: "vesselCO2",
        type: "text",
    },
    {
        label: "AHL",
        name: "vesselAHL",
        type: "text",
    },
    {
        label: "Ice Class",
        name: "vesselIceClass",
        type: "text",
    },
    {
        label: "Shipyard",
        name: "vesselShipyard",
        type: "text",
    },
    {
        label: "Ship owner",
        name: "vesselShipowner",
        type: "text",
    },
    {
        label: "Ship country",
        name: "vesselCountry",
        type: "text",
    },
    {
        label: "Light Ship",
        name: "vesselLightShip",
        type: "number",
    },
]

export const createTableQuery = [
    {
        label: "Operation",
        name: "operation",
        type: "select"
    },
    {
        label: "Port",
        name: "port",
        type: "text"
    },
    {
        label: "Cargo Qty",
        name: "cargoQty",
        type: "number"
    },
    {
        label: "Tolerance",
        name: "tolerance",
        type: "number"
    },
    {
        label: "Rate MT/Day",
        name: "rate",
        type: "number"
    },
    {
        label: "Turn Time",
        name: "turnTime",
        type: "number"
    },
    {
        label: "PDA Remarks",
        name: "pdaRemarks",
        type: "text"
    },
    {
        label: "Grade/ Remarks",
        name: "grade",
        type: "text"
    },
    {
        label: "Freight",
        name: "freight",
        type: "number"
    },
    {
        label: "Adcom",
        name: "adcom",
        type: "number"
    },
    {
        label: "Other",
        name: "other",
        type: "number"
    },
    {
        label: "Brokerage",
        name: "brokerage",
        type: "number"
    },
    {
        label: "TTL Comm",
        name: "ttlComm",
        type: "number"
    },
    {
        label: "Net Freight",
        name: "netFrt",
        type: "number"
    },
    {
        label: "Min",
        name: "min",
        type: "number"
    },
    {
        label: "Max",
        name: "max",
        type: "number"
    },
    {
        label: "Account",
        name: "account",
        type: "text"
    },
    {
        label: "Cargo",
        name: "cargo",
        type: "text"
    },
    {
        label: "Stow Factor",
        name: "stowFactor",
        type: "number"
    },
]

export const accountCargoFields = [
    {
        label: "Account",
        value: "account"
    },
    {
        label: "Cargo",
        value: "cargo",
    },
    {
        label: "Laycan start",
        value: "laycanStartDate"
    },
    {
        label: "Laycan end",
        value: "laycanEndDate"
    },
    {
        label: "Adcom",
        value: "adcom",
    },
    {
        label: "Brokerage",
        value: "brokerage"
    },
    {
        label: "Others",
        value: "other"
    },
    {
        label: "Sf of cargo",
        value: "stowFactor",
    },
    {
        label: "Cargo Min",
        value: "min",
    },
    {
        label: "Cargo Max",
        value: "max",
    },
    {
        label: "freight Mode",
        value: "freightMode",
    },
    {
        label: "Tag1",
        value: "Tag1",
    },
    {
        label: "Tag2",
        value: "Tag2",
    },
    {
        label: "Tag3",
        value: "Tag3",
    },
    {
        label: "Tag4",
        value: "Tag4",
    },

]

export const vesselCalculatorMainFields = [
    {
        label: "DWT (S)",
        name: "vesselDwt",
        type: "number"
    },
    {
        label: "Draft (S)",
        name: "vesselDraftS",
        type: "number"
    },
    {
        label: "TPC",
        name: "vesselTPC",
        type: "number"
    },
    {
        label: "Grain",
        name: "vesselGrainCap",
        type: "number"
    },
    {
        label: "Built",
        name: "vesselBuilt",
        type: "number"
    },
    {
        label: "LOA",
        name: "vesselLOA",
        type: "number"
    },
    {
        label: "Beam",
        name: "vesselBeam",
        type: "number"
    },
    {
        label: "GRT",
        name: "vesselGRT",
        type: "number"
    },
    {
        label: "NRT",
        name: "vesselNRT",
        type: "number"
    },
    {
        label: "Light Ship",
        name: "vesselLightShip",
        type: "number"
    },
    {
        label: "Bale Capacity",
        name: "vesselBaleCap",
        type: "number"
    },
    {
        label: "Gears",
        name: "vesselGears",
        type: "text"
    },
    {
        label: "Grabs",
        name: "vesselGrabs",
        type: "text"
    },
    {
        label: "Constants",
        name: "Constants",
        type: "text"
    },
    {
        label: "Fuel Type",
        name: "fuelType",
        type: "text"
    },
    {
        label: "Scrubber Fitted",
        name: "vesselScrubber",
        type: "text"
    },
]

export const marginAndAllowancesOptions = [
    {
        label: "DWF",
        value: "dwf",
        type: "number",
        unitType: "%"
    },
    {
        label: "Speed Perf Factor",
        value: "Speed Perf Factor",
        type: "number",
        unitType: "%"
    },
    {
        label: "Speed Reduce",
        value: "Speed Reduce",
        type: "number",
        unitType: "Knots"
    },
    {
        label: "Bunker Con Allowance",
        value: "Bunker Con Allowance",
        type: "number",
        unitType: "%"
    },
]

export const expenseResultField = [
    {
        label: "CVE(per30days)",
        value: "cve",
        type: "text"
    },
    {
        label: "Insurance(Per Day)",
        value: "insurance",
        type: "text"
    },
    {
        label: "On/Off Hire Survey",
        value: "hireSurvey",
        type: "text"
    },
    {
        label: "Canal Expenses",
        value: "canalExpenses",
        type: "text"
    },
    {
        label: "Additional Premium",
        value: "additionalPremium",
        type: "text"
    },
    {
        label: "ILOHC",
        value: "ilohc",
        type: "text"
    },
    {
        label: "Manoevring Exp",
        value: "manoevringExp",
        type: "text"
    },
    {
        label: "Misc Exp",
        value: "miscExp",
        type: "text"
    },
    // {
    //     label: "Total",
    //     value: "Total",
    //     type: "text",
    //     disabled: true
    // }
]

export const resultsFields = [
    {
        label: "Frt Cost",
        value: "frtCost",
    },
    {
        label: "Profit PMT",
        value: "profitPmt",
    },
    {
        label: "Profit Net",
        value: "profitNet",
    },
    {
        label: "Net Frt Revenue",
        value: "netFrtRevenue",
    },
    {
        label: "Hire Exp",
        value: "hireExpense",
    },
    {
        label: "Fuel Expenses",
        value: "fuelExpense",
    },
    {
        label: "Others",
        value: "otherExpense",
    },
    {
        label: "HS Qty",
        value: "hsQuantity",
    },
    {
        label: "LS Qty",
        value: "lsQuantity",
    },
    {
        label: "MGO Qty",
        value: "mgoQuantity",
    },
    {
        label: "Voy Duration",
        value: "voyageDuration",
    },
    {
        label: "Port Stay",
        value: "portStayTotal",
    },
    {
        label: "Stowage",
        value: "stowage",
    },
    {
        label: "Fuel Settle Profit",
        value: "fuelSettlementProfit",
    },
]

export const portOperationHeader = [
    {
        label: '',
        width: 30
    },
    {
        label: 'B/L',
        width: 30
    },
    {
        label: 'Operation',
        width: 90
    },
    {
        label: 'Port',
        width: 80
    },
    {
        label: 'Dist Normal',
        width: 50
    },
    {
        label: 'Dist ECA',
        width: 40
    },
    {
        label: 'DWF',
        width: 40
    },
    {
        label: 'Speed Normal',
        width: 40
    },
    {
        label: 'Speed ECA',
        width: 50
    },
    {
        label: 'Cargo Tx',
        width: 60
    },
    {
        label: 'Rate MT/day',
        width: 60
    },
    {
        label: 'Terms',
        width: 90
    },
    {
        label: 'Factor',
        width: 60
    },
    {
        label: 'Turn time(d)',
        width: 50
    },
    {
        label: 'Extra days',
        width: 50
    },
    {
        label: 'Port Exp',
        width: 60
    },
    {
        label: 'Port stay',
        width: 60
    },
    {
        label: 'Port Emission',
        width: 60
    },
    {
        label: 'Loadline',
        width: 80
    },
    {
        label: 'Draft Res',
        width: 40
    },
    {
        label: 'Water Den',
        width: 40
    },
    {
        label: 'Supply',
        width: 40
    },
    {
        label: '',
        width: 30
    }
]

export const portOperationDisabledFields2 = {
    Commence: {
        operation: true,
        cargoQuantity: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        draftRes: true,
        waterDensity: true,
        dwf: true,
        consumptionMargin: true,
        speedMargin: true,
        speedReduction: true,
        extraDays: true,
        loadline: true,
        distanceFromPreviousPort: true,
        ecaLowSulphurDistanceFromLastPort: true,
        portEmission: true,
        portExpenses: true,
        reserve: true,
        hsfo: true,
        lsfo: true,
        mgo: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
    },
    Loading: {
        operation: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
    },
    Passing: {
        cargoQuantity: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Waiting: {
        cargoQuantity: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Bunkering: {
        cargoQuantity: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Redelivery: {
        cargoQuantity: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Relay: {
        cargoQuantity: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Discharging: {
        operation: true,
        cargoQuantity: true,
        tolerance: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyAuto: true,
    }
}

export const portOperationDisabledFields = {
    Commence: {
        operation: true,
        cargoQty: true,
        tolerance: true,
        terms: true,
        other: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        draftRes: true,
        waterDensity: true,
        dwf: true,
        bnkrFactor: true,
        speedFactor: true,
        speedReduce: true,
        extraDays: true,
        loadline: true,
        distNormal: true,
        distEca: true,
        sulfur: true,
        portExp: true,
        reserve: true,
        bunkerSuppliedHSFO: true,
        bunkerSuppliedLSFO: true,
        bunkerSuppliedMGO: true,
        bunkerNonScrubberHSFO: true,
        bunkerNonScrubberLSFO: true,
        bunkerNonScrubberMGO: true,
        bunkerScrubberHSFO: true,
        bunkerScrubberLSFO: true,
        bunkerScrubberMGO: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
    },
    Loading: {
        operation: true,
        dischargeQty: true,
        cargoTxQtyAuto: true,
    },
    Passing: {
        cargoQty: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Waiting: {
        cargoQty: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Bunkering: {
        cargoQty: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Redelivery: {
        cargoQty: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Relay: {
        cargoQty: true,
        tolerance: true,
        terms: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        rate: true,
        turnTime: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyActual: true,
        cargoTxQtyAuto: true,
        canDelete: true,
    },
    Discharging: {
        operation: true,
        cargoQty: true,
        tolerance: true,
        other_tax: true,
        brokerage: true,
        account: true,
        cargo: true,
        stowFactor: true,
        freight: true,
        adcom: true,
        min: true,
        max: true,
        cargoTxQtyAuto: true,
    }
}

export const termsCoEfficientTable = [
    {
        label: "SHINC",
        value: 1
    },
    {
        label: "SHEX-12hrs",
        value: 1.0769
    },
    {
        label: "SHEX-18hrs",
        value: 1.12
    },
    {
        label: "SHEX-24hrs",
        value: 1.1667
    },
    {
        label: "SHEX-30hrs",
        value: 1.2174
    },
    {
        label: "SHEX-36hrs",
        value: 1.2727
    },
    {
        label: "SHEX-38hrs",
        value: 1.2923
    },
    {
        label: "SHEX-40hrs",
        value: 1.3125
    },
    {
        label: "SHEX-42hrs",
        value: 1.3333
    },
    {
        label: "SHEX-44hrs",
        value: 1.3548
    },
    {
        label: "SHEX-48hrs",
        value: 1.4
    },
    {
        label: "SHEX-55hrs",
        value: 1.4867
    },
    {
        label: "SHEX-56hrs",
        value: 1.5
    },
    {
        label: "SHEX-62hrs",
        value: 1.5849
    },
    {
        label: "SHEX-63hrs",
        value: 1.6
    },
    {
        label: "SHEX-68hrs",
        value: 1.68
    },
    {
        label: "SHEX-72hrs",
        value: 1.75
    },
    {
        label: "SHEX-84hrs",
        value: 2
    },
    {
        label: "SHEX-96hrs",
        value: 2.3333
    },
]

export const operationsOptions = [
    {
        label: 'Commence',
        isDisabled: true,
    },
    {
        label: 'Loading',
        isDisabled: true,
    },
    {
        label: 'Passing',
        isDisabled: false,
    },
    {
        label: 'Waiting',
        isDisabled: false,
    },
    {
        label: 'Bunkering',
        isDisabled: false,
    },
    {
        label: 'Redelivery',
        isDisabled: false
    },
    {
        label: 'Relay',
        isDisabled: false,
    },
    {
        label: 'Discharging',
        isDisabled: true,
    },
]

export const loadlineOptions = [
    { label: 'S-Summer', value: 'S-Summer' },
    { label: 'TF-Tropical FW', value: 'TF-Tropical FW' },
    { label: 'F-FW', value: 'F-FW' },
    { label: 'T-Tropical', value: 'T-Tropical' },
    { label: 'W-Winter', value: 'W-Winter' },
    { label: 'WNA-Winter NA', value: 'WNA-Winter NA' },
]

export const portEmissionOptions = [
    { label: 'Normal', value: 'Normal' },
    { label: 'ECA', value: 'ECA' },
]

export const initialCreatePortOperation = {
    id: "",
    no: "L",
    operation: "Bunkering",
    port: "",
    dist_Normal: 0,
    dist_eca: 0,
    dwf: 0,
    seepd_Normal: "",
    speed_eca: "",
    sea_Time: "",
    cargoQty: 0,
    rate: 0,
    terms: "SHINC",
    factor: "1.0000",
    turnTime: 0,
    extraDays: 0,
    portExp: 0,
    portStay: 0,
    sulfur: "Normal",
    loadline: "S-Summer",
    draftRes: 0,
    waterDensity: 0,
    supply: 0,
    arrival: "",
    departure: "",
    maxQty: 0,
    freight: 0,
    adCom: 0,
    brokerage: 0,
    queryId: ""
}

export const initialPortOperation = {
    account: "",
    adcom: 0,
    arrivalHsfo: "",
    arrivalLsfo: "",
    arrivalMgo: "",
    arrivalTime: "",
    broker: "",
    brokerage: 0,
    cargo: "",
    cargoDetails: {
        arrivalBunkersHSFO: 0,
        arrivalBunkersLSFO: 0,
        arrivalBunkersMGO: 0,
        bnkrFactor: 0,
        bunkerNonScrubberHSFO: 0,
        bunkerNonScrubberLSFO: 0,
        bunkerNonScrubberMGO: 0,
        bunkerScrubberHSFO: 0,
        bunkerScrubberLSFO: 0,
        bunkerScrubberMGO: 0,
        bunkerSuppliedHSFO: 0,
        bunkerSuppliedLSFO: 0,
        bunkerSuppliedMGO: 0,
        cargoId: "",
        distEca: 0,
        distNormal: 0,
        id: "",
        organizationId: "",
        paidBy: "Operator",
        reserve: "User",
        speedFactor: 0,
        speedReduce: 0,
    },
    cargoId: "",
    cargoQty: 0,
    cargoTx: "",
    country: "",
    createdDate: "",
    createdUser: "",
    departureHsfo: "",
    departureLsfo: "",
    departureMgo: "",
    departureTime: "",
    dischargeQty: 0,
    draftRes: 0,
    duration: "",
    dwf: 0,
    extraDays: 0,
    factor: 0,
    firstLoadPort: "",
    freight: "",
    freightMode: "",
    grade: "",
    hire: "",
    hireGbb: "",
    id: "",
    index: 0,
    label: "",
    lastDischargePort: "",
    lastModifiedDate: "",
    lastModifiedUser: "",
    lat: "",
    laycanBegin: "",
    laycanEnd: "",
    lbmarker: "",
    lng: "",
    loadline: "",
    lumpsumFreight: 0,
    max: 0,
    min: 0,
    multiParcelMode: "",
    netFrt: 0,
    notes: "",
    operation: "",
    organizationId: "",
    other: 0,
    pdaRemarks: "",
    port: "",
    portExp: 0,
    portId: "",
    portStay: 0,
    profit: "",
    queryId: "",
    rate: 0,
    seaTime: 0,
    speedECA: "",
    speedNormal: "",
    stowFactor: 0,
    sulfur: "",
    supply: 0,
    system: false,
    tcEq: "",
    tcGbb: "",
    terms: "",
    tolerance: 0,
    ttlComm: 0,
    turnTime: 0,
    version: 0,
    voyType: "",
    waterDensity: 0,
}

export const fuelPriceOptions = [
    {
        label: "Type-1",
        value: "TYPE0",
        newLabel: "Non-Scrubber"
    },
    {
        label: "Type-2",
        value: "TYPE1",
        newLabel: "Scrubber"
    },
    {
        label: "Type-3",
        value: "TYPE2",
        newLabel: "LNG"
    },
    {
        label: "Type-4",
        value: "TYPE3",
        newLabel: "Hydrogen"
    },
    {
        label: "Type-5",
        value: "TYPE4",
        newLabel: "Bio-Fuel"
    },
]

export const scrubberOptions = [
    {
        label: "Yes",
        value: "Yes",
    },
    {
        label: "No",
        value: "No",
    },
]

export const initialAddFleet = {
    checked: false,
    vesselName: "",
    dwt: 0,
    port: "",
    dateOpen: Date.now(),
    tcEq: 0,
    gbb: 0,
    hirePerDay: 0,
    hireGbb: 0,
    frtCost: 0,
    profit: 0,
    loadability: 0,
    arrivalLoadPort: "",
    extra_Exp: "",
    bod: 0,
    constan: 0,
    lat: 0,
    lng: 0,
    remark: "",
    vesselId: "",
    vessel: "",
    selectedVessel: {},
    selectedPort: {}
}

export const addFleetRegionOptions = [
    {
        label: "Unknown",
        value: "Unknown"
    },
    {
        label: "OTHERS",
        value: "OTHERS"
    },
    {
        label: "BALTIC",
        value: "BALTIC"
    },
    {
        label: "INDIA SUBCOUNT",
        value: "INDIA SUBCOUNT"
    },
    {
        label: "AUSTRALIA/NZ",
        value: "AUSTRALIA/NZ"
    },
    {
        label: "UKC",
        value: "UKC"
    },
    {
        label: "BLACK SEA",
        value: "BLACK SEA"
    },
    {
        label: "FAR EAST",
        value: "FAR EAST"
    },
    {
        label: "WC LATIN AMERICA",
        value: "WC LATIN AMERICA"
    },
    {
        label: "EAST/SOUTH AFRICA",
        value: "EAST/SOUTH AFRICA"
    },
    {
        label: "EC LATIN AMERICA",
        value: "EC LATIN AMERICA"
    },
    {
        label: "CARIBS/ECMEX",
        value: "CARIBS/ECMEX"
    },
    {
        label: "USAC/ECC",
        value: "USAC/ECC"
    },
    {
        label: "MED",
        value: "MED"
    },
    {
        label: "USWC/WCM",
        value: "USWC/WCM"
    },
    {
        label: "USG",
        value: "USG"
    },
    {
        label: "MIDDLE EAST",
        value: "MIDDLE EAST"
    },
    {
        label: "SOUTH EAST ASIA",
        value: "SOUTH EAST ASIA"
    },
    {
        label: "WAF",
        value: "WAF"
    }
]

export const addFleetVesselTypeOptions = [
    {
        label: "All",
        value: "0_99999999"
    },
    {
        label: "Mini Bulk 0-20000",
        value: "0_20000"
    },
    {
        label: "HandyBulk 20,000-40,000",
        value: "20001_40000"
    },
    {
        label: "Supra/Ultra 40,000-68,000",
        value: "40001_68000"
    },
    {
        label: "P/Pmx 68,000-100,000",
        value: "68001_100000"
    },
    {
        label: "Cape 100,000 & above",
        value: "100001_99999999"
    },
]

export const addFleetGearsOptions = [
    {
        label: "All",
        value: "All"
    },
    {
        label: "Geared",
        value: "Geared"
    },
    {
        label: "Gearless",
        value: "Gearless"
    },
]

export const refreshCanalOptions = [
    {
        label: "Avoid Suez Canal",
        value: "suez"
    },
    {
        label: "Avoid Panama Canal",
        value: "panama"
    },
    {
        label: "Avoid Singapore",
        value: "singapore"
    },
    {
        label: "Avoid JWC",
        value: "jwc"
    },
    {
        label: "Avoid HRA",
        value: "hra"
    },
    {
        label: "Minimise ECA",
        value: "eca"
    },
]

export const addQueryInitialData = {
    name: "",
    cve: 0,
    insurance: 0,
    hireSurvey: 0,
    canalExpenses: 0,
    additionalPremium: 0,
    ilohc: 0,
    manoevringExp: 0,
    miscExp: 0,
    miscRevenue: 0,
    message: "",
    qtyMode: "MOLOO",
    freightMode: "Single Freight",
    access: "private",
    scrubberType: false,
    laycanStartDate: null,
    laycanEndDate: null,
    bnkDelhs: 0,
    bnkDells: 0,
    bnkDelmgo: 0
}

export const queryPortDisabledFields = {
    Loading: {

    },
    Discharging: {
        min: true,
        max: true,
        cargoQty: true,
        tolerance: true,
        stowFactor: true,
        freight: true,
        adcom: true,
        other: true,
        brokerage: true,
        account: true,
        cargo: true
    }
}

export const initialVesselState = {
    vesselAHL: "",
    vesselBalConsmHS1: 0,
    vesselBalConsmHS2: 0,
    vesselBalConsmHS3: 0,
    vesselBalConsmHS4: 0,
    vesselBalConsmLS1: 0,
    vesselBalConsmLS2: 0,
    vesselBalConsmLS3: 0,
    vesselBalConsmLS4: 0,
    vesselBalConsmMGO1: 0,
    vesselBalConsmMGO2: 0,
    vesselBalConsmMGO3: 0,
    vesselBalConsmMGO4: 0,
    vesselBalSpd1: 0,
    vesselBalSpd2: 0,
    vesselBalSpd3: 0,
    vesselBalSpd4: 0,
    vesselBaleCap: 0,
    vesselBeam: 0,
    vesselBuilt: 0,
    vesselCO2: "",
    vesselClass: "",
    vesselCountry: "",
    vesselDraftS: 0,
    vesselDwt: 0,
    vesselFlag: "",
    vesselGRT: 0,
    vesselGears: "",
    vesselGrabs: "",
    vesselGrainCap: 0,
    vesselHatches: 0,
    vesselHolds: 0,
    vesselIMO: 0,
    vesselIceClass: "",
    vesselLOA: 0,
    vesselLdnConsmHS1: 0,
    vesselLdnConsmHS2: 0,
    vesselLdnConsmHS3: 0,
    vesselLdnConsmHS4: 0,
    vesselLdnConsmLS1: 0,
    vesselLdnConsmLS2: 0,
    vesselLdnConsmLS3: 0,
    vesselLdnConsmLS4: 0,
    vesselLdnConsmMGO1: 0,
    vesselLdnConsmMGO2: 0,
    vesselLdnConsmMGO3: 0,
    vesselLdnConsmMGO4: 0,
    vesselLdnSpd1: 0,
    vesselLdnSpd2: 0,
    vesselLdnSpd3: 0,
    vesselLdnSpd4: 0,
    vesselLightShip: 0,
    vesselMMSI: 0,
    vesselNRT: 0,
    vesselName: "",
    vesselPDischConsmHS: 0,
    vesselPDischConsmLS: 0,
    vesselPDischConsmMGO: 0,
    vesselPIdleConsmHS: 0,
    vesselPIdleConsmLS: 0,
    vesselPIdleConsmMGO: 0,
    vesselPLdgConsmHS: 0,
    vesselPLdgConsmLS: 0,
    vesselPLdgConsmMGO: 0,
    fuelType: 'TYPE0',
    vesselShipowner: "",
    vesselShipyard: "",
    vesselSource: '',
    vesselTPC: 0,
    vesselType: '',
    vesselVExtraConsmHS: 0,
    vesselVExtraConsmLS: 0,
    vesselVExtraConsmMGO: 0,
    vesselBalComb1Selected: false,
    vesselBalComb2Selected: false,
    vesselBalComb3Selected: false,
    vesselBalComb4Selected: false,
    vesselLadComb1Selected: false,
    vesselLadComb2Selected: false,
    vesselLadComb3Selected: false,
    vesselLadComb4Selected: false,
}

export const vesselTableHeading = [
    { id: "vesselName", title: "Vessel Name", showOrder: false },
    { id: "dwt", title: "DWT", showOrder: false },
    { id: "port", title: "Port", showOrder: false },
    { id: "dateOpening", title: "Date Opening", showOrder: false },
    { id: "tcEq", title: "TC Eq", showOrder: true },
    { id: "gbb", title: "GBB", showOrder: false },
    { id: "hirePerDay", title: "Hire Per Day", showOrder: false },
    { id: "hireGbb", title: "Hire GBB", showOrder: false },
    { id: "frtCost", title: "Frt Cost", showOrder: true },
    { id: "profitNet", title: "Profit", showOrder: true },
    { id: "loadability", title: "Loadability", showOrder: true },
    { id: "eta", title: "ETA", showOrder: false },
    { id: "vesselPremium", title: "Vessel Premium", showOrder: true },
    { id: "positionPremium", title: "Position Premium", showOrder: true },
    { id: "remarks", title: "Remarks/Broker/etc", showOrder: false },
    { id: "actions", title: "Actions", showOrder: false },
]

export const vesselTableHeadingViewOnly = [
    { id: "checkbox", title: "checkbox", showOrder: true },
    { id: "vesselName", title: "Vessel Name", showOrder: true },
    { id: "dwt", title: "DWT", showOrder: true },
    { id: "port", title: "Port", showOrder: true },
    { id: "dateOpening", title: "Date Opening", showOrder: false },
    { id: "gbb", title: "GBB", showOrder: true },
    { id: "hirePerDay", title: "Hire Per Day", showOrder: true },
    { id: "hireGbb", title: "Hire GBB", showOrder: true },
    // { id: "eta", title: "ETA", showOrder: true },
    // { id: "rank", title: "Rank", showOrder: false },
    { id: "remarks", title: "Remarks/Broker/etc", showOrder: false },
    // { id: "actions", title: "Actions", showOrder: false },
]

export const vesselDetailsOption1 = [
    {
        label: 'Flag',
        name: "vesselFlag",
        isExtra: false
    },
    {
        label: 'Class',
        name: "vesselClass",
        isExtra: false
    },
    {
        label: 'IMO Number',
        name: "vesselIMO",
        isExtra: false
    },
    {
        label: 'Holds',
        name: "vesselHolds",
        isExtra: false
    },
    {
        label: 'Hatches',
        name: "vesselHatches",
        isExtra: false
    },
    {
        label: 'CO2',
        name: "vesselCO2",
        isExtra: false
    },
    {
        label: 'AHL',
        name: "vesselAHL",
        isExtra: false
    },
    {
        label: 'Ice Class',
        name: "vesselIceClass",
        isExtra: false
    },
    {
        label: 'Suez GRT',
        name: "suezGRT",
        isExtra: true
    },
    {
        label: 'Suez NRT',
        name: "suezNRT",
        isExtra: true
    },
    {
        label: 'Panama NT',
        name: "panamaNT",
        isExtra: true
    },
    {
        label: 'IFO Capacity',
        name: "ifoCapacity",
        isExtra: true
    },
    {
        label: 'MGO Capacity',
        name: "mgoCapacity",
        isExtra: true
    },
    {
        label: 'FW Capacity',
        name: "fwCapacity",
        isExtra: true
    },
]

export const vesselDetailsOption2 = [
    {
        label: 'Type',
        name: "vesselType",
        isExtra: false
    },
    {
        label: 'Sub Type',
        name: "subType",
        isExtra: true
    },
    {
        label: 'Call Sign',
        name: "callSign",
        isExtra: true
    },
    {
        label: 'MMSI Number',
        name: "vesselMMSI",
        isExtra: false
    },
    {
        label: 'Ship Yard Built',
        name: "vesselShipyard",
        isExtra: false
    },
    {
        label: 'Ship Owner',
        name: "vesselShipowner",
        isExtra: false
    },
    {
        label: 'Ship Country',
        name: "vesselCountry",
        isExtra: false
    },
    {
        label: 'E-mail',
        name: "email",
        isExtra: true
    },
    {
        label: 'Phone 1',
        name: "phone1",
        isExtra: true
    },
    {
        label: 'Phone 2',
        name: "phone2",
        isExtra: true
    },
    {
        label: 'P & I Club',
        name: "pandIClub",
        isExtra: true
    },
    {
        label: 'Created Timestamp',
        name: "null",
        isExtra: true,
        isDisabled: true
    },
    {
        label: 'Last Updated Time',
        name: "lastModifiedDate",
        isExtra: false,
        isDisabled: true
    },
    {
        label: 'Last Updated By',
        name: "savedBy",
        isExtra: false,
        isDisabled: true
    },
]