import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, FormControl, Select, MenuItem } from "@material-ui/core"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom"
import * as actions from "./actions"
import { useSelector, useDispatch } from "react-redux"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomButton from "../../../components/Common/CustomButton"
import CustomAddModal from '../../../components/Common/CustomAddModal';
import AddNewUser from '../../../components/Dashboard/AddNewUser';
import { toastError, toastSuccess, getCurrentUserEmail, isAdminLogin, getOrganizationId } from '../../../utils';
import moment from "moment"

const inititalUser = {
    dob: "",
    email: "",
    firstName: "",
    lastName: "",
    organization: "",
    password: "",
    userRole: [
        // { role: "Sales" },
        // { role: "ADMIN" }
    ],
    userAccessLevel: 0,
}

export default function UserMgntOrg(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false)
    const [user, setUser] = useState({ ...inititalUser })
    const [isEdit, setIsEdit] = useState(false)
    const [saveLabel, setSaveLabel] = useState('Add')
    const [selectedOrganization, setSelectedOrganization] = useState({})

    const { userOrganizaton = [], allRoles = [], allOrganization = [] } = useSelector(state => state.dashboard)

    useEffect(() => {

        if (!allRoles.length) {
            dispatch(actions.allRoles())
        }
        // let selectedOrganization = {}
        if (getOrganizationId()) {
            let selectedOrganization = Array.isArray(allOrganization) && allOrganization.length ? allOrganization.find(org => org.id === getOrganizationId()) : {}
            setSelectedOrganization(selectedOrganization)
        } else {
            if (allOrganization.length)
                setSelectedOrganization(allOrganization[0])
        }
    }, [allOrganization])

    useEffect(() => {
        if (selectedOrganization.id) {
            setUser({ ...user, organization: selectedOrganization.organizationName })
            onUserForOrg()
        }
    }, [selectedOrganization])


    const onUserForOrg = () => {
        props.setLoader(true)
        dispatch(actions.usersForOrg({ organizationId: selectedOrganization.id })).then(() => {
            props.setLoader(false)
        }).catch(() => {
            props.setLoader(false)
        })
    }

    const onCloseModal = () => {
        setUser({ ...inititalUser, organization: selectedOrganization.organizationName })
        setIsEdit(false)
        setSaveLabel('Add')
        setOpen(false)
    }

    const onSubmitConfirm = () => {
        setOpen(false)
        props.setLoader(true)
        let newUser = { ...user, dob: moment(user.dob).format() }
        if (isEdit) {
            newUser = {
                ...newUser,
                roles: newUser.userRole && newUser.userRole.map(item => {
                    const originalItem = allRoles.find(item1 => item1.name === item.role)
                    return originalItem
                })
            }
            return actions.updateUser(newUser).then(() => {
                toastSuccess('User Updated Successfully')
                props.setLoader(false)
                onUserForOrg()
                setUser({ ...inititalUser, organization: selectedOrganization.organizationName })
                setIsEdit(false)
                setSaveLabel('Add')
            }).catch((err) => {
                toastError(err.error)
                props.setLoader(false)
                setOpen(true)
            })
        }
        actions.createUser(newUser).then(() => {
            toastSuccess('User Created Successfully')
            props.setLoader(false)
            onUserForOrg()
            setUser({ ...inititalUser, organization: selectedOrganization.organizationName })
            setIsEdit(false)
            setSaveLabel('Add')
        }).catch((err) => {
            toastError(err.error)
            props.setLoader(false)
            setOpen(true)
        })
    }

    const onDeleteUser = (id) => {
        props.setLoader(true)
        actions.deleteUser({ id }).then(() => {
            props.setLoader(false)
            onUserForOrg()
            toastSuccess('User Deleted Successfully')
        }).catch(() => props.setLoader(false))
    }

    const onEditUser = (item) => {
        props.setLoader(true)
        actions.getUserRoles({ id: item.id }).then(res => {
            setIsEdit(true)
            setSaveLabel('Update')
            props.setLoader(false)
            const userRole = (Array.isArray(res) && res.map(item => ({ role: item.name }))) || []
            const obj = { ...item, dob: moment(item.dob).valueOf(), userRole, roles: res }
            setUser(obj)
            setOpen(true)
        }).catch(err => {
            props.setLoader(false)
        })
    }

    const onChangeOrganization = (id) => {
        let selectedOrganization = Array.isArray(allOrganization) && allOrganization.length ? allOrganization.find(org => org.id === id) : {}
        setSelectedOrganization(selectedOrganization)
    }

    return (
        <Grid container className="user-organization-contianer">
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                    <Paper onClick={() => history.goBack()} className="go-back-button"><p><ArrowBackIosIcon /> Go back</p></Paper>
                    <div style={{ width: "300px" }}>
                        <FormControl variant="filled" size="small" fullWidth>
                            <Select
                                value={(selectedOrganization && selectedOrganization.id) || ''}
                                onChange={e => onChangeOrganization(e.target.value)}
                                // label="Age"
                                disabled={!isAdminLogin()}
                                defaultValue="Single Freight"
                                size="small"
                                variant="outlined"
                                fullWidth
                            >
                                {
                                    Array.isArray(allOrganization) && allOrganization.map((item, index) => {
                                        return <MenuItem key={index} dense value={item.id}>{item.organizationName}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                    </div>
                    <div style={{ width: "115px" }}><CustomButton onClick={() => setOpen(true)}>Create User</CustomButton></div>
                </Box>

            </Grid>

            <Grid item xs={12} sm={12} md={8} className="m-a">
                <Box pt={2}>
                    <TableContainer component={Paper}>
                        <Table className="universal-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.no</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Array.isArray(userOrganizaton) && userOrganizaton.length ? userOrganizaton.map((item, index) => {
                                        return <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.fullname || ''}</TableCell>
                                            <TableCell>{item.email || ''}</TableCell>
                                            <TableCell className="user-organization-actions">
                                                {item.email === getCurrentUserEmail() ? null : <><EditIcon onClick={() => onEditUser(item)} />
                                                    <DeleteIcon onClick={() => onDeleteUser(item.id)} /></>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    }) : <TableRow>
                                        <TableCell align="center" colSpan="4">No User organizaton found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <CustomAddModal
                open={open}
                saveLabel={saveLabel}
                title="Add User"
                handleClose={onCloseModal}
                onSubmitConfirm={onSubmitConfirm}
            >
                <AddNewUser
                    user={user}
                    isEdit={isEdit}
                    allRoles={allRoles}
                    setUser={setUser}
                    selectedOrganization={selectedOrganization}
                    onChangeOrganization={onChangeOrganization}
                    isAdminLogin={isAdminLogin}
                    allOrganization={allOrganization}
                />
            </CustomAddModal>
        </Grid>
    )
}
