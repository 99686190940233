import React, { useState } from 'react'
import { Grid, Box } from "@material-ui/core"
import CustomTextField from '../../components/Common/CustomTextField'
import CustomButton from '../../components/Common/CustomButton'
import loginValidation from '../../validations/loginValidation'
import * as actions from "./actions"
import { saveObject, toastError } from '../../utils'
import { useHistory } from "react-router-dom"

const initialUser = {
    email: "",
    password: ""
}

function Auth() {
    const history = useHistory();

    const [user, setUser] = useState({ ...initialUser })
    const [loginLoader, setLoginLoader] = useState(false)
    const [errors, setErrors] = useState({})

    const onChangeHandler = (e) => {
        const { name = "", value = "" } = e.target
        setUser({ ...user, [name]: value })
        setErrors({ ...errors, [name]: "" })
    }

    const keyPressed = e => {
        if (e.key === "Enter") {
            if (!loginLoader) {
                onLogin();
            }
        }
    }

    const onLogin = () => {
        const { isValid, errors } = loginValidation(user)
        if (!isValid) return setErrors(errors)
        setLoginLoader(true)
        actions.login(user).then(res => {
            setLoginLoader(false)
            saveObject('auth', res)
            history.push('/welcome')
        }).catch(err => {
            setLoginLoader(false)
            toastError(err.error)
        })
    }
    const onLoginWithGoogle = () => {
        setLoginLoader(false)
        history.push('/google')
    }

    const onClickForgotPassword = () => {
        history.push('/forgot_password')
    }

    const onClickRequestSignUp = () => {
        history.push('/request_signup')
    }

    return (
        <Grid container className="login-container">
            <Grid item xs={12} sm={7} md={4} lg={3} className="m-a">
                <Grid container >
                    <Grid item xs={12} className="p-10">
                        <Box className="welcome-login">
                            <h2>Welcome</h2>
                        </Box>
                        <Box p={2} className="login-container-card">

                            <Grid item xs={12}>
                                <Box pt={2} pb={6}>
                                    <h2 className="login-heading">Login</h2>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pt={2} pb={3}>
                                    <CustomTextField
                                        name="email"
                                        placeholder="Email"
                                        value={user.email}
                                        error={!!errors.email}
                                        errorMsg={errors.email}
                                        onChange={onChangeHandler}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={3}>
                                    <CustomTextField
                                        inputType="password"
                                        name="password"
                                        placeholder="Password"
                                        value={user.password}
                                        error={!!errors.password}
                                        errorMsg={errors.password}
                                        onChange={onChangeHandler}
                                        onKeyPress={keyPressed}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={5} display="flex" justifyContent="space-between">
                                    <p className="forgot-password" onClick={onClickRequestSignUp}>Request Registration</p>
                                    <p className="forgot-password" onClick={onClickForgotPassword}>Forgot password</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <CustomButton
                                        onClick={onLogin}
                                        loader={loginLoader}
                                        disabled={loginLoader}
                                    >
                                        Login
                                    </CustomButton>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <CustomButton
                                        onClick={onLoginWithGoogle}
                                        loader={loginLoader}
                                        disabled={loginLoader}
                                    >
                                        Login/Signup with Google
                                    </CustomButton>
                                </Box>
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default Auth
