import React from 'react'
import { userDrawerList } from "../../../constants"
import { getCurrentUserFirstName, getRolePermisionRoutes } from '../../../utils'
import {useHistory} from "react-router-dom"

function Welcome() {

    const history = useHistory()

    const validRoutes = userDrawerList.filter(item => {
        return getRolePermisionRoutes().includes(item.url)
    })

    const goToRoute = (path) => {
        history.push(path)
    }

    return (
        <div className="welcome-container">
            <div>
                <h1>Welcome {getCurrentUserFirstName()} !</h1>
                <div className="welcome-notes">
                    <h2>Welcome to Smart Ship Chartering Platform</h2>
                    <h4> Boosting Efficiency, Empowering Professionals </h4>
                    <p style={{textAlign:'justify'}}> Efficiency is the key to thrive in a competitive market. This application will assist ship chartering professionals to achieve their best by complete automation and renewed workflow.  </p>
                    <p style={{textAlign:'justify'}}> <b>Instructions: </b> IMO 2020 has categorised distances into two types : Distance Normal (0.5% Compliant) and Distance ECA (0.1% compliant).
                        <p> In our scheme of calculations, the fuel consumed for 0.5% (Distance Normal) is called HS Fuel; and that for 0.1% (Distance ECA) is called LS Fuel. Accordingly, Vessel consumption has been defined for any speed as HS/LS/MGO.
                        <p> While entering fuel prices, the user should enter HS Price, LS Price and MGO Price:: Here HS Price will be used for all Distance Normal, LS Price will be used for all Distance ECA, MGO Price for any light fuel needed on board. Similarly for Port Consumptions:: HS fuel in Normal Ports, LS Fuel for ECA ports, MGO fuel always. Thus any one of HS or LS fuel is consumed and MGO is always consumed as per the vessel description.</p>
                        Depending on types of fuels consumed, users have option to categorise vessels into 5-types (Fuel Price Combinations):</p>
                        <p> 1.	Type 1 = Non-Scrubber</p>
                        <p> 2.	Type 2 = Scrubber Fitted vessels </p>
                        <p> 3.	Type 3 = LNG vessels </p>
                        <p> 4.	Type 4 = Custom user defined </p>
                        <p> 5.	Type 5 = Custom user defined </p>
                        <p> Please ensure vessel description is defined with HS, LS and MGO Consumptions accordingly. If vessel uses same fuel for HS and LS, ensure fuel prices for HS and LS are same for her. </p>
                        Please ensure the fuel prices are entered as per vessel type selected.
                    </p>
                </div>
                <div className="welcome-announcement">
                    <h3>HAPPY WORKING</h3>
                </div>
                <div className='navigation-container'>
                    <h3>Navigations:</h3>
                    <div className='navigation-div-item'>
                        {
                            validRoutes.map(routes => {
                                return <div className='navigation-routes'>
                                    <div className="main-container" onClick={() => goToRoute(routes.url)}>
                                    <div className="navigation-icon">
                                        <img src={routes.blackImg} alt="" />
                                    </div>
                                    <span className="navigation-route">{routes.label}</span>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome
