import { apiPost, apiPut } from "../../utils"

export const loginAPI = (data) => {
    return apiPost('/api/v1/auth/login', data)
}

export const forgetPasswordAPI = (data) => {
    const { email = '' } = data || {}
    return apiPut(`/api/v1/forgetPassword?email=${email}`)
}

export const updatePasswordAPI = (data) => {
    return apiPut(`/api/v1/updatePassword`, data)
}
